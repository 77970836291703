export const oidcSettings = {
    authority: process.env.VUE_APP_OIDC_AUTHORITY_URL,
    clientId: process.env.VUE_APP_OIDC_CLIENT_ID,
    redirectUri: window.location.origin + '/oidc-callback',
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 30,
    responseType: 'code',
    post_logout_redirect_uri: window.location.origin,
    scope: 'openid profile'
}

export const initOptions = {
    url: process.env.VUE_APP_OIDC_AUTHORITY_URL,
    realm: process.env.VUE_APP_OIDC_REALM,
    clientId: process.env.VUE_APP_OIDC_CLIENT_ID,
    onLoad: 'login-required'
}

