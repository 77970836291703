/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BenutzerVO } from './benutzer-vo';

/**
 * 
 * @export
 * @interface KitaVO
 */
export interface KitaVO {
    /**
     * 
     * @type {string}
     * @memberof KitaVO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KitaVO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof KitaVO
     */
    status: KitaVOStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof KitaVO
     */
    mitDessert: boolean;
    /**
     * 
     * @type {string}
     * @memberof KitaVO
     */
    sonderwunsch?: string;
    /**
     * 
     * @type {string}
     * @memberof KitaVO
     */
    geschaeftsname?: string;
    /**
     * 
     * @type {string}
     * @memberof KitaVO
     */
    kundenummer?: string;
    /**
     * 
     * @type {string}
     * @memberof KitaVO
     */
    ansprechpartner?: string;
    /**
     * 
     * @type {string}
     * @memberof KitaVO
     */
    telefonnummer?: string;
    /**
     * 
     * @type {Array<BenutzerVO>}
     * @memberof KitaVO
     */
    benutzer?: Array<BenutzerVO>;
    /**
     * 
     * @type {Array<number>}
     * @memberof KitaVO
     */
    liefertage: Array<number>;
}

/**
    * @export
    * @enum {string}
    */
export enum KitaVOStatusEnum {
    Aktiv = 'aktiv',
    Inaktiv = 'inaktiv'
}



