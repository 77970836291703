/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineObjectVO } from '../model';
// @ts-ignore
import { KitabenutzerInformationenVO } from '../model';
// @ts-ignore
import { KitainformationenVO } from '../model';
/**
 * KitainformationenApi - axios parameter creator
 * @export
 */
export const KitainformationenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Legt eine neue Kitainformation an
         * @param {InlineObjectVO} inlineObjectVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01013NeueInformationSpeichern: async (inlineObjectVO: InlineObjectVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObjectVO' is not null or undefined
            assertParamExists('uC01013NeueInformationSpeichern', 'inlineObjectVO', inlineObjectVO)
            const localVarPath = `/kita-informationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObjectVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Versendet eine Kitainformation
         * @param {string} id ID der Kitainformation die versendet werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01014InformationVersenden: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uC01014InformationVersenden', 'id', id)
            const localVarPath = `/kita-informationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Löscht eine Kitainformation
         * @param {string} id ID der Kitainformation die geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01015KitainformationLoeschen: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uC01015KitainformationLoeschen', 'id', id)
            const localVarPath = `/kita-informationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ändert eine Kitainformation
         * @param {KitainformationenVO} kitainformationenVO Struktur der zu änderden Kitainformation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01016KitainformationAendern: async (kitainformationenVO: KitainformationenVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitainformationenVO' is not null or undefined
            assertParamExists('uC01016KitainformationAendern', 'kitainformationenVO', kitainformationenVO)
            const localVarPath = `/kita-informationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kitainformationenVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Markiert eine Kitainformation als gelesen
         * @param {string} id ID der Kitainformation die als gelesen markiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01017KitainformationGelesenSpeichern: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uC01017KitainformationGelesenSpeichern', 'id', id)
            const localVarPath = `/benutzer/kitainformationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lädt alle offenen Kitainformationen für einen Benutzer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01018KitaBenutzerInformationLaden: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/benutzer/kitainformationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lädt alle Kitainformationen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01019KitainformationenLaden: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/kita-informationen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KitainformationenApi - functional programming interface
 * @export
 */
export const KitainformationenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KitainformationenApiAxiosParamCreator(configuration)
    return {
        /**
         * Legt eine neue Kitainformation an
         * @param {InlineObjectVO} inlineObjectVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uC01013NeueInformationSpeichern(inlineObjectVO: InlineObjectVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uC01013NeueInformationSpeichern(inlineObjectVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Versendet eine Kitainformation
         * @param {string} id ID der Kitainformation die versendet werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uC01014InformationVersenden(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uC01014InformationVersenden(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Löscht eine Kitainformation
         * @param {string} id ID der Kitainformation die geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uC01015KitainformationLoeschen(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uC01015KitainformationLoeschen(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ändert eine Kitainformation
         * @param {KitainformationenVO} kitainformationenVO Struktur der zu änderden Kitainformation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uC01016KitainformationAendern(kitainformationenVO: KitainformationenVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uC01016KitainformationAendern(kitainformationenVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Markiert eine Kitainformation als gelesen
         * @param {string} id ID der Kitainformation die als gelesen markiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uC01017KitainformationGelesenSpeichern(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uC01017KitainformationGelesenSpeichern(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lädt alle offenen Kitainformationen für einen Benutzer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uC01018KitaBenutzerInformationLaden(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KitabenutzerInformationenVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uC01018KitaBenutzerInformationLaden(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lädt alle Kitainformationen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uC01019KitainformationenLaden(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KitainformationenVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uC01019KitainformationenLaden(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KitainformationenApi - factory interface
 * @export
 */
export const KitainformationenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KitainformationenApiFp(configuration)
    return {
        /**
         * Legt eine neue Kitainformation an
         * @param {InlineObjectVO} inlineObjectVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01013NeueInformationSpeichern(inlineObjectVO: InlineObjectVO, options?: any): AxiosPromise<void> {
            return localVarFp.uC01013NeueInformationSpeichern(inlineObjectVO, options).then((request) => request(axios, basePath));
        },
        /**
         * Versendet eine Kitainformation
         * @param {string} id ID der Kitainformation die versendet werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01014InformationVersenden(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.uC01014InformationVersenden(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Löscht eine Kitainformation
         * @param {string} id ID der Kitainformation die geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01015KitainformationLoeschen(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.uC01015KitainformationLoeschen(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Ändert eine Kitainformation
         * @param {KitainformationenVO} kitainformationenVO Struktur der zu änderden Kitainformation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01016KitainformationAendern(kitainformationenVO: KitainformationenVO, options?: any): AxiosPromise<void> {
            return localVarFp.uC01016KitainformationAendern(kitainformationenVO, options).then((request) => request(axios, basePath));
        },
        /**
         * Markiert eine Kitainformation als gelesen
         * @param {string} id ID der Kitainformation die als gelesen markiert werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01017KitainformationGelesenSpeichern(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.uC01017KitainformationGelesenSpeichern(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lädt alle offenen Kitainformationen für einen Benutzer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01018KitaBenutzerInformationLaden(options?: any): AxiosPromise<Array<KitabenutzerInformationenVO>> {
            return localVarFp.uC01018KitaBenutzerInformationLaden(options).then((request) => request(axios, basePath));
        },
        /**
         * Lädt alle Kitainformationen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uC01019KitainformationenLaden(options?: any): AxiosPromise<Array<KitainformationenVO>> {
            return localVarFp.uC01019KitainformationenLaden(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KitainformationenApi - object-oriented interface
 * @export
 * @class KitainformationenApi
 * @extends {BaseAPI}
 */
export class KitainformationenApi extends BaseAPI {
    /**
     * Legt eine neue Kitainformation an
     * @param {InlineObjectVO} inlineObjectVO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitainformationenApi
     */
    public uC01013NeueInformationSpeichern(inlineObjectVO: InlineObjectVO, options?: any) {
        return KitainformationenApiFp(this.configuration).uC01013NeueInformationSpeichern(inlineObjectVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Versendet eine Kitainformation
     * @param {string} id ID der Kitainformation die versendet werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitainformationenApi
     */
    public uC01014InformationVersenden(id: string, options?: any) {
        return KitainformationenApiFp(this.configuration).uC01014InformationVersenden(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Löscht eine Kitainformation
     * @param {string} id ID der Kitainformation die geloescht werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitainformationenApi
     */
    public uC01015KitainformationLoeschen(id: string, options?: any) {
        return KitainformationenApiFp(this.configuration).uC01015KitainformationLoeschen(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Ändert eine Kitainformation
     * @param {KitainformationenVO} kitainformationenVO Struktur der zu änderden Kitainformation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitainformationenApi
     */
    public uC01016KitainformationAendern(kitainformationenVO: KitainformationenVO, options?: any) {
        return KitainformationenApiFp(this.configuration).uC01016KitainformationAendern(kitainformationenVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Markiert eine Kitainformation als gelesen
     * @param {string} id ID der Kitainformation die als gelesen markiert werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitainformationenApi
     */
    public uC01017KitainformationGelesenSpeichern(id: string, options?: any) {
        return KitainformationenApiFp(this.configuration).uC01017KitainformationGelesenSpeichern(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lädt alle offenen Kitainformationen für einen Benutzer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitainformationenApi
     */
    public uC01018KitaBenutzerInformationLaden(options?: any) {
        return KitainformationenApiFp(this.configuration).uC01018KitaBenutzerInformationLaden(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lädt alle Kitainformationen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitainformationenApi
     */
    public uC01019KitainformationenLaden(options?: any) {
        return KitainformationenApiFp(this.configuration).uC01019KitainformationenLaden(options).then((request) => request(this.axios, this.basePath));
    }
}
