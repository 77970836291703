/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { KitabenutzerInformationenVO } from './kitabenutzer-informationen-vo';

/**
 * 
 * @export
 * @interface KitainformationenVO
 */
export interface KitainformationenVO {
    /**
     * 
     * @type {string}
     * @memberof KitainformationenVO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof KitainformationenVO
     */
    datum?: string;
    /**
     * 
     * @type {string}
     * @memberof KitainformationenVO
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof KitainformationenVO
     */
    status?: KitainformationenVOStatusEnum;
    /**
     * 
     * @type {Array<KitabenutzerInformationenVO>}
     * @memberof KitainformationenVO
     */
    kitabenutzerinformationen?: Array<KitabenutzerInformationenVO>;
}

/**
    * @export
    * @enum {string}
    */
export enum KitainformationenVOStatusEnum {
    Offen = 'offen',
    Versendet = 'versendet'
}



