<template>
  <v-container>
    <v-row>
      <v-col>
        <allergenkennzeichnungen-uebersicht @change="refreshAllergenkennzeichnungen" />
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import AllergenkennzeichnungenUebersicht from "@/components/essenbestellen/allergenkennzeichnung/AllergenkennzeichnungenUebersicht.vue";
import {mapActions} from "vuex";

export default {
  name: "AllergenkennzeichnungenView",
  components: {AllergenkennzeichnungenUebersicht},

  data: () => ({

  }),
  methods: {
    ...mapActions(["refreshAllergenkennzeichnungen"])
  }
}
</script>

<style scoped>

</style>