<template>
  <v-container class="speiseplan-passwort-container justify-center fill-height">
    <v-row align-content="center">
      <v-col cols="12" offset-sm="2" sm="8">
        <h2 class="text-center">
          Sie werden angemeldet. Bitte warten.
        </h2>
      </v-col>
      <v-col class="text-center" cols="12" offset-sm="2" sm="8">
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {initOptions} from "@/plugins/oidcconfiguration";
import {mapGetters} from "vuex";

export default {
  name: "BenutzeranmeldenView",

  computed: {
    ...mapGetters(['oidcIsAuthenticated'])
  },

  created() {
    if (!this.oidcIsAuthenticated) {
      this.$keycloak.init({onLoad: initOptions.onLoad}).then((auth) => {
        if (!auth) {
          window.location.reload();
        } else {
          this.$store.commit("setOidcUser", this.$keycloak.tokenParsed)
          this.$store.commit("setAuthenticated", this.$keycloak.authenticated)
          this.$router.push("/")
        }
        setInterval(() => {
          this.$keycloak.updateToken(70).then(() => {
          }).catch(() => {
            console.log('Failed to refresh token');
          });
        }, 30000)

      }).catch(() => {
        console.log("Authenticated Failed");
      });
    } else {
      this.$router.push("/")
    }
  }
}
</script>

<style scoped>
.speiseplan-passwort-container {
  height: 700px;

}
</style>