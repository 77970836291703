<template>
  <v-alert v-if="this.informationen.length>0" class="mb-3 info-banner" outlined prominent rounded transition="fade">
    <div class="overflow-auto fade-transition" style="max-height: 500px">
      <h2 class="mb-2 info--text">
        <v-icon color="info" left>mdi-information</v-icon>
        Wichtige Informationen für Sie.
      </h2>
      <div v-for="info in informationen" :key="info.id" class="text-pre-line text-body-2">
        <b>{{ new Date(info.datum).toLocaleDateString() }}:</b>
        <div>{{ info.text }}</div>
      </div>
    </div>
    <v-btn class="float-end" color="info" depressed @click.stop="informationenAlsGelesenMakieren">
      <v-icon left>mdi-check</v-icon>
      Ich habe die Informationen gelesen
    </v-btn>
  </v-alert>
</template>

<script>
import {kitainformationenservice} from "@/service";

export default {
  name: "InformationsAnzeigeBannerComponent",
  data: () => ({
    informationen: []
  }),
  methods: {
    ladeInformationen() {
      kitainformationenservice.uC01018KitaBenutzerInformationLaden().then(response => {
        this.informationen = response.data
      })
    },
    informationenAlsGelesenMakieren() {
      this.informationen.forEach(info => {
        kitainformationenservice.uC01017KitainformationGelesenSpeichern(info.id)
            .catch(error => {
              console.log(error)
            })
      });
      this.informationen = []
    }
  },
  created() {
    if (this.$store.getters.hatRolleKita) {
      this.ladeInformationen()
    }
  }
}
</script>

<style scoped>
.info-banner {
  background-color: white !important;
}
</style>