<template>
  <v-checkbox
      v-model="mitDessert"
      label="Dessert"
      hint="Ist in den Bestellungen ein Dessert enthalten"
      @change="handleInput"
      :disabled="disabled"
      class="mt-0"
      hide-details
  />
</template>

<script>
export default {
  name: "KitaMitDessertComponent",
  props:['value', 'disabled'],
  data() {
    return {
      mitDessert: '',
    }
  },
  watch:{
    value:{
      immediate: true,
      handler(newValue){
        this.mitDessert = newValue
      }
    }
  },
  methods:{
    handleInput(){
      this.$emit('input',this.mitDessert)
    }
  }
}
</script>

<style scoped>

</style>