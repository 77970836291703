import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import axios from "axios";
import {initOptions} from "@/plugins/oidcconfiguration";
import Keycloak from "keycloak-js";
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false


const keycloak = new Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak

// @ts-ignore
keycloak.init({onLoad: 'check-sso'}).then((auth) => {
    if (auth) {
        store.commit("setOidcUser", keycloak.tokenParsed)
        store.commit("setAuthenticated", keycloak.authenticated)
        setInterval(() => {
            keycloak.updateToken(70).then(() => {
            }).catch(() => {
                console.log('Failed to refresh token');
            });
        }, 30000)
    }
    new Vue({
        vuetify, store, router, render: h => h(App, {props: {keycloak: keycloak}})
    }).$mount('#app')


}).catch(() => {
    console.log("Authenticated Failed");
});

axios.interceptors.request.use((config) => {
        const token = keycloak.token
        config.headers

        if (token) {
            // @ts-ignore
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    });


