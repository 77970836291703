<template>
  <v-autocomplete v-if="hatRolleMitarbeiter || hatRolleKueche"
                  v-model="ausgewaehlteKita"
                  :items="kitas"
                  auto-select-first
                  class="mb-2"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  label="Einrichtung auswählen"
                  no-data-text="Keine Einrichtung gefunden"
                  outlined
  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: ['value', 'nurMeta'],
  name: "KitaAuswahlComponent",
  data() {
    return {
      ausgewaehlteKita: this.value
    }
  },
  watch: {
    ausgewaehlteKita(neuerWert) {
      this.$emit('input', neuerWert)
    },
    getKitaVonBenutzer: {
      immediate: true,
      handler(neuerWert) {
        if (neuerWert) {
          this.ausgewaehlteKita = neuerWert.id
        }
      }
    }
  },
  methods: {
    ...mapActions(['refreshKitas'])
  },
  computed: {
    ...mapGetters(['getKitas', 'hatRolleKueche', 'hatRolleMitarbeiter', 'getKitaVonBenutzer']),
    kitas() {
      if (this.getKitas.length === 0) {
        this.refreshKitas()
      }
      return this.getKitas
    }
  },

}
</script>

<style scoped>

</style>