/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BenutzerVO } from '../model';
/**
 * BenutzerApi - axios parameter creator
 * @export
 */
export const BenutzerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lädt den Nutzer zum übergebenen JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benutzerMeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/benutzer/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lädt Benutzer
         * @param {'kueche' | 'mitarbeiter' | 'kita'} [rolle] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {boolean} [kitaZugewiesen] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzer: async (rolle?: 'kueche' | 'mitarbeiter' | 'kita', kitaZugewiesen?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rolle !== undefined) {
                localVarQueryParameter['rolle'] = rolle;
            }

            if (kitaZugewiesen !== undefined) {
                localVarQueryParameter['kitaZugewiesen'] = kitaZugewiesen;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Laedt die Benutzer zu einer Kita
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeBenutzerZurKita: async (kitaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitaId' is not null or undefined
            assertParamExists('ladeBenutzerZurKita', 'kitaId', kitaId)
            const localVarPath = `/kitas/{kitaId}/benutzer`
                .replace(`{${"kitaId"}}`, encodeURIComponent(String(kitaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lädt alle Benutzer aus der hinterlegten Benutzerverwaltung und aktualisiert diese in der Datenbank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBenutzerverwaltung: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/benutzerverwaltung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BenutzerApi - functional programming interface
 * @export
 */
export const BenutzerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BenutzerApiAxiosParamCreator(configuration)
    return {
        /**
         * Lädt den Nutzer zum übergebenen JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async benutzerMeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BenutzerVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.benutzerMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lädt Benutzer
         * @param {'kueche' | 'mitarbeiter' | 'kita'} [rolle] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {boolean} [kitaZugewiesen] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenutzer(rolle?: 'kueche' | 'mitarbeiter' | 'kita', kitaZugewiesen?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BenutzerVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenutzer(rolle, kitaZugewiesen, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Laedt die Benutzer zu einer Kita
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeBenutzerZurKita(kitaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BenutzerVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeBenutzerZurKita(kitaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lädt alle Benutzer aus der hinterlegten Benutzerverwaltung und aktualisiert diese in der Datenbank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshBenutzerverwaltung(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshBenutzerverwaltung(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BenutzerApi - factory interface
 * @export
 */
export const BenutzerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenutzerApiFp(configuration)
    return {
        /**
         * Lädt den Nutzer zum übergebenen JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        benutzerMeGet(options?: any): AxiosPromise<BenutzerVO> {
            return localVarFp.benutzerMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lädt Benutzer
         * @param {'kueche' | 'mitarbeiter' | 'kita'} [rolle] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {boolean} [kitaZugewiesen] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzer(rolle?: 'kueche' | 'mitarbeiter' | 'kita', kitaZugewiesen?: boolean, options?: any): AxiosPromise<Array<BenutzerVO>> {
            return localVarFp.getBenutzer(rolle, kitaZugewiesen, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Laedt die Benutzer zu einer Kita
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeBenutzerZurKita(kitaId: string, options?: any): AxiosPromise<Array<BenutzerVO>> {
            return localVarFp.ladeBenutzerZurKita(kitaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lädt alle Benutzer aus der hinterlegten Benutzerverwaltung und aktualisiert diese in der Datenbank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBenutzerverwaltung(options?: any): AxiosPromise<void> {
            return localVarFp.refreshBenutzerverwaltung(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenutzerApi - object-oriented interface
 * @export
 * @class BenutzerApi
 * @extends {BaseAPI}
 */
export class BenutzerApi extends BaseAPI {
    /**
     * Lädt den Nutzer zum übergebenen JWT
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public benutzerMeGet(options?: any) {
        return BenutzerApiFp(this.configuration).benutzerMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lädt Benutzer
     * @param {'kueche' | 'mitarbeiter' | 'kita'} [rolle] Gibt nur Benutzer mit der angegebenen Rolle zurück 
     * @param {boolean} [kitaZugewiesen] Gibt nur Benutzer mit der angegebenen Rolle zurück 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public getBenutzer(rolle?: 'kueche' | 'mitarbeiter' | 'kita', kitaZugewiesen?: boolean, options?: any) {
        return BenutzerApiFp(this.configuration).getBenutzer(rolle, kitaZugewiesen, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Laedt die Benutzer zu einer Kita
     * @param {string} kitaId Die ID der Kita
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public ladeBenutzerZurKita(kitaId: string, options?: any) {
        return BenutzerApiFp(this.configuration).ladeBenutzerZurKita(kitaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lädt alle Benutzer aus der hinterlegten Benutzerverwaltung und aktualisiert diese in der Datenbank
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenutzerApi
     */
    public refreshBenutzerverwaltung(options?: any) {
        return BenutzerApiFp(this.configuration).refreshBenutzerverwaltung(options).then((request) => request(this.axios, this.basePath));
    }
}
