<template>
  <v-tooltip v-if="speiseplan" left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn bottom
             class="ma-3"
             color="primary"
             fab
             fixed
             right
             v-bind="attrs"
             @click="download"
             v-on="on">
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>
    </template>
    <span>Speiseplan herunterladen</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "SpeiseplanDownloadComponent",
  props: ['speiseplan', 'dateiname'],
  methods: {
    download() {
      const fileLink = document.createElement('a');
      fileLink.href = this.speiseplan;
      fileLink.setAttribute('download', 'speiseplan_' + this.dateiname + '.pdf');
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  }
}
</script>

<style scoped>

</style>