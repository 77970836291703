<template>
  <v-dialog
      v-model="loeschDialog"
      :retain-focus="false"
      persistent
      max-width="450"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
          color="error"
          v-bind="attrs"
          v-on="on"
      >
        <v-list-item-icon>
          <v-icon color="error">
            mdi-cancel
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          Bestellvorlage löschen
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-alert type="error" dense v-if="error">
        {{error}}
      </v-alert>
      <v-card-title class="text-h5 ">
        Soll die Bestellvorlage wirklich gelöscht werden?
      </v-card-title>
      <v-card-actions>

        <v-spacer/>
        <v-btn
            text
            @click.stop="loeschDialog = false"
            :disabled="loading"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="error"
            depressed
            @click.stop="loescheBestellvorlage"
            :loading="loading"
        >
          löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {bestellvorlageservice} from "@/service";
import {mapMutations} from "vuex";

export default {
  name: "BestellvorlageLoeschenComponent",
  props: ['value'],
  data: () => ({
    loeschDialog: false,
    loading: false,
    error: null
  }),
  computed:{

  },
  methods: {
    ...mapMutations(["addErfolgsNachricht"]),
    loescheBestellvorlage() {
      this.error=null
      this.loading = true
      bestellvorlageservice.loescheBestellvorlage(this.value.kitaId).then(() => {
        this.loeschDialog = false
        this.addErfolgsNachricht("Bestellvorlage erfolgreich gelöscht")
        this.$emit("geloescht")
      }).catch(error=>{
        this.error = error.response.data.message
      }).finally(()=>
          this.loading = false
      )
    }
  }
}
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>