<template>
  <div>
    <v-card outlined rounded>
      <v-alert
          v-if="info"
          :color="info.typ ==='success'?'success':'error'"
          :type="info.typ"
          class="ma-0"
      >
        {{ info.nachricht }}
      </v-alert>
      <v-alert v-if="this.woche.length>0" dark type="info">
        Auf welche Woche(n) soll die Woche vom {{ baueWochenString(woche) }} kopiert werden?
        <div class="text-xl-subtitle-2">
          Klicken Sie dazu auf einen Tag der jeweiligen Woche.
        </div>
      </v-alert>
      <v-progress-linear v-if="ladewarteschlange.length>0" indeterminate>
      </v-progress-linear>
      <v-date-picker
          v-model="datum"
          :allowed-dates="erlaubteTage"
          :disabled="woche.length===0"
          :max="maxDatum"
          :min="fruehestesDatumZurAuswahl"
          :multiple="true"
          color="blue darken-1"
          :events="monatsevents"
          first-day-of-week="1"
          flat
          full-width
          locale="de"
          no-title
          scrollable
          show-adjacent-months
          show-week
          locale-first-day-of-year="4"
          @input="handleDatepickerInput($event)"
          class="mb-3 "
          @update:picker-date="datePickerMonatJahr = $event"
      >
      </v-date-picker>
      <v-card-text v-if="this.datum.length>0">
        <h4>
          Die Bestellungen der Woche vom {{ baueWochenString(woche) }} werden auf folgende Wochen kopiert:
        </h4>
        <v-list max-width="350px">
          <div v-for="[key, value]  in wochenMap" :key="key">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <b>KW {{ String(key).substring(4) }}</b> : {{ baueWochenString(value) }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="error" icon small @click="loescheWoche(String(key).substring(4),String(key).substring(0,3))">
                  <v-icon>
                    mdi-close-circle
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-card-text>

      <v-card-actions class="grey lighten-3">
        <v-spacer></v-spacer>
        <v-btn v-if="!$vuetify.breakpoint.mobile" :disabled="loading" depressed text @click="datum=[]">
          Eingaben löschen
        </v-btn>
        <v-btn :block="$vuetify.breakpoint.mobile" :disabled="bestellungDarfNochNichtAufgegebenWerden" :loading="loading" color="primary" depressed @click="bestellungKopieren">
          Bestellungen kopieren
        </v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>

import moment from "moment";
import {bestellungspauseservice, essensbestellungservice, kitaservice} from "@/service";
import {mapMutations} from "vuex";

export default {
  name: "EssensBestellungKopierenComponent",
  props: {
    kita: String,
    woche: Array,
  },
  data: () => ({
    datePickerMonatJahr: String(),
    datum: [],
    loading: false,
    kitaVO: {},
    info: null,
    feiertage: [],
    bestellungspausen: [],
    bestellungen: [],
    feiertagsFarbe: 'red',
    keineLiefertageFarbe: 'grey',
    bestellungspausenFarbe: 'blue',
    bestellungsFarbe: 'deep-purple lighten-1',
    legendenpunkt: 'dot mr-1 ',
    ladewarteschlange: [],
  }),
  computed: {
    bestellungDarfNochNichtAufgegebenWerden() {
      return this.datum.length === 0
    },
    maxDatum() {
      let naechstesJahr = new Date().getFullYear() + 1
      return naechstesJahr + "12-31"
    },
    fruehestesDatumZurAuswahl() {
      var dat = new Date()
      dat.setDate(dat.getDate() + 1)

      // Get the first Monday in the month
      while (dat.getDay() !== 1) {
        dat.setDate(dat.getDate() + 1);
      }
      return dat.toISOString().split('T')[0]
    },
    wochenMap() {
      const wochenDatumMap = new Map()
      this.datum.forEach(d => {
            if (wochenDatumMap.has(moment(d).year() * 100 + moment(d).isoWeek())) {
              const datenProWoche = wochenDatumMap.get(moment(d).year() * 100 + moment(d).isoWeek())
              datenProWoche.push(d)
              wochenDatumMap.set(moment(d).year() * 100 + moment(d).isoWeek(), datenProWoche)
            } else {
              const datenProWoche = []
              datenProWoche.push(d)
              wochenDatumMap.set(moment(d).year() * 100 + moment(d).isoWeek(), datenProWoche)
            }
          }
      )
      return new Map([...wochenDatumMap].sort());
    },
  },
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    monatsevents(date) {
      let besondererTagKennzeichen = []
      if (this.feiertage.map(feiertag => feiertag.datum).includes(date)) {
        besondererTagKennzeichen.push(this.feiertagsFarbe)
      }
      let wochentag = new Date(date).getDay() === 0 ? 7 : new Date(date).getDay()
      if (this.kitaVO.liefertage && !this.kitaVO.liefertage.includes(wochentag)) {
        besondererTagKennzeichen.push(this.keineLiefertageFarbe)
      }
      if (this.bestellungspausen && this.bestellungspausen.bestellungspausen && this.bestellungspausen.bestellungspausen.map(pause => pause.datum).includes(date)) {
        besondererTagKennzeichen.push(this.bestellungspausenFarbe)
      }
      if (this.bestellungen && this.bestellungen.map(bestellung => bestellung.datum).includes(date)) {
        besondererTagKennzeichen.push(this.bestellungsFarbe)
      }
      return besondererTagKennzeichen
    },
    pruefeTagErlaubt(date) {
      let wochentag = new Date(date).getDay() === 0 ? 7 : new Date(date).getDay()
      return !this.feiertage.map(feiertag => feiertag.datum).includes(date) && !this.bestellungspausen.bestellungspausen.map(pause => pause.datum).includes(date) && (this.kitaVO.liefertage && this.kitaVO?.liefertage.includes(wochentag));
    },

    erlaubteTage(date) {
      if (this.ladewarteschlange.length > 0) {
        return false
      }
      const tagIstErlaubt = this.pruefeTagErlaubt(date) && !this.woche.includes(date);
      if (!tagIstErlaubt) {
        if (this.datum && typeof this.datum !== 'string') {
          if (this.datum[0] === date
          ) {
            this.datum = []
          }
        } else if (this.datum && typeof this.datum === 'string') {
          if (this.datum === date) {
            this.datum = null
          }
        }
      }
      return tagIstErlaubt
    },
    refreshBestellungen() {
      const monatJahrSplit = this.datePickerMonatJahr.split('-')
      let jahr = Number(monatJahrSplit[0])
      if (monatJahrSplit[1]) {
        this.ladeBestellungen(jahr, Number(monatJahrSplit[1]))
      }
    },
    getBestellungRequestVO() {
      const bestellungKopierenRequest = {
        kitaid: this.kita,
        zuKopierendeWoche: {
          kwoche: moment(this.woche[0]).isoWeek(),
          jahr: moment(this.woche[0]).year()
        },
        aufZuKopierendeWochen: []
      };
      this.wochenMap.forEach((daten, jahrUndWoche) => {
        const kwoche = String(jahrUndWoche).substring(4)
        const jahr = String(jahrUndWoche).substring(0, 4)
        bestellungKopierenRequest.aufZuKopierendeWochen.push({
          kwoche: Number(kwoche),
          jahr: Number(jahr)
        })
      })
      return bestellungKopierenRequest
    },
    bestellungKopieren() {
      this.info = null
      this.loading = true
      essensbestellungservice.kopiereBestellungsWoche(this.getBestellungRequestVO())
          .then(() => {
            this.info = {typ: "success", nachricht: "Bestellungen erfolgreich kopiert"}
            this.addErfolgsNachricht("Bestellungen erfolgreich kopiert")
            this.$emit("erfolgreichGespeichert")
            this.datum = []
            this.refreshBestellungen()
          }).catch((error) => {
        this.vermerkeFehler(error.response.data)
      }).finally(() => {
        this.loading = false
      })
    },
    baueWochenString(woche) {
      if (woche) {
        return new Date(woche[0]).toLocaleDateString() + " bis " + new Date(woche[woche.length - 1]).toLocaleDateString()
      } else {
        return ''
      }
    },
    vermerkeFehler(error) {
      this.info = {
        typ: 'error',
        nachricht: error.message
      }
    },
    loescheWoche(woche, jahr) {
      this.datum = this.datum.filter(d => moment(d).isoWeek() !== Number(woche) && moment(d).year() !== Number(jahr))
    },
    handleDatepickerInput(event) {
      if (event.length % 7 === 1) {
        const current = new Date(event[event.length - 1])
        current.setDate((current.getDate() - current.getDay() + 1));
        for (let i = 0; i < 7; i++) {
          this.datum.push(
              current.toISOString().split('T')[0]
          );
          current.setDate(current.getDate() + 1);
        }
      } else {
        this.wochenMap.forEach((daten, jahrUndWoche) => {
          if (daten.length < 7) {
            const kwoche = String(jahrUndWoche).substring(4)
            const jahr = String(jahrUndWoche).substring(0, 4)
            this.datum = this.datum.filter(d => !(moment(d).isoWeek() === Number(kwoche) && moment(d).year() === Number(jahr)))
          }
        })
      }
      this.datum = [...new Set(this.datum)]
    },
    ladeFeiertage(jahr, monat) {
      this.ladewarteschlange.push("1")
      essensbestellungservice.ladeFeiertage(jahr, monat).then(
          response => {
            this.feiertage = response.data
          }
      ).catch((error) => {
        console.log(error)
      }).finally(() => this.ladewarteschlange.pop())
    },
    ladeLiefertage() {
      this.ladewarteschlange.push("1")
      kitaservice.kitaAnzeigen(this.kita).then(response => {
        this.kitaVO = response.data
      }).finally(() =>
          this.ladewarteschlange.pop()
      )
    },
    ladeBestellungspausen(jahr, monat) {
      if (this.kita) {
        this.ladewarteschlange.push("1")
        bestellungspauseservice.ladeBestellungspausen(jahr, this.kita, monat).then(
            response => {
              this.bestellungspausen = response.data
            }
        ).finally(() =>
            this.ladewarteschlange.pop()
        )
      }
    },
    ladeBestellungen(jahr, monat) {
      if (this.kita) {
        this.ladewarteschlange.push("1")
        essensbestellungservice.ladeEssensbestellungen(jahr, this.kita, monat).then(
            response => {
              this.bestellungen = response.data
            }
        ).finally(() =>
            this.ladewarteschlange.pop()
        )
      }
    },
    updateTage: function (monatJahr) {
      const monatJahrSplit = monatJahr.split('-')
      let jahr = Number(monatJahrSplit[0])
      if (monatJahrSplit[1]) {
        let monat = Number(monatJahrSplit[1])
        this.ladeFeiertage(jahr, monat)
        this.ladeBestellungspausen(jahr, monat)
        this.ladeBestellungen(jahr, monat)
      }
    },
  },
  created() {
    this.ladeLiefertage()
  },
  watch: {
    woche: {
      immediate: true,
      deep: true,
      handler(neueWoche) {
        if (neueWoche.length === 0) {
          this.datum = []
        } else {
          neueWoche.forEach(d => this.datum = this.datum.filter(bereitsGewaehltesDatum => bereitsGewaehltesDatum !== d))
        }
      }
    },
    datePickerMonatJahr: {
      immediate: true,
      handler(neu, alt) {
        if (neu && neu != alt) {
          this.updateTage(neu)
        }
      }
    }
  }

}
</script>

<style scoped>

</style>