import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        dark:false,
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#8577b2',
                accent: '#cdcc33',
                info: '#2554c9',
                success: colors.green.darken3
            },
        },
    }
});
