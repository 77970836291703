<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          depressed
          v-bind="attrs"
          @click="fehler=null"
          v-on="on"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Neue Allergenkennzeichnung anlegen
      </v-btn>
    </template>
    <v-card rounded="lg">
      <v-alert
          v-if="fehler"
          class="ma-0"
          color="error"
          type="error"
      >{{ fehler }}
      </v-alert>
      <v-card-title>
        <span class="headline">Neue Allergenkennzeichnung anlegen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                  v-model="allergenkennzeichnungVO.name"
                  :maxlength="100"
                  counter="100"
                  dense
                  label="Name"
                  outlined
                  @keydown.enter="erstelleAllergenkennzeichnung"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="allergenkennzeichnungVO.allergene"
                  :maxlength="100"
                  counter="100"
                  dense
                  label="Allergene"
                  outlined
                  @keydown.enter="erstelleAllergenkennzeichnung"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            @click="leereKomponente"
        >
          Abbrechen
        </v-btn>
        <v-btn
            :disabled="allergenkennzeichnungVO.name.trim().length === 0"
            :loading="loading"
            color="primary"
            depressed
            @click="erstelleAllergenkennzeichnung"
            v-if="hatRolleMitarbeiter || hatRolleKueche"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {allergenkennzeichnungenservice} from "@/service";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Allergenkennzeichnung from "@/model/Allergenkennzeichnung";

export default {
  name: "NeueAllergenkennzeichnungAnlegen",
  data() {
    return {
      dialog: false,
      fehler: null,
      loading: false,
      allergenkennzeichnungVO: new Allergenkennzeichnung()
    }
  },
  computed: {
    ...mapGetters(['hatRolleMitarbeiter', 'hatRolleKueche'])
  },
  methods: {
    ...mapMutations(["addErfolgsNachricht"]),
    ...mapActions(['refreshAllergenkennzeichnungen']),
    leereKomponente() {
      this.dialog = false
      this.allergenkennzeichnungVO= new Allergenkennzeichnung()
    },
    erstelleAllergenkennzeichnung() {
      this.loading = true
      this.fehler = null
      allergenkennzeichnungenservice
          .erstelleOderAktualisiereAllergenkennzeichnung(this.allergenkennzeichnungVO)
          .then(() => {
            this.$emit("neueAllergenkennzeichnung")
            this.addErfolgsNachricht("Die Allergenkennzeichnung " + this.allergenkennzeichnungVO.name + " wurde erstellt.")
            this.leereKomponente()
          })
          .catch((error) => {
            this.fehler = error.response.data.message
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>

<style scoped>

</style>