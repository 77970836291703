<template>
  <v-text-field
      v-model="ansprechpartner"
      :maxlength="100"
      :value="ansprechpartner"
      counter="100"
      dense
      label="Ansprechpartner"
      outlined
      @input="handleInput"
  />
</template>

<script>
export default {
  name: "KitaAnsprechpartnerComponent",
  props: ['value'],
  data() {
    return {
      ansprechpartner: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.ansprechpartner = newValue
      }
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.ansprechpartner)
    },
  }
}
</script>

<style scoped>

</style>