import {EssensbestellpositionVO, EssensbestellungHistorieVO, EssensbestellungMitPositionenVO} from "@/generated";

export default class Essensbestellung implements EssensbestellungMitPositionenVO {
    datum: string;
    essensbestellpositionen: Array<EssensbestellpositionVO>;
    historie: EssensbestellungHistorieVO[];
    id: string;
    kitaId: string;
    kitaName: string;
    bemerkung?: string;

    constructor() {
        this.datum = "";
        this.essensbestellpositionen = Array<EssensbestellpositionVO>();
        this.historie = {} as EssensbestellungHistorieVO[];
        this.id = "";
        this.kitaId = "";
        this.kitaName = "";
        this.essensbestellpositionen.push({essensarten: ['Normale Speisen'], anzahl: undefined})
    }

}
