import {
    AbrechnungApi,
    AllergenkennzeichnungApi,
    AuswertungApi,
    BenutzerApi,
    BestellungspauseApi,
    BestellvorlageApi,
    Configuration,
    EssensartenApi,
    EssensbestellungApi,
    KitainformationenApi,
    KitasApi,
    SpeiseplanApi,
    VerwaltungApi,
} from "@/generated";

const config = new Configuration()
config.basePath = process.env.VUE_APP_BACKEND_URL

export const kitaservice = new KitasApi(config)
export const essensbestellungservice = new EssensbestellungApi(config)
export const bestellungspauseservice = new BestellungspauseApi(config)
export const auswertungservice = new AuswertungApi(config)
export const abrechnungservice = new AbrechnungApi(config)
export const verwaltungservice = new VerwaltungApi(config)
export const benutzerservice = new BenutzerApi(config)
export const bestellvorlageservice = new BestellvorlageApi(config)
export const speiseplanservice = new SpeiseplanApi(config)
export const essensartenservice = new EssensartenApi(config)
export const allergenkennzeichnungenservice = new AllergenkennzeichnungApi(config)
export const kitainformationenservice = new KitainformationenApi(config)