<template>
  <v-container fluid>
    <v-row class="ma-2">
      <h2>Informationen für Einrichtungen</h2>
      <v-spacer></v-spacer>
      <v-btn class="primary" depressed @click.stop="neueKitaInformation()">
        <v-icon left>
          mdi-plus
        </v-icon>
        Neue Information erstellen
      </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table v-if="!amLaden" fixed-header>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left" style="width: 10%">
                Datum
              </th>
              <th class="text-left" style="width: 70%">
                Text
              </th>
              <th class="text-center" style="width: 10%">
                Status
              </th>
              <th class="text-center" style="width: 10%">
                Gelesen von
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in kitaInformationen"
                :key="item.name"
                style="cursor: pointer;"
                @click="ausgewaehlteKitaInformation = item; kitaInformationDialog = true"
            >
              <td>{{ new Date(item.datum).toLocaleDateString() }}</td>
              <td>{{ kuerzeTextWennNoetig(item.text) }}</td>
              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :class="bestimmeStatusIconFarbe(item.status)"
                            v-bind="attrs"
                            v-on="on"
                    >{{ bestimmeStatusIcon(item.status) }}
                    </v-icon>
                  </template>
                  <span>{{ item.status }}</span>
                </v-tooltip>
              </td>
              <td class="text-center">
                {{ bestimmeGelesenVonFuerItem(item) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-progress-linear
            v-else
            indeterminate
        ></v-progress-linear>
      </v-col>
    </v-row>
    <kita-information-component
        v-model="kitaInformationDialog"
        :kitainformation="ausgewaehlteKitaInformation"
        @success="ladeKitaInformationen"
        :key="ausgewaehlteKitaInformation.id"
    >
    </kita-information-component>
  </v-container>
</template>

<script>

import {kitainformationenservice} from "@/service";
import KitaInformationComponent from "@/components/verwaltung/kitainformationen/KitaInformationComponent.vue";

export default {
  name: "KitaInformationenView",
  components: {KitaInformationComponent},
  data: () => ({
    amLaden: false,
    kitaInformationen: [],
    kitaInformationDialog: false,
    ausgewaehlteKitaInformation: {id: null, text: ''}
  }),
  methods: {
    kuerzeTextWennNoetig(text) {
      if (text.length > 100) {
        return text.substring(0, 100) + '...'
      }
      return text
    },
    bestimmeStatusIcon(status) {
      if (status === 'versendet') {
        return 'mdi-send-check'
      }
      return 'mdi-clock-outline'
    },
    bestimmeStatusIconFarbe(status) {
      if (status === 'versendet') {
        return 'success--text'
      }
      return 'info--text'
    },
    bestimmeGelesenVonFuerItem(item) {
      if (item.status === 'versendet') {
        const informierteKitas = item.kitabenutzerinformationen
        const gelesenVon = informierteKitas.filter(it => it.status === 'gelesen').length
        return gelesenVon + ' von ' + informierteKitas.length
      }
      return ""
    },
    neueKitaInformation() {
      this.ausgewaehlteKitaInformation = {id: null, text: ''};
      this.kitaInformationDialog = true;
    },
    ladeKitaInformationen() {
      this.amLaden = true;
      kitainformationenservice.uC01019KitainformationenLaden()
          .then(response => {
            this.kitaInformationen = response.data
          })
          .catch(error => {
            console.log(error.response)
          })
          .finally(() => this.amLaden = false);
    }
  },


  created() {
    this.ladeKitaInformationen();
  }
}
</script>

<style scoped>

</style>