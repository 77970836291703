<template>
  <div v-if="kita">
    <v-card v-if="!loading" outlined rounded>
      <v-alert
          v-if="info"
          :color="info.typ ==='success'?'green darken-3':'error'"
          :type="info.typ"
          class="ma-0"
      >
        {{ info.nachricht }}
      </v-alert>
      <v-card-title>
        Aktuelle Bestellvorlage
        <v-spacer></v-spacer>
        <v-menu
            v-if="hatRolleMitarbeiter && bestellvorlage.essensbestellpositionen && !bestellungDarfNochNichtAufgegebenWerden"
            bottom
            left
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <bestellvorlage-loeschen-component
                v-model="bestellvorlage"
                @geloescht="loescheBestellvorlage"
            ></bestellvorlage-loeschen-component>

          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <div v-for="(position,index) in bestellvorlage.essensbestellpositionen" :key="index">
          <essens-bestellposition-component
              :index="index"
              :position="position"
              :standard-essen="index===0"
              @delete="$delete(bestellvorlage.essensbestellpositionen,index)"
              @input="$set(bestellvorlage.essensbestellpositionen, index, $event)"
          />
        </div>
        <essens-bestellung-summen-zeile-component :bestellung="bestellvorlage"/>
        <v-btn block class="text-right" text @click="bestellvorlage.essensbestellpositionen.push({essensarten:[],anzahl:0})">
          + Weitere Bestellposition
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="grey lighten-3">
        <v-spacer></v-spacer>
        <v-btn v-if="!$vuetify.breakpoint.mobile" :disabled="loading" depressed text @click="initBestellung">
          Eingaben löschen
        </v-btn>
        <v-btn :block="$vuetify.breakpoint.mobile" :disabled="bestellungDarfNochNichtAufgegebenWerden" :loading="speichernloading" color="primary" depressed @click="speichern">
          {{ bestellenButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader
        v-else
        class="mx-auto"
        max-width="500"
        type="image, actions"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import Bestellvorlage from "@/model/BestellvorlageVO";
import {bestellvorlageservice} from "@/service";
import EssensBestellpositionComponent from "@/components/essenbestellen/bestellung/EssensBestellpositionComponent";
import EssensBestellungSummenZeileComponent from "@/components/essenbestellen/bestellung/EssensBestellungSummenZeileComponent";
import BestellvorlageLoeschenComponent from "@/components/essenbestellen/bestellvorlage/BestellvorlageLoeschenComponent";
import {mapMutations, mapState, mapGetters} from "vuex";

export default {
  name: "BestellvorlageCompoent",
  components: {EssensBestellungSummenZeileComponent, EssensBestellpositionComponent, BestellvorlageLoeschenComponent},
  props: {
    kita: String,
  },
  data: () => ({
    bestellvorlage: new Bestellvorlage(),
    loading: true,
    speichernloading: false,
    info: null
  }),
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    sortiereBestellpositionen() {
      let normaleSpeisenPosition = this.bestellvorlage.essensbestellpositionen.find(position => position.essensarten.includes("Normale Speisen"))
      if(!normaleSpeisenPosition){
        normaleSpeisenPosition = {essensarten: ['Normale Speisen'], anzahl: 0}
        this.bestellvorlage.essensbestellpositionen.push(normaleSpeisenPosition)
      }
      let normaleSpeisenPositionIndex = this.bestellvorlage.essensbestellpositionen.indexOf(normaleSpeisenPosition)
      this.bestellvorlage.essensbestellpositionen.splice(normaleSpeisenPositionIndex, 1)
      this.bestellvorlage.essensbestellpositionen.unshift(normaleSpeisenPosition)
    },
    initBestellung() {
      this.bestellvorlage = new Bestellvorlage()
    },
    loescheBestellvorlage() {
      this.initBestellung()
      this.info = {
        typ: 'success',
        nachricht: 'Bestellvorlage erfolgreich gelöscht'
      }
      this.$emit("change")
    },
    speichern() {
      this.speichernloading = true
      this.bestellvorlage.kitaId = this.kita
      bestellvorlageservice.erstelleBestellvorlage(this.bestellvorlage).then(() => {
        this.info = {
          typ: "success",
          nachricht: "Bestellvorlage erfolgreich gespeichert"
        }
        this.addErfolgsNachricht("Bestellvorlage erfolgreich gespeichert")
      }).catch(error => {
        this.info = {
          typ: "error",
          nachricht: error.response.data
        }
      }).finally(() =>
          this.speichernloading = false
      )
    }
  },
  computed: {
    ...mapState(["essensarten"]),
    ...mapGetters(['hatRolleMitarbeiter']),
    bestellungDarfNochNichtAufgegebenWerden() {
      return this.bestellvorlage.essensbestellpositionen.some(position => position.anzahl===undefined || position.essensarten.length === 0)||this.summe<=0
    },
    bestellenButtonText() {
      return "Speichern"
    },
    summe() {
      return this.bestellvorlage.essensbestellpositionen
          .map(position => Number(position.anzahl||0))
          .reduce((sum, current) => sum + current, 0)
    },
  },
  created() {
    if (this.essensarten.length === 0) {
      this.refreshEssensarten()
    }
  },
  watch: {
    kita: {
      immediate: true,
      deep: true,
      handler(neueKita) {
        if (neueKita) {
          this.loading = true
          bestellvorlageservice.ladeBestellvorlage(this.kita)
              .then(response => {
                var responseBestellvorlage = response.data
                if (responseBestellvorlage.essensbestellpositionen.length > 0) {
                  this.bestellvorlage = responseBestellvorlage
                  this.sortiereBestellpositionen()
                } else {
                  this.initBestellung()
                }
              })
              .finally(() => this.loading = false)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>