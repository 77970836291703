<template>
  <div v-if="kita && bestellungszeitraum.length>0">
    <v-card v-if="!loading" outlined rounded>
      <v-alert
          v-if="info"
          :type="info.typ"
          :color="info.typ ==='success'?'success':'error'"
          class="ma-0"
      >
        {{ info.nachricht }}
      </v-alert>
      <v-card-title class="mt-0">
        Datum: {{ bestellungszeitraum.map(datum => new Date(datum).toLocaleDateString()).join(" bis ") }}
        <v-spacer></v-spacer>
        <v-menu
            v-if="bestellung.id && bestellungszeitraum.length===1"
            bottom
            left
            offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <essens-bestellung-stornieren-component
                v-model="bestellung"
                @storniert="storniereBestellung"
            ></essens-bestellung-stornieren-component>
            <v-divider></v-divider>
            <essens-bestellung-historie
                :historie="bestellung.historie">
            </essens-bestellung-historie>

          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <div v-for="(position,index) in bestellung.essensbestellpositionen" :key="index">
          <essens-bestellposition-component
              :index="index"
              :position="position"
              :standard-essen="index===0"
              @input="$set(bestellung.essensbestellpositionen, index, $event)"
              @delete="$delete(bestellung.essensbestellpositionen,index)"
          >
          </essens-bestellposition-component>
        </div>
        <essens-bestellung-summen-zeile-component :bestellung="bestellung"></essens-bestellung-summen-zeile-component>
        <v-btn text class="text-right" @click="bestellung.essensbestellpositionen.push({essensarten:[],anzahl:undefined})" block>
          + Weitere Bestellposition
        </v-btn>

        <v-divider class="my-3"/>
        <v-textarea class="mt-4"
                    dense
                    label="Bemerkung zur Bestellung"
                    outlined
                    v-model="bestellung.bemerkung"
                    counter="400"
                    rows="3"
                    maxlength="400"
        />
      </v-card-text>
      <v-divider></v-divider>
      <essens-bestellung-actions-component
          v-model="bestellung"
          :kita="kita"
          :bestellungszeitraum="bestellungszeitraum"
          @reset="initBestellung"
          @bestellung-gespeichert="vermerkeBestellungGespeichert"
          @error="vermerkeFehler($event)"
      >
      </essens-bestellung-actions-component>
    </v-card>
    <v-skeleton-loader
        v-else
        class="mx-auto"
        max-width="500"
        type="image, actions"
    ></v-skeleton-loader>
  </div>
  <div v-else-if="bestellungszeitraum.length===0">
    <v-alert type="warning" prominent border="top" rounded mode="">
      <p>
        Für den ausgewählten Tag ist aufgrund einer Bestellungspause oder eines Feiertages keine Bestellung möglich.
      </p>
      <p>
        Bitte wählen Sie ein anderes Datum.
      </p>
    </v-alert>
  </div>
</template>

<script>

import {essensbestellungservice} from "@/service";
import {mapActions, mapState} from "vuex";
import Essensbestellung from "@/model/EssensbestellungVO";
import EssensBestellpositionComponent from "@/components/essenbestellen/bestellung/EssensBestellpositionComponent";
import EssensBestellungSummenZeileComponent from "@/components/essenbestellen/bestellung/EssensBestellungSummenZeileComponent";
import EssensBestellungActionsComponent from "@/components/essenbestellen/bestellung/EssensBestellungActionsComponent";
import EssensBestellungStornierenComponent from "@/components/essenbestellen/bestellung/EssensBestellungStornierenComponent";
import EssensBestellungHistorie from "@/components/essenbestellen/bestellung/EssensBestellungHistorie";

export default {
  name: "EssensBestellungComponent",
  components: {
    EssensBestellungHistorie,
    EssensBestellungStornierenComponent,
    EssensBestellungActionsComponent,
    EssensBestellungSummenZeileComponent,
    EssensBestellpositionComponent
  },
  props: {
    kita: String,
    bestellungszeitraum: Array
  },
  data: () => ({
    bestellung: new Essensbestellung(),
    loading: true,
    info: null
  }),
  methods: {
    ...mapActions(["refreshEssensarten"]),
    initBestellung() {
      this.info = null
      this.bestellung = new Essensbestellung()
    },
    vermerkeBestellungGespeichert() {
      this.info = {
        typ: 'success',
        nachricht: 'Bestellung erfolgreich gespeichert'
      }
      this.ladeBestellung(this.bestellungszeitraum[0])
      this.$emit("change")
    },
    storniereBestellung() {
      this.initBestellung()
      this.info = {
        typ: 'success',
        nachricht: 'Bestellung erfolgreich storniert'
      }
      this.$emit("change")
    },
    vermerkeFehler(error) {
      this.info = {
        typ: 'error',
        nachricht: error.message
      }
    },
    sortiereBestellpositionen() {
      let normaleSpeisenPosition = this.bestellung.essensbestellpositionen.find(position => position.essensarten.includes("Normale Speisen"))
      if (!normaleSpeisenPosition) {
        normaleSpeisenPosition = {essensarten: ['Normale Speisen'], anzahl: 0}
        this.bestellung.essensbestellpositionen.push(normaleSpeisenPosition)
      }
      let normaleSpeisenPositionIndex = this.bestellung.essensbestellpositionen.indexOf(normaleSpeisenPosition)
      this.bestellung.essensbestellpositionen.splice(normaleSpeisenPositionIndex, 1)
      this.bestellung.essensbestellpositionen.unshift(normaleSpeisenPosition)
    },
    ladeBestellung(datum) {
      this.loading = true
      essensbestellungservice.ladeEinzelneEssensbestellung(this.kita, datum).then(
          response => {
            if (response.status === 200) {
              this.bestellung = response.data
              this.sortiereBestellpositionen()
            } else {
              this.initBestellung()
            }

          }
      ).finally(() => this.loading = false)
    }
  },
  computed: {
    ...mapState(["essensarten"]),
  },
  created() {
    if (this.essensarten.length === 0) {
      this.refreshEssensarten()
    }
  },
  watch: {
    bestellungszeitraum: {
      immediate: true,
      deep: true,
      handler(neuerZeitraum, alterZeitraum) {
        this.info = null
        if (neuerZeitraum !== alterZeitraum) {
          if (neuerZeitraum && neuerZeitraum[0]) {
            this.ladeBestellung(neuerZeitraum[0])
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
