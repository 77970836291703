<template>
  <v-textarea
      dense
      v-model="sonderwunsch"
      label="Sonderwünsche"
      hint="Wiederkehrende Essenwünsche, die zu jede Bestellung hinzugefügt werden"
      counter="400"
      outlined
      rows="3"
      @input="handleInput"
  />
</template>

<script>
export default {
  name: "KitaSonderwunschComponent",
  props:['value'],
  data() {
    return {
      sonderwunsch: '',
    }
  },
  watch:{
    value:{
      immediate: true,
      handler(newValue){
        this.sonderwunsch = newValue
      }
    }
  },
  methods:{
    handleInput(){
      this.$emit('input',this.sonderwunsch)
    }
  }
}
</script>

<style scoped>

</style>