<template>
  <v-dialog
      :key="kitaerstellenkey"
      v-model="dialog"
      max-width="600px"
      persistent
      v-if="hatRolleMitarbeiter"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          block
          color="primary"
          depressed
          @click="fehler=null"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Neue Einrichtung anlegen
      </v-btn>
    </template>
    <v-card rounded="lg">
      <v-alert
          v-if="fehler"
          class="ma-0"
          color="error"
          type="error"
      >{{ fehler }}
      </v-alert>
      <v-card-title>
        <span class="headline">Neue Einrichtung anlegen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col
                cols="12"
            >
              <kita-name-component
                  ref="kitanamecomponent"
                  v-model="neueKitaVO.name"
              />
            </v-col>
            <v-col
                cols="12"
            >
              <kita-geschaeftsname-component
                  v-model="neueKitaVO.geschaeftsname"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
                cols="12"
            >
              <kita-ansprechpartner-component
                  v-model="neueKitaVO.ansprechpartner"
              />
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <kita-kundennummer-component
                  v-model="neueKitaVO.kundenummer"
              >
              </kita-kundennummer-component>
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <kita-telefonnummer-component
                  v-model="neueKitaVO.telefonnummer"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
                cols="12"
            >
              <kita-sonderwunsch-component
                  ref="kitasonderwunschcomponent"
                  v-model="neueKitaVO.sonderwunsch"
              />
            </v-col>
            <v-col cols="12">
              <benutzerzuweisen-component v-model="neueKitaVO.benutzer">
              </benutzerzuweisen-component>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <kita-mit-dessert-component
                  v-model="neueKitaVO.mitDessert"
                  :disabled="false"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <liefertag-auswahl-component
                  :kitaliefertage="neueKitaVO.liefertage"
                  @change="neueKitaVO.liefertage = $event"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>*Pflichtfelder</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            @click="leereKomponente"
        >
          Abbrechen
        </v-btn>
        <v-btn
            :disabled="neueKitaVO.name.trim().length === 0"
            :loading="loading"
            color="primary"
            depressed
            @click="erstelleKita"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import KitaNameComponent from "@/components/verwaltung/kitaverwaltung/components/KitaNameComponent";
import KitaSonderwunschComponent from "@/components/verwaltung/kitaverwaltung/components/KitaSonderwunschComponent";
import LiefertagAuswahlComponent from "@/components/verwaltung/kitaverwaltung/components/LiefertagAuswahlComponent";
import KitaMitDessertComponent from "@/components/verwaltung/kitaverwaltung/components/KitaMitDessertComponent.vue";
import NeueKita from "@/model/NeueKita";
import {kitaservice} from "@/service";
import KitaGeschaeftsnameComponent from "@/components/verwaltung/kitaverwaltung/components/KitaGeschaeftsnameComponent";
import KitaKundennummerComponent from "@/components/verwaltung/kitaverwaltung/components/KitaKundennummerComponent";
import KitaTelefonnummerComponent from "@/components/verwaltung/kitaverwaltung/components/KitaTelefonnummerComponent";
import KitaAnsprechpartnerComponent from "@/components/verwaltung/kitaverwaltung/components/KitaAnsprechpartnerComponent";
import BenutzerzuweisenComponent from "@/components/verwaltung/kitaverwaltung/components/BenutzerZuweisenComponent";

export default {
  name: "KitaErstellenComponent",
  components: {
    BenutzerzuweisenComponent,
    KitaAnsprechpartnerComponent,
    KitaTelefonnummerComponent,
    KitaKundennummerComponent, KitaGeschaeftsnameComponent, LiefertagAuswahlComponent, KitaSonderwunschComponent, KitaNameComponent, KitaMitDessertComponent
  },
  data() {
    return {
      dialog: false,
      fehler: null,
      neueKitaVO: new NeueKita(),
      loading: false,
      kitaerstellenkey: 0,
      validierung: {
        nameNochNichtVergeben: true
      },
    }
  },
  computed: {
    ...mapGetters(['hatRolleMitarbeiter'])
  },
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    ...mapActions(['refreshKitas']),
    leereKomponente() {
      this.dialog = false
      this.neueKitaVO = new NeueKita()
      this.kitaerstellenkey += 1
    },
    erstelleKita() {
      this.loading = true
      this.fehler = null
      kitaservice.kitaAnlegen(this.neueKitaVO).then(
          response => {
            this.addErfolgsNachricht("Neue Einrichtung angelegt")
            this.$emit('neueKita', response.data)
            this.refreshKitas()
            this.leereKomponente()
          }
      ).catch(
          (error) => {
            if (error.response.data.code === '0100101') {
              this.$refs.kitanamecomponent.setNameBereitsVergeben()
            } else {
              this.fehler = error.response.data.message
            }
          }
      ).finally(
          () => (
              this.loading = false
          )
      )
    }
  },
}
</script>

<style scoped>

</style>