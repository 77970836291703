<template>
  <v-dialog v-model="dialogZeigen" persistent width="700px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ titelText }}</span>
        <v-spacer>
        </v-spacer>

        <span v-if="kitainformation.status">
          <span v-if="kitainformation.status==='versendet'" class="success--text text-subtitle-2">
             <v-icon color="success">mdi-send-check</v-icon> Versendet
          </span>
        </span>
        <v-btn
            v-if="!isNeu && kitainformation.status==='offen'"
            :loading="amLaden"
            :disabled="textgeandert"
            class="info--text"
            text
            @click="versendeInformation"
        >
          <v-icon left small>
            mdi-send
          </v-icon>
          An Einrichtungen versenden
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-textarea ref="infotextkita"
                    v-model="kitainformation.text"
                    :readonly="amLaden || (!isNeu && kitainformation.status==='versendet')"
                    :rules="[rules.required, rules.length(5),rules.maxlength(1000)]"
                    counter
                    hint="Dieser Text wird für alle Mitarbeiter auf der Startseite angezeigt. Nach dem Speichern dieses Textes haben Sie noch die Möglichkeit diesen Text zu bearbeiten.
            Erst wenn Sie den Text versenden, wird er für alle Kita-Mitarbeiter sichtbar. Nach dem Versenden ist der Text nicht mehr bearbeitbar."
                    label="Information für Einrichtungen"
                    name="Informationtext"
                    outlined
                    persistent-hint
        ></v-textarea>
        <v-simple-table v-if="kitainformation.kitabenutzerinformationen && kitainformation.kitabenutzerinformationen.length>0"
                        dense
                        fixed-header
                        height="400px"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left" style="width: 90%">
                Name
              </th>
              <th class="text-center" style="width: 10%">
                Gelesen
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="informierterBenutzer in kitainformation.kitabenutzerinformationen"
                :key="informierterBenutzer.name"
            >
              <td>{{ informierterBenutzer.benutzername }}</td>
              <td class="text-center">
                <v-icon v-if="informierterBenutzer.status==='gelesen'" color="success">mdi-check</v-icon>
                <v-icon v-else>mdi-clock-outline</v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogZeigen=false">
          Abbrechen
        </v-btn>
        <v-btn
            v-if="!isNeu && kitainformation.status!=='versendet'"
            :disabled="amLaden"
            :loading="amLaden" class="error" depressed
            @click="loeschen">
          <v-icon left small>
            mdi-delete
          </v-icon>
          Löschen
        </v-btn>
        <v-btn
            v-if="isNeu || kitainformation.status!=='versendet'"
            :disabled="amLaden || nichtValideEingabenVorhanden"
            :loading="amLaden" class="primary" depressed
            @click="speichern">
          <v-icon left small>
            mdi-content-save
          </v-icon>
          Speichern
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {kitainformationenservice} from "@/service";
import {mapMutations} from "vuex";

export default {
  name: "KitaInformationComponent",
  props: {
    value: {},
    kitainformation: {
      id: null,
      text: ''
    }
  },
  data: () => ({
    amLaden: false,
    originalerText: '',
    rules: {
      length: len => v => (v || '').length >= len || `Die Information muss mindestes ${len} Zeichen haben.`,
      maxlength: len => v => (v || '').length <= len || `Die Information darf maximal ${len} Zeichen haben.`,
      required: v => !!v || 'Bitte geben Sie eine Information an.',
    },
  }),
  created() {
    this.originalerText = this.kitainformation.text
  },
  computed: {
    nichtValideEingabenVorhanden() {
      return this.kitainformation.text <= 5 || this.kitainformation.text >= 1000;
    },
    titelText() {
      if (this.isNeu) {
        return 'Neue Information anlegen'
      }
      return 'Information bearbeiten';
    },
    isNeu() {
      return !this.kitainformation || this.kitainformation.id === null
    },
    dialogZeigen: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    speichern() {
      this.amLaden = true
      if (this.isNeu) {
        kitainformationenservice.uC01013NeueInformationSpeichern(this.kitainformation)
            .then(response => {
              this.$emit('neueInformation', response.data)
              this.addErfolgsNachricht('Die Information wurde erfolgreich gespeichert.')
              this.$emit('success')
              this.dialogZeigen = false
            }).catch(error => {
          console.log(error.response)
        }).finally(() => {
          this.amLaden = false
        })
      } else {
        kitainformationenservice.uC01016KitainformationAendern(this.kitainformation)
            .then(() => {
              this.addErfolgsNachricht('Die Information wurde erfolgreich gespeichert.')
              this.$emit('success')
              this.dialogZeigen = false
            }).catch(error => {
          console.log(error.response)
        }).finally(() => {
          this.amLaden = false
        })
      }
    },
    async versendeInformation() {
      this.amLaden = true
      await kitainformationenservice.uC01016KitainformationAendern(this.kitainformation)
      await kitainformationenservice.uC01014InformationVersenden(this.kitainformation.id)
          .then(() => {
            this.addErfolgsNachricht('Die Information wurde erfolgreich versendet.')
            this.$emit('success')
            this.dialogZeigen = false
          }).catch(error => {
        console.log(error.response)
      }).finally(() => {
        this.amLaden = false
      })
    },
    loeschen() {
      this.amLaden = true
      kitainformationenservice.uC01015KitainformationLoeschen(this.kitainformation.id)
          .then(() => {
            this.addErfolgsNachricht('Die Information wurde erfolgreich gelöscht.')
            this.$emit('success')
            this.dialogZeigen = false
          }).catch(error => {
        console.log(error.response)
      }).finally(() => {
        this.amLaden = false
      })
    }
  }
}
</script>

<style scoped>

</style>