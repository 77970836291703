<template>
  <div>
    <v-container class="fill-height" fill-height>
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h4>
            Ihre Daten werden geladen. Gleich geht es los...
          </h4>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {benutzerservice} from "@/service";
import {mapMutations} from "vuex";

export default {
  name: "InitialisiereView",
  methods: {
    ...mapMutations(['setzeBenutzer'])
  },
  mounted() {
    benutzerservice.benutzerMeGet().then(response => {
      this.setzeBenutzer(response.data)
      const redirectPath = this.$route.query.red
      if (redirectPath) {
        this.$router.push(redirectPath)
      } else {
        this.$router.push('/')
      }
    }).catch(error => {
      const errorstatus = error.response.status
      if (errorstatus === 401 || errorstatus === 403) {
        this.$router.push({name: 'zugriffVerweigert'})
      }
    })
  }
}
</script>

<style scoped>

</style>