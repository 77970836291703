<template>
  <div v-if="!oidcIsAuthenticated">
    <v-btn

        :to="{name:'Anmelden'}"
        block
        color="primary"
        outlined
    >
      <v-icon

          dark
      >mdi-account
      </v-icon>
      Anmelden
    </v-btn>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AnmeldenButtonComponent",
  computed: {
    ...mapGetters(['oidcIsAuthenticated'])
  }
}
</script>

<style scoped>

</style>