<template>
    <v-dialog
        v-model="dialog"
        max-width="600px"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="red"
            depressed
            v-bind="attrs"
            v-on="on"
            icon
            small
        >
          <v-icon left>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

      <v-card rounded="lg">
        <v-card-title class="text-h6  " style="word-break: break-word">
          Möchten Sie die Allergenkennzeichnung &nbsp; <b>"{{ allergenkennzeichnungName }}"</b> wirklich löschen?
        </v-card-title>

        <v-card-text>
          Möchten Sie die Allergenkennzeichnung &nbsp; <b>"{{ allergenkennzeichnungName }}"</b> wirklich löschen?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog=false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="error"
              depressed
              :loading="loading"
              @click="loescheAllergenkennzeichnung"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations} from "vuex";
import {allergenkennzeichnungenservice} from "@/service";

export default {
  props: ['allergenkennzeichnungName'],
  name: "AllergenkennzeichnungLoeschen",
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(["addErfolgsNachricht"]),
    loescheAllergenkennzeichnung() {
      this.loading = true;
      allergenkennzeichnungenservice.loescheAllergenkennzeichnung(this.allergenkennzeichnungName)
          .then(() => {
            this.addErfolgsNachricht("Allergenkennzeichnung " + this.allergenkennzeichnungName + " gelöscht")
            this.$emit("allergenkennzeichnungGeloescht")
            this.dialog = false
          }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>