<template>
  <v-container>
    <v-row class="px-3">
      <v-col
          sm="7"
          cols="12"
      >
        <h2>Bestellungspausen verwalten</h2>
      </v-col>
      <v-col
          sm="5"
          cols="12"
          class="text-sm-right"
          align-self="center"
      >
        <bestellungspause-erstellen-component
            @neueBestellungspause="ladeBestellungspausen"
        />
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col
          cols="12"
          sm="6"
          md="3"
      >
        <v-select
            outlined
            dense
            v-model="jahrfilter"
            label="Nach Jahr filtern"
            :items="jahre"
            hide-details
            @change="ladeBestellungspausen"
        />
      </v-col>
      <v-col
          cols="12"
          sm="6"
          md="3"
      >
        <v-select
            outlined
            dense
            v-model="monatfilter"
            label="Nach Monat filtern"
            :items="monate"
            item-text="name"
            item-value="nummer"
            hide-details
            clearable
            @change="ladeBestellungspausen"
        />
      </v-col>
      <v-col
          cols="12"
          sm="12"
          md="6"
      >
        <kita-filter-component v-model="kitafilter" @input="ladeBestellungspausen">
        </kita-filter-component>
      </v-col>
    </v-row>
    <v-row class="px-3">
      <v-col cols="12">
        <bestellungspausen-kalendar-component
            :bestellungspausen-zeitraeume="bestellungspausenzeitraueme"
            :jahr="jahrfilter"
            :monat="monatfilter"
        />
      </v-col>

    </v-row>
    <v-row class="px-3">
      <v-spacer/>
      <v-col class="text-right">
        <p class="caption">
          {{ anzahlBestellungspausen }} Ergebnisse gefunden
        </p>
      </v-col>
    </v-row>
    <v-data-table
        :disable-pagination="true"
        :header-props="{
        'sort-by-text':'Sortieren nach'
      }"
        :headers="headers"
        :items="bestellungspausen"
        :loading="loading"
        class="px-3"
        dense
        hide-default-footer
        loading-text="Bestellungspausen werden geladen"
        no-data-text="Keine Bestellungspausen gefunden"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <bestellungspause-loeschen-component :item="item" @pauseGeloescht="ladeBestellungspausen()"></bestellungspause-loeschen-component>
      </template>
      <template v-slot:[`item.datum`]="{ item }">
        {{ formatiereDatum(item.datum) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import BestellungspauseErstellenComponent from "@/components/essenbestellen/bestellungspause/BestellungspauseErstellenComponent"
import {bestellungspauseservice} from "@/service";
import BestellungspauseLoeschenComponent from "@/components/essenbestellen/bestellungspause/BestellungspauseLoeschenComponent";
import KitaFilterComponent from "@/components/kita/KitaFilterComponent";
import JahrMonatMixin from "@/components/tools/JahrMonatMixin";
import BestellungspausenKalendarComponent from "@/components/essenbestellen/bestellungspause/BestellungspausenKalendarComponent";

export default {
  name: 'BestellungspauseListeComponent',
  components: {BestellungspausenKalendarComponent, KitaFilterComponent, BestellungspauseLoeschenComponent, BestellungspauseErstellenComponent},
  mixins: [JahrMonatMixin],
  data() {
    return {
      bestellungspausenzeitraueme: [],
      anzahlBestellungspausen: 0,
      bestellungspausen: [],
      headers: [
        {
          text: 'Einrichtung',
          align: 'start',
          value: 'kitaname',
        },
        {
          text: 'Datum',
          value: 'datum'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
          width: '50px'
        }
      ],
      jahrfilter: new Date().getFullYear().toString(),
      kitafilter: undefined,
      loading: true,
      monatfilter: (new Date().getUTCMonth() + 1).toString(),
    }
  },
  mounted() {
    this.ladeBestellungspausen()
  },
  methods: {
    formatiereDatum(datum) {
      const [year, month, day] = datum.split('-')
      return `${day}.${month}.${year}`
    },
    ladeBestellungspausen() {
      this.loading = true
      bestellungspauseservice.ladeBestellungspausen(
          this.jahrfilter,
          this.kitafilter ? this.kitafilter : undefined,
          this.monatfilter ? this.monatfilter : undefined)
          .then((response) => {
            this.bestellungspausen = response.data.bestellungspausen
            this.bestellungspausenzeitraueme = response.data.bestellungspausenZeitraeume
            this.anzahlBestellungspausen = response.data.bestellungspausen.length
          }).catch((error) => {
        console.log(error.response)
      }).finally(() =>
          this.loading = false
      )
    },
  }
}
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>