<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon :v-ripple=true
                color="red"
                v-bind="attrs"
                v-on="on">
          mdi-delete
        </v-icon>
      </template>

      <v-card>
        <v-card-title class="text-h6  " style="word-break: break-word">
          Möchten Sie die Essensart &nbsp; <b>"{{ value }}"</b> wirklich löschen?
        </v-card-title>

        <v-card-text>
          Die Essensart bleibt nach dem Löschen in bereits getätigten Bestellungen und Bestellvorlagen bestehen.
          Bitte nutzen Sie die Detailansicht
          <v-icon small>mdi-magnify</v-icon>
          um vor dem Löschen die Bestellungen bzw. Bestellvorlagen zu prüfen.
          In neuen Bestellungen kann die Essensart nicht mehr verwendet werden.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog=false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="error"
              depressed
              :loading="loading"
              @click="loescheArt"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {essensartenservice} from "@/service";

export default {
  props: ['value'],
  name: "EssensartLoeschen",
  data() {
    return {
      dialog: false,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(["addErfolgsNachricht"]),
    loescheArt() {
      this.loading = true;
      essensartenservice.deleteEssensart(this.value)
          .then(() => {
            this.addErfolgsNachricht("Essensart " + this.value + " gelöscht")
            this.$emit("change")
            this.dialog = false
          }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>