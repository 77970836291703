<template>
  <v-text-field
      v-model="kitageschaeftsname"
      :maxlength="200"
      :value="kitageschaeftsname"
      counter="200"
      dense
      label="Geschäftsname"
      outlined
      @input="handleInput"
  />
</template>

<script>
export default {
  name: "KitaGeschaeftsnameComponent",
  props: ['value'],
  data() {
    return {
      kitageschaeftsname: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.kitageschaeftsname = newValue
      }
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.kitageschaeftsname)
    },
  }
}
</script>

<style scoped>

</style>