<template>
  <v-card flat outlined rounded v-if="kita">
    <v-alert v-if="isWocheKopieren && this.datum.length===0" rounded type="info">
      Bitte wählen Sie zuerst die Woche, die kopiert werden soll.
    </v-alert>
    <v-card-subtitle>
      <h3>Art der Bestellung</h3>
      <v-radio-group v-model="bestellungstyp"
                     dense
                     mandatory
                     row
                     @change="handleBestellungstypGeaendert($event)"
      >
        <v-radio
            v-for="n in bestellungstypen"
            :key="n.text"
            :label="`${n.text}`"
            :value="n.text"
            color="primary"

        ></v-radio>
      </v-radio-group>
      {{ bestellungsbeschreibung }}
    </v-card-subtitle>
    <v-progress-linear v-if="ladewarteschlange.length>0" indeterminate>
    </v-progress-linear>
    <v-date-picker

        scrollable
        show-week
        :min="fruehestesDatumZurAuswahl"
        :max="maxDatum"
        locale="de"
        v-model="datum"
        color="primary"
        first-day-of-week="1"
        locale-first-day-of-year="4"
        :events="monatsevents"
        :allowed-dates="erlaubteTage"
        :range="isBestellungFuerZeitraum"
        no-title
        :multiple="isWocheKopieren"
        full-width
        flat
        @update:picker-date="datePickerMonatJahr = $event"
        show-adjacent-months
        @input="handleDatepickerInput($event)"
    >
    </v-date-picker>
    <v-card-actions>

      <v-row>
        <v-col>
          <div class="caption mx-1">
            <b>Legende:</b>
            <div>
              <span :class="legendenpunkt+keineLiefertageFarbe"></span>Keine standardmäßigen Lieferungen
            </div>
            <div>
              <span :class="legendenpunkt+feiertagsFarbe"></span>Feiertage
            </div>
            <div>
              <span :class="legendenpunkt+bestellungspausenFarbe"></span>Bestellungspausen
            </div>
            <div>
              <span :class="legendenpunkt+bestellungsFarbe"></span>Bestellungen
            </div>
          </div>
        </v-col>
      </v-row>
      <v-btn v-if="ladewarteschlange.length===0" :ripple="false" class="calendar-lade-info" color="primary" icon text>
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import {bestellungspauseservice, essensbestellungservice, kitaservice} from "@/service";
import Datumutil from "@/service/datumutil";
import {BestellungsTypEnum} from "@/model/BestellungsTypEnum";
import moment from "moment";

export default Vue.extend({
  name: 'EssensDatumAuswahlComponent',
  props: {
    kita: String
  },
  data: () => ({

    datum: undefined,
    feiertage: [],
    bestellungspausen: [],
    datePickerMonatJahr: String(),
    bestellungen: [],
    kitaVO: {},
    feiertagsFarbe: 'red',
    keineLiefertageFarbe: 'grey',
    bestellungspausenFarbe: 'blue',
    bestellungsFarbe: 'deep-purple lighten-1',
    legendenpunkt: 'dot mr-1 ',
    ladewarteschlange: [],
    bestellungstypen: [
      {
        icon: 'mdi-calendar',
        text: BestellungsTypEnum.Einzel,
        beschreibung: 'Bestellen Sie für einen einzelnen Tag.'

      },
      {
        icon: 'mdi-calendar-range',
        text: BestellungsTypEnum.Zeitraum,
        beschreibung: 'Bestellen Sie für einen oder mehrere Tage.'

      },
      {
        icon: 'mdi-content-copy',
        text: BestellungsTypEnum.WocheKopieren,
        beschreibung: 'Wählen Sie bitte die Woche die kopiert werden soll'
      }
    ],
    bestellungstyp: '',
  }),
  computed: {
    maxDatum() {
      let naechstesJahr = new Date().getFullYear() + 1
      return naechstesJahr + "12-31"
    },
    bestellungsbeschreibung() {
      if (this.bestellungstyp) {
        return this.bestellungstypen.find(t => t.text === this.bestellungstyp).beschreibung
      } else {
        return null
      }
    },
    isBestellungFuerZeitraum() {
      return this.bestellungstyp === BestellungsTypEnum.Zeitraum
    },
    isWocheKopieren() {
      return this.bestellungstyp === BestellungsTypEnum.WocheKopieren
    },
    fruehestesDatumZurAuswahl() {
      if (this.isWocheKopieren) {
        return Datumutil.ersterMontagDesMonats(new Date().getFullYear(), new Date().getMonth()).toISOString()
      } else {
        return new Date().toISOString()
      }
    }
  },
  methods: {
    fuegeAlleGueltigenWochentageZurAuswahlHinzu(date) {
      const current = new Date(date)
      current.setDate((current.getDate() - current.getDay() + 1));
      for (let i = 0; i < 7; i++) {
        let currentAlsIsoString = current.toISOString().split('T')[0]
        if (this.pruefeTagErlaubt(currentAlsIsoString)) {
          this.datum.push(
              currentAlsIsoString
          );
        }
        current.setDate(current.getDate() + 1);
      }
    },
    handleDatepickerInput(event) {
      if (this.isWocheKopieren) {
        if (this.datum.length === 1) {
          //woche wurde gewaehlt
          this.fuegeAlleGueltigenWochentageZurAuswahlHinzu(event[0])
        } else {
          this.datum = []
          const ausgewahlteWochenMap = this.baueWochenMapAusDaten(event)
          if (ausgewahlteWochenMap.size !== 1) {
            this.baueWochenMapAusDaten(event).forEach((daten) => {
              //Suche die Woche wo nur ein Datum gewählt ist => ist die neu gewählte Woche
              if (daten.length === 1) {
                this.fuegeAlleGueltigenWochentageZurAuswahlHinzu(daten[0])
              }
            })
          }
        }
        this.datum = [...new Set(this.datum)]
      }
    },
    baueWochenMapAusDaten(daten) {
      const wochenDatumMap = new Map()
      daten.forEach(d => {
            if (wochenDatumMap.has(moment(d).year() * 100 + moment(d).isoWeek())) {
              const datenProWoche = wochenDatumMap.get(moment(d).year() * 100 + moment(d).isoWeek())
              datenProWoche.push(d)
              wochenDatumMap.set(moment(d).year() * 100 + moment(d).isoWeek(), datenProWoche)
            } else {
              const datenProWoche = []
              datenProWoche.push(d)
              wochenDatumMap.set(moment(d).year() * 100 + moment(d).isoWeek(), datenProWoche)
            }
          }
      )
      return new Map([...wochenDatumMap].sort());
    },
    handleBestellungstypGeaendert(event) {
      this.bestellungstyp = event
      if (event === BestellungsTypEnum.Einzel) {
        if (this.datum && typeof this.datum !== 'string') {
          this.datum = this.datum[0]
        }
      }
      if (event === BestellungsTypEnum.Zeitraum) {
        if (this.datum && typeof this.datum !== 'string') {
          this.datum = []
        }
      }
      if (event === BestellungsTypEnum.WocheKopieren) {
        this.datum = []
      }
      this.$emit('bestellungstypGeandert', event)
    },
    sortiereDaten(daten) {
      if (typeof daten === 'string') {
        return [daten]
      } else {
        return daten.sort()
      }
    },
    monatsevents(date) {
      let besondererTagKennzeichen = []
      if (this.feiertage.map(feiertag => feiertag.datum).includes(date)) {
        besondererTagKennzeichen.push(this.feiertagsFarbe)
      }
      let wochentag = new Date(date).getDay() === 0 ? 7 : new Date(date).getDay()
      if (this.kitaVO.liefertage && !this.kitaVO.liefertage.includes(wochentag)) {
        besondererTagKennzeichen.push(this.keineLiefertageFarbe)
      }
      if (this.bestellungspausen && this.bestellungspausen.map(pause => pause.datum).includes(date)) {
        besondererTagKennzeichen.push(this.bestellungspausenFarbe)
      }
      if (this.bestellungen && this.bestellungen.map(bestellung => bestellung.datum).includes(date)) {
        besondererTagKennzeichen.push(this.bestellungsFarbe)
      }
      return besondererTagKennzeichen
    },
    pruefeTagErlaubt(date) {
      let wochentag = new Date(date).getDay() === 0 ? 7 : new Date(date).getDay()
      return !this.feiertage.map(feiertag => feiertag.datum).includes(date) && !this.bestellungspausen.map(pause => pause.datum).includes(date) && (this.kitaVO.liefertage && this.kitaVO?.liefertage.includes(wochentag));
    },

    erlaubteTage(date) {
      if (this.ladewarteschlange.length > 0) {
        return false
      }
      const tagIstErlaubt = this.pruefeTagErlaubt(date);
      if (!tagIstErlaubt) {
        if (this.datum && typeof this.datum !== 'string') {
          if (this.datum[0] === date
          ) {
            this.datum = []
          }
        } else if (this.datum && typeof this.datum === 'string') {
          if (this.datum === date) {
            this.datum = null
          }
        }
      }
      return tagIstErlaubt
    },
    updateTage: function (monatJahr) {
      const monatJahrSplit = monatJahr.split('-')
      let jahr = Number(monatJahrSplit[0])
      if (monatJahrSplit[1]) {
        let monat = Number(monatJahrSplit[1])
        this.$emit('change-betrachteter-monate', {monat: monat, jahr: jahr})
        this.ladeFeiertage(jahr, monat)
        this.ladeBestellungspausen(jahr, monat)
        this.ladeBestellungen(jahr, monat)
      }
    },
    refreshBestellungen() {
      const monatJahrSplit = this.datePickerMonatJahr.split('-')
      let jahr = Number(monatJahrSplit[0])
      if (monatJahrSplit[1]) {
        this.ladeBestellungen(jahr, Number(monatJahrSplit[1]))
      }
    },
    ladeFeiertage(jahr, monat) {
      this.ladewarteschlange.push("1")
      essensbestellungservice.ladeFeiertage(jahr, monat).then(
          response => {
            this.feiertage = response.data
          }
      ).catch((error) => {
        console.log(error)
      }).finally(() => this.ladewarteschlange.pop())
    },
    ladeLiefertage() {
      this.ladewarteschlange.push("1")
      kitaservice.kitaAnzeigen(this.kita).then(response => {
        this.kitaVO = response.data
      }).finally(() =>
          this.ladewarteschlange.pop()
      )
    },
    ladeBestellungspausen(jahr, monat) {
      if (this.kita) {
        this.ladewarteschlange.push("1")
        bestellungspauseservice.ladeBestellungspausen(jahr, this.kita, monat).then(
            response => {
              this.bestellungspausen = response.data.bestellungspausen
            }
        ).finally(() =>
            this.ladewarteschlange.pop()
        )
      }
    },
    ladeBestellungen(jahr, monat) {
      if (this.kita) {
        this.ladewarteschlange.push("1")
        essensbestellungservice.ladeEssensbestellungen(jahr, this.kita, monat).then(
            response => {
              this.bestellungen = response.data
            }
        ).finally(() =>
            this.ladewarteschlange.pop()
        )
      }
    }
  },
  created() {
    this.datum = new Date().toISOString().substr(0, 10)
  },
  watch: {
    datum: {
      immediate: true,
      deep: true,
      handler(neu, alt) {
        if (neu && neu != alt) {
          this.$emit('change', this.sortiereDaten(neu))
        } else if (!neu) {
          this.$emit('change', [])
        }
      }
    },
    kita: {
      immediate: true,
      handler(neu) {
        if (neu != null) {
          this.ladeLiefertage()
        }
      }
    },
    datePickerMonatJahr: {
      immediate: true,
      handler(neu, alt) {
        if (neu && neu != alt) {
          this.updateTage(neu)
        }
      }
    }
  }
})
</script>

<style>
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.calendar-lade-info {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>