<template>
  <div>
    <v-dialog
        v-model="dialog"
        scrollable
        width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
            ripple
            v-bind="attrs"
            v-on="on"
        >
          <v-list-item-icon>
            <v-icon>
              mdi-timeline-clock
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Änderungshistorie
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-card width="100%">
        <v-card-title>Änderungshistorie</v-card-title>
        <v-card-text>
          <v-timeline dense>
            <v-timeline-item v-for="item in historie"
                             :key="item.erstelldatum" small>
              <v-row justify="space-between">
                <v-col align-self="center" class="pa-0" cols="3">
                  {{ new Date(item.erstelldatum).toLocaleString() }}
                </v-col>
                <v-col
                    class="text-left px-0 ma-0"
                    cols="9"
                >
                  <v-card class="py-0" outlined style="white-space: pre-line">
                    <v-card-text class="py-1 ">
                      <v-icon class="mr-1" dense>mdi-account left</v-icon>
                      <b>{{ item.ersteller }}</b>
                      {{ verschoenere(item.aenderung) }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              text
              @click="dialog = false"
          >
            Schliessen
          </v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>


  </div>

</template>

<script>
export default {
  name: "EssensBestellungHistorie",
  props: {
    historie: Array,
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    verschoenere(text) {
      return text.replaceAll("&gt;", ">")
    }
  }
}
</script>

<style scoped>

</style>