<template>
  <v-select
      v-model="benutzer"
      class="benutzerselect"
      :items="verfuegbareBenuter"
      label="Benutzer"
      item-text="name"
      multiple
      :return-object=true
      chips
      deletable-chips
      hide-details
      hide-selected
      cache-items
      outlined
      small-chips
      dense
      @input="handleInput"
      no-data-text="Keine weiteren Benutzer ohne zugewiesener Einrichtung gefunden"
      :menu-props="{ down: true, offsetY: true }"
  >
  </v-select>
</template>

<script>
import {benutzerservice} from "@/service";

export default {
  name: "BenutzerzuweisenComponent",
  props: ['value'],
  data() {
    return {
      benutzer: [],
      benutzerOhneZugewieseneKita: [],
    }
  },
  computed: {
    verfuegbareBenuter() {
      return [...this.benutzer || [], ...this.benutzerOhneZugewieseneKita || []]
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.benutzer = newValue
        this.ladeBenutzerOhneKitas()
      }
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.benutzer)
    },
    ladeBenutzerOhneKitas() {
      benutzerservice.getBenutzer("kita", false).then(response => {
        this.benutzerOhneZugewieseneKita = response.data
      }).catch(error => {
        console.log(error.response)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.benutzerselect::v-deep .v-chip {

  /* chip background color */
  background-color: var(--v-primary-base);

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>