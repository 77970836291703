<template>
  <kita-liste-component />
</template>

<script>
import KitaListeComponent from "@/components/verwaltung/kitaverwaltung/KitaAnzeigenComponent.vue"

export default {
  name: "KitaVerwaltungView",
  components: { KitaListeComponent }
}
</script>

<style scoped>

</style>