<template>
  <v-card outlined>
    <v-simple-table dense fixed-header height="400px">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Essensart
          </th>
          <th class="text-center">
            Löschen
          </th>
          <th class="text-center">
            Details
          </th>
        </tr>
        </thead>
        <colgroup>
          <col span="1" style="width: 84%;">
          <col span="1" style="width: 8%;">
          <col span="1" style="width: 8%;">
        </colgroup>
        <tbody>
        <tr
            v-for="art in essensarten"
            :key="art"

        >
          <td>{{ art.art }}</td>
          <td>
            <essensart-loeschen v-if="art.aenderbar" v-model="art.art" @change="$emit('change')"/>
          <td>
            <div class="text-center">
              <v-icon :v-ripple=true @click="$emit('update',art.art)">
                mdi-magnify
              </v-icon>
            </div>

          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>

</template>

<script>
import {mapState} from "vuex";
import EssensartLoeschen from "@/components/verwaltung/essensarten/EssensartLoeschen";

export default {
  name: "EssensartenUebersicht",
  components: {EssensartLoeschen},
  data: () => ({}),
  computed: {
    ...mapState(["essensarten"]),
  },

}
</script>

<style scoped>

</style>