<template>
    <div id="printMe">
      <v-data-table
          no-data-text="An diesem Tag liegen keine Bestellungen vor"
          loading-text="Bitte warten"
          dense
          :loading="loading"
          :headers="headers"
          :items="gefilterteBestellungen"
          hide-default-footer
          disable-pagination
          :calculate-widths=true
          class="essensauswertung"
      >
        <template v-slot:body="props">
          <v-progress-linear v-if="loading">
          </v-progress-linear>
          <draggable
              :list="props.items"
              tag="tbody"
              :disabled="!reihenfolgeAnpassen"
              v-bind="dragOptions"
              @change="updateKitaAuswertungsIndex"
              :class="reihenfolgeAnpassen?'anpassbareZeilen' : ''"
          >
            <tr
                v-for="(item, index) in props.items"
                :key="index"
                :class="index%2 ? '':'graue-zeile' "
            >
              <td class="v-data-table__divider">{{ index + 1 }}</td>
              <td class="v-data-table__divider">{{ item.kitaName }}
                <v-icon v-if="item.hatBestellungspause" color="info" dense>
                  mdi-sleep
                </v-icon>
              </td>
              <td class="text-center v-data-table__divider essensanzahl" :class="bestimmeZellenFarbe(item.normaleSpeisen)">{{ item.normaleSpeisen ? item.normaleSpeisen.anzahl : null }} {{ggfBestellaenderungAnzeigen(item.normaleSpeisen)}}</td>
              <td class="text-center v-data-table__divider essensanzahl" :class="bestimmeZellenFarbe(item.vegetarischeSpeisen)">{{ item.vegetarischeSpeisen ? item.vegetarischeSpeisen.anzahl : null }} {{ggfBestellaenderungAnzeigen(item.vegetarischeSpeisen)}}</td>
              <td class="text-center v-data-table__divider essensanzahl" :class="bestimmeZellenFarbe(item.glutenfreieSpeisen)">{{ item.glutenfreieSpeisen ? item.glutenfreieSpeisen.anzahl : null }} {{ggfBestellaenderungAnzeigen(item.glutenfreieSpeisen)}}</td>
              <td class="text-center v-data-table__divider essensanzahl" :class="bestimmeZellenFarbe(item.laktosefreieSpeisen)">{{ item.laktosefreieSpeisen ? item.laktosefreieSpeisen.anzahl : null }} {{ggfBestellaenderungAnzeigen(item.laktosefreieSpeisen)}}</td>
              <td class="v-data-table__divider essensanzahl" >
                <div v-for="(sonstigeSpeise,index) in item.sonstigeAllergene" :key="index" :class="bestimmeZellenFarbe(sonstigeSpeise)">
                  {{ sonstigeSpeise.anzahl }} x {{ sonstigeSpeise.essensarten.join(", ") }} {{ggfBestellaenderungAnzeigen(sonstigeSpeise)}}
                </div>
              </td>
              <td class="text-center v-data-table__divider essensanzahl">{{ item.gesamt }}</td>
              <td class="text-center v-data-table__divider">{{ item.bemerkung }}</td>
              <td class="text-center">
                <v-simple-checkbox color="primary"
                                   class="dessert-checkbox"
                                   v-model="item.mitDessert"
                                   disabled
                ></v-simple-checkbox>
              </td>
            </tr>
          </draggable>
          <tfoot>
          <tr v-if="auswertung && auswertung.summiertePositionen && !$vuetify.breakpoint.xsOnly">
            <th class="v-data-table__divider px-1"></th>
            <th class="text-center v-data-table__divider">Summe</th>
            <th class="text-center v-data-table__divider">{{ getAnzahlNullsafe(auswertung.summiertePositionen.normaleSpeisen) }}</th>
            <th class="text-center v-data-table__divider">{{ getAnzahlNullsafe(auswertung.summiertePositionen.vegetarischeSpeisen) }}</th>
            <th class="text-center v-data-table__divider">{{ getAnzahlNullsafe(auswertung.summiertePositionen.glutenfreieSpeisen) }}</th>
            <th class="text-center v-data-table__divider">{{ getAnzahlNullsafe(auswertung.summiertePositionen.laktosefreieSpeisen) }}</th>
            <th class="v-data-table__divider"></th>
            <th class="text-center v-data-table__divider">{{ auswertung.summiertePositionen.gesamt }}</th>
            <th class="v-data-table__divider"></th>
            <th class="text-center">{{ summierteAnzahlDesserts }}</th>
          </tr>
          </tfoot>
        </template>
      </v-data-table>
    </div>

</template>

<script>
import Vue from 'vue'
import {auswertungservice} from "@/service";
import draggable from 'vuedraggable'
import {mapMutations} from "vuex";

export default Vue.extend({
  name: 'AuswertungEssensbestellungComponent',
  components: {
    draggable
  },
  props: ['datum', 'reihenfolgeAnpassen', 'zeigeBestellungspausen', 'zeigeLeereBestellungen'],
  data: () => ({
    auswertung: null,
    loading: true,
    headers: [
      {
        text: '#',
        align: 'center',
        divider: true,
        width: "1%",
        class: "px-1"
      },
      {
        text: 'Einrichtung',
        align: 'start',
        sortable: true,
        value: 'kitaName',
        width: "15%",
        divider: true,
        class: "px-1"
      },
      {
        text: 'Normal',
        value: 'normaleSpeisen.anzahl',
        width: "4%",
        align: 'center',
        sortable: true,
        divider: true,
        class: "px-1"
      },
      {
        text: 'Vegi',
        value: 'vegetarischeSpeisen.anzahl',
        width: "4%",
        align: 'center',
        sortable: true,
        divider: true,
        class: "px-1"
      },
      {
        text: 'Glutenfrei',
        value: 'glutenfreieSpeisen.anzahl',
        width: "4%",
        sortable: true,
        align: 'center',
        class: "px-1",
        divider: true
      },
      {
        text: 'Laktosefrei',
        value: 'laktosefreieSpeisen.anzahl',
        width: "4%",
        sortable: true,
        align: 'center',
        class: "px-1",
        divider: true
      },
      {
        text: 'Sonstiges',
        value: 'sonstigeAllergene',
        align: 'center',
        sortable: false,
        divider: true,
        width: "15%"
      },
      {
        text: 'Gesamt',
        value: 'gesamt',
        align: 'center',
        width: "1%",
        class: "px-1",
        sortable: true,
        divider: true
      },
      {
        text: 'Bemerkung',
        value: 'bemerkung',
        align: 'center',
        sortable: false,
        divider: true,
        width: "30%"
      },
      {
        text: 'Dessert',
        value: 'mitDessert',
        width: "1%",
        class: "px-1",
        align: "center"
      }
    ],
  }),
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    bestimmeZellenFarbe(item){
      if(item) {
        if (item.anzahl && item.anzahl > item.initialeanzahl) {
          return 'mehressen'
        } else if (item.anzahl && item.anzahl < item.initialeanzahl) {
          return 'wenigeressen'
        } else {
          return ''
        }
      }
    },
    ggfBestellaenderungAnzeigen(item){
      if(item){
        return item.anzahl && item.anzahl !== item.initialeanzahl ? ` (${item.initialeanzahl})` : ''
      }
    },
    updateKitaAuswertungsIndex(event) {
      this.loading=true
      const vorherigeKita = this.auswertung.bestellungen[event.moved.newIndex-1]
      let index
      if(vorherigeKita){
        index=vorherigeKita.auswertungsIndex+1
      }else {
        index=0
      }
      auswertungservice.updateKitaAuswertungsIndex({kitaId: event.moved.element.kitaId, neuerIndex: index})
      .then(() => {
        this.addErfolgsNachricht("Reihenfolge gespeichert")
      })
      .finally(()=>{
        this.loading=false
      })
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printMe').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=1000,height=800,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
              <html lang="de">
                <head>
                  ${stylesHtml}
                  <style>
                   td {
                         text-align: center;
                         vertical-align: middle;
                         font-size: 10px !important;
                         border-color: black !important;
                         height: 12px !important;
                         padding-right: 1px !important;
                         padding-left: 1px !important;
                    }
                    th {
                        border-color: black !important;
                        color: black !important;
                        height: 18px !important;
                          padding-right: 1px !important;
                         padding-left: 1px !important;
                    }
                    .v-data-table__divider {
                        border-color: black !important;
                    }
                    .v-icon.v-icon{
                        font-size: 12px !important;
                    }
                    .v-input--selection-controls__input{
                        height: 10px !important;
                    }
                    .theme--light.v-icon.v-icon.v-icon--disabled{
                        color: #262626 !important;
                    }
                    .graue-zeile{
                         background-color: rgba(0, 0, 0, .20);
                    }
                    .theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon{
                     display: none !important;
                    }
                  </style><title></title>
                </head>
                <body>
                  ${prtHtml}
                </body>
              </html>`);


      setTimeout(function () {
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close()
      }, 300);
    },
    getAnzahlNullsafe(pos) {
      return pos ? pos.anzahl : 0
    }
  },
  computed: {
    summierteAnzahlDesserts() {
      if (this.auswertung && this.auswertung.bestellungen) {
        return this.auswertung.bestellungen
            .filter(b => b.mitDessert)
            .map(b => b.gesamt)
            .reduce((pv, cv) => pv + cv, 0)
      } else {
        return 0
      }
    },
    dragOptions() {
      return {
        animation: 1,
        group: "description",
        ghostClass: "ghost"
      };
    },
    gefilterteBestellungen() {
      if (this.auswertung) {
        return this.auswertung.bestellungen
            .filter(it => {
              if (!this.zeigeBestellungspausen) {
                return !it.hatBestellungspause
              } else {
                return true
              }
            })
            .filter(it => {
              if (!this.zeigeLeereBestellungen) {
                return it.gesamt
              } else {
                return true
              }
            })
      } else {
        return []
      }
    }
  },
  mounted() {
    if (this.datum) {
      this.loading = true
      auswertungservice.ladeAuswertungZuEssensbestellung(this.datum).then(
          response => {
            this.auswertung = response.data
            this.loading = false
          }
      )
    }

  }
})
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: var(--v-primary-base);
}

.anpassbareZeilen {
  cursor: move;
}

td {
  height: 12px !important;
  padding: 0 10px !important;
}

.essensanzahl{
  padding: 0 3px !important;
}

.essensauswertung{
  line-height: 1.2 !important;
}

.mehressen{
  color: green;
  font-weight: bold;
}

.wenigeressen{
  color: red;
  font-weight: bold;
}

</style>