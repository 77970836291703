<template>
  <v-container>
    <v-row class="ma-2">
      <h2>Monatsübersicht</h2>
      <v-spacer></v-spacer>
      <v-btn color="primary" depressed fab small @click="$refs.monatsuebersicht.print()">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-row>
    <monatsuebersicht-filter-component
        @change="monatsuebersichtFilter=$event">
    </monatsuebersicht-filter-component>
    <monatsuebersicht-anzeige-component
        ref="monatsuebersicht"
        :monatsuebersichtfilter="monatsuebersichtFilter"
    >
    </monatsuebersicht-anzeige-component>
  </v-container>
</template>

<script>
import MonatsuebersichtFilterComponent from "@/components/verwaltung/monatsuebersicht/MonatsuebersichtFilterComponent";
import MonatsubersichtFilterVO from "@/model/MonatsubersichtFilterVO";
import MonatsuebersichtAnzeigeComponent from "@/components/verwaltung/monatsuebersicht/MonatsuebersichtAnzeigeComponent";

export default {
  name: "MonatsuebersichtView",
  components: {MonatsuebersichtAnzeigeComponent, MonatsuebersichtFilterComponent},
  data: () => ({
    monatsuebersichtFilter: new MonatsubersichtFilterVO()
  })
}
</script>

<style scoped>

</style>