<template>
  <v-card-actions class="grey lighten-3">
    <v-spacer></v-spacer>
    <v-btn v-if="!$vuetify.breakpoint.mobile" text depressed @click="initBestellung" :disabled="loading">
      Eingaben löschen
    </v-btn>
    <v-btn :block="$vuetify.breakpoint.mobile" color="primary" depressed :disabled="bestellungDarfNochNichtAufgegebenWerden" :loading="loading" @click="bestellen">
      {{ bestellenButtonText }}
    </v-btn>
  </v-card-actions>
</template>

<script>
import Essensbestellung from "@/model/EssensbestellungVO";
import {essensbestellungservice} from "@/service";
import NeueEssensbestellung from "@/model/NeueEssensbestellung";
import {mapMutations} from "vuex";

export default {
  name: "EssensBestellungActionsComponent",
  props: ['value', 'kita', 'bestellungszeitraum'],
  data: () => ({
    bestellung: new Essensbestellung(),
    loading: false
  }),
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    initBestellung() {
      this.$emit('reset')
    },
    bestellen() {
      this.loading = true
      let neueEssesnbestellung = new NeueEssensbestellung(this.bestellung.essensbestellpositionen,
          this.kita,
          this.bestellungszeitraum[0],
          this.bestellungszeitraum[1] ? this.bestellungszeitraum[1] : undefined,
          this.bestellung.bemerkung
      )
      essensbestellungservice.erstelleEssensbestellung(neueEssesnbestellung).then(() => {
        this.$emit('bestellung-gespeichert')
        this.addErfolgsNachricht("Bestellung erfolgreich gespeichert")
      }).catch(error => {
        this.$emit('error', error.response.data)
      }).finally(() =>
          this.loading = false
      )
    }
  },
  computed: {
    bestellungDarfNochNichtAufgegebenWerden() {
      return this.bestellung.essensbestellpositionen.some(position => position.essensarten.length === 0|| position.anzahl===undefined ) || this.summe<=0
    },
    bestellenButtonText() {
      return this.bestellung.id ? 'Bestellung speichern' : 'Bestellung abschicken'
    },
    summe() {
      return this.bestellung.essensbestellpositionen
          .map(position => Number(position.anzahl||0))
          .reduce((sum, current) => sum + current, 0)
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(neueBestellung) {
        this.bestellung = neueBestellung
      }
    }
  }
}
</script>

<style scoped>

</style>