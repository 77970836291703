<template>
  <div>
    <v-container fill-height class="fill-height">
      <v-row justify="center" align="center">
        <v-col cols="12" class="text-center">
          <h4>
            Sie werden angemeldet. Bitte warten...
          </h4>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions([
      'oidcSignInCallback',
    ])
  },
  mounted () {

    this.oidcSignInCallback()
        .then((redirectPath) => {
          this.$router.push(redirectPath)
        })
        .catch((err) => {
          console.error(err)
        })
  }
}
</script>

<style scoped>

</style>