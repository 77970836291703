
import Vue from "vue";
import {mapState} from "vuex";
import {NachrichtVO} from "@/components/nachrichten/NachrichtVO";

export default Vue.extend({
  name: 'MachrichtComponent',
  data: () => ({
    snackbar: false,
    aktuelleNachricht: NachrichtVO
  }),
  computed: {
      ...mapState([
          "nachricht"
      ])
  },
  watch: {
    nachricht(neueNachricht){
      this.aktuelleNachricht = neueNachricht
      this.snackbar=true
    }
  }
})
