<template>
  <div v-if="hatRolleKita || hatRolleMitarbeiter">
    <v-tooltip
        top
        :disabled="!disableLoeschen"
        color="primary"
    >
      <template v-slot:activator="{ on }">
        <div v-on="on">
          <v-icon
              color="error"
              :disabled="disableLoeschen"
              @click.stop="oeffneLoeschDialog()"
          >
            mdi-trash-can-outline
          </v-icon>
        </div>
      </template>
      <span>Bestellungspausen können nur gelöscht werden, wenn sie nicht in der Vergangenheit liegen</span>
    </v-tooltip>
    <v-dialog
        v-model="loeschDialog"
        :retain-focus="false"
        persistent
        max-width="450"
    >
      <v-card>
        <v-card-title class="text-h5">
          Soll die Bestellungspause wirklich gelöscht werden?
        </v-card-title>
        <v-card-actions>

          <v-spacer/>
          <v-btn
              text
              @click.stop="loeschDialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="error"
              depressed
              @click.stop="loescheBestellungspause()"
          >
            Löschen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {bestellungspauseservice} from "@/service";

export default {
  name: "BestellungspauseLoeschenComponent",
  props: ['item'],
  data() {
    return {
      loeschDialog: false,
    }
  },
  computed: {
    ...mapGetters(['hatRolleMitarbeiter', 'hatRolleKita']),
    disableLoeschen() {
      const heute = new Date()
      let referenzDatum = new Date(heute)
      return this.item.datum < referenzDatum.toISOString().substr(0, 10)
    },
  },
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    oeffneLoeschDialog() {
      this.loeschDialog = true
    },
    loescheBestellungspause() {
      bestellungspauseservice.loescheBestellungspausen(this.item.id)
          .then(() => {
            this.loeschDialog = false
            this.addErfolgsNachricht('Bestellungspause erfolgreich gelöscht')
            this.$emit('pauseGeloescht')
          })
          .catch((error) => {
            this.loeschDialog = false
            console.log(error)
          })
    },
  }
}
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>