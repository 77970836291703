
<script>
export default {
  name: "JahrMonatMixin",
  computed: {
    monate() {
      const monate = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
      return monate.map((monat, index) => {
        return {
          name: monat,
          nummer: (index + 1).toString()
        }
      })
    },
    monateMitNummern() {
      const monate = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
      return monate.map((monat, index) => {
        return {
          name: monat,
          nummer: (index + 1)
        }
      })
    },
    jahre() {
      const diesesJahr = new Date().toISOString().substr(0, 4)
      const naechstesJahr = (parseInt(diesesJahr) + 1).toString()
      return [diesesJahr, naechstesJahr]
    },

    jahreAb2021() {
      const startJahr = 2021
      const diesesJahr = new Date().getFullYear()
      const naechstesJahr = (parseInt(diesesJahr) + 1).toString()
      const jahre = []
      for (var i = startJahr; i <= naechstesJahr; i++) {
        jahre.push(i)
      }
      return jahre;
    }
  },
}
</script>
