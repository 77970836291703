/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BestellvorlageVO } from '../model';
/**
 * BestellvorlageApi - axios parameter creator
 * @export
 */
export const BestellvorlageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Speichere eine Bestellvorlage zu einer Kita
         * @param {BestellvorlageVO} bestellvorlageVO Bestellvorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erstelleBestellvorlage: async (bestellvorlageVO: BestellvorlageVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bestellvorlageVO' is not null or undefined
            assertParamExists('erstelleBestellvorlage', 'bestellvorlageVO', bestellvorlageVO)
            const localVarPath = `/bestellvorlage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bestellvorlageVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lädt die Bestellvorlage einer Kita
         * @param {string} kitaId Die ID der Kita für die die Vorlage geladen werden soll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeBestellvorlage: async (kitaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitaId' is not null or undefined
            assertParamExists('ladeBestellvorlage', 'kitaId', kitaId)
            const localVarPath = `/bestellvorlage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kitaId !== undefined) {
                localVarQueryParameter['kitaId'] = kitaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loescht eine Bestellvorlage
         * @param {string} kitaId Id der Kita deren Bestellvorlage geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loescheBestellvorlage: async (kitaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitaId' is not null or undefined
            assertParamExists('loescheBestellvorlage', 'kitaId', kitaId)
            const localVarPath = `/bestellvorlage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kitaId !== undefined) {
                localVarQueryParameter['kitaId'] = kitaId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BestellvorlageApi - functional programming interface
 * @export
 */
export const BestellvorlageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BestellvorlageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Speichere eine Bestellvorlage zu einer Kita
         * @param {BestellvorlageVO} bestellvorlageVO Bestellvorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erstelleBestellvorlage(bestellvorlageVO: BestellvorlageVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erstelleBestellvorlage(bestellvorlageVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lädt die Bestellvorlage einer Kita
         * @param {string} kitaId Die ID der Kita für die die Vorlage geladen werden soll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeBestellvorlage(kitaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BestellvorlageVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeBestellvorlage(kitaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary loescht eine Bestellvorlage
         * @param {string} kitaId Id der Kita deren Bestellvorlage geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loescheBestellvorlage(kitaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loescheBestellvorlage(kitaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BestellvorlageApi - factory interface
 * @export
 */
export const BestellvorlageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BestellvorlageApiFp(configuration)
    return {
        /**
         * 
         * @summary Speichere eine Bestellvorlage zu einer Kita
         * @param {BestellvorlageVO} bestellvorlageVO Bestellvorlage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erstelleBestellvorlage(bestellvorlageVO: BestellvorlageVO, options?: any): AxiosPromise<void> {
            return localVarFp.erstelleBestellvorlage(bestellvorlageVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lädt die Bestellvorlage einer Kita
         * @param {string} kitaId Die ID der Kita für die die Vorlage geladen werden soll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeBestellvorlage(kitaId: string, options?: any): AxiosPromise<BestellvorlageVO> {
            return localVarFp.ladeBestellvorlage(kitaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary loescht eine Bestellvorlage
         * @param {string} kitaId Id der Kita deren Bestellvorlage geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loescheBestellvorlage(kitaId: string, options?: any): AxiosPromise<void> {
            return localVarFp.loescheBestellvorlage(kitaId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BestellvorlageApi - object-oriented interface
 * @export
 * @class BestellvorlageApi
 * @extends {BaseAPI}
 */
export class BestellvorlageApi extends BaseAPI {
    /**
     * 
     * @summary Speichere eine Bestellvorlage zu einer Kita
     * @param {BestellvorlageVO} bestellvorlageVO Bestellvorlage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestellvorlageApi
     */
    public erstelleBestellvorlage(bestellvorlageVO: BestellvorlageVO, options?: any) {
        return BestellvorlageApiFp(this.configuration).erstelleBestellvorlage(bestellvorlageVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lädt die Bestellvorlage einer Kita
     * @param {string} kitaId Die ID der Kita für die die Vorlage geladen werden soll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestellvorlageApi
     */
    public ladeBestellvorlage(kitaId: string, options?: any) {
        return BestellvorlageApiFp(this.configuration).ladeBestellvorlage(kitaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary loescht eine Bestellvorlage
     * @param {string} kitaId Id der Kita deren Bestellvorlage geloescht werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestellvorlageApi
     */
    public loescheBestellvorlage(kitaId: string, options?: any) {
        return BestellvorlageApiFp(this.configuration).loescheBestellvorlage(kitaId, options).then((request) => request(this.axios, this.basePath));
    }
}
