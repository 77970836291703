<template>
  <v-row
      justify="center"
      align="center"
      dense
  >
    <v-col cols="1" class="hidden-xs-only">
      #{{ index + 1 }}
    </v-col>
    <v-col
        cols="8"
        sm="8"
    >
      <v-text-field outlined readonly dense hide-details
                    v-if="standardEssen"
                    v-model="bestellposition.essensarten[0]"
      >
      </v-text-field>
      <v-autocomplete
          v-else
          no-data-text="Keine Ergebnisse gefunden"
          hide-selected
          full-width
          clearable
          :error="!bestellposition.essensarten || bestellposition.essensarten.length===0"
          multiple
          outlined
          :items="bestimmeEssensArtenListe"
          v-model="bestellposition.essensarten"
          hide-details
          label="Essensart"
          dense
          :search-input.sync="searchInput"
          @blur="searchInput=''"
          :menu-props="{ bottom: true, offsetY: true }"
          @input="$emit('input',bestellposition)"
      ></v-autocomplete>
    </v-col>
    <v-col
        cols="3"
        sm="2"
    >
      <v-text-field class="text-center "
                    :error="bestellposition.anzahl <0 || bestellposition.anzahl===undefined"
                    v-model="bestellposition.anzahl"
                    type="number"
                    max="100"
                    outlined
                    hide-details
                    label="Anzahl"
                    dense
                    @input="$emit('input',bestellposition)"
                    @blur="normalisiereAnzahl"
      />
    </v-col>
    <v-col cols="1">
      <v-btn small icon color="error" v-if="!standardEssen" @click="$emit('delete')">
        <v-icon>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: "EssensBestellpositionComponent",
  props: {
    index: Number,
    position: {},
    standardEssen: Boolean
  },
  data: () => ({
    bestellposition: {},
    searchInput: ''
  }),
  methods:{
    normalisiereAnzahl(){
      if (this.bestellposition.anzahl < 0) {
        this.bestellposition.anzahl = null
      } else {
        this.bestellposition.anzahl = Math.round(this.bestellposition.anzahl)
      }
    }
  },
  computed: {
    ...mapState(["essensarten"]),
    bestimmeEssensArtenListe() {
      let standards = this.essensarten.filter(it => !it.art.includes('Normale Speisen')).map(it => it.art).sort()
      this.bestellposition.essensarten.filter(it => !standards.includes(it)).forEach(it => standards.push(it))
      return standards
    }
  },
  watch: {
    position: {
      immediate: true,
      handler(neueBestellposition) {
        this.bestellposition = neueBestellposition
      }
    }
  }
}
</script>

<style scoped>

</style>