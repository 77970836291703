<template>
  <v-text-field
      v-model="kundennummer"
      :maxlength="30"
      :value="kundennummer"
      counter="30"
      dense
      label="Kundennummer"
      outlined
      @input="handleInput"
  />
</template>

<script>
export default {
  name: "KitaKundennummerComponent",
  props: ['value'],
  data() {
    return {
      kundennummer: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.kundennummer = newValue
      }
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.kundennummer)
    },
  }
}
</script>

<style scoped>

</style>