<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          depressed
          v-bind="attrs"
          @click="fehler=null"
          v-on="on"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Neue Essensart anlegen
      </v-btn>
    </template>
    <v-card rounded="lg">
      <v-alert
          v-if="fehler"
          class="ma-0"
          color="error"
          type="error"
      >{{ fehler }}
      </v-alert>
      <v-card-title>
        <span class="headline">Neue Essensart anlegen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col
                cols="12"
            >
              <v-text-field
                  v-model="neueEssensart"
                  :maxlength="100"
                  counter="100"
                  dense
                  label="Name"
                  outlined
                  @keydown.enter="erstelleEssensart"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            @click="abbrechen "

        >
          Abbrechen
        </v-btn>
        <v-btn
            :disabled="!neueEssensart"
            :loading="loading"
            color="primary"
            depressed
            @click="erstelleEssensart"

        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {essensartenservice} from "@/service";
import {mapMutations} from "vuex";

export default {
  name: "NeueEssensartAnlegen",
  data() {
    return {
      dialog: false,
      fehler: null,
      loading: false,
      neueEssensart: null,
    }
  },
  methods: {
    ...mapMutations(["addErfolgsNachricht"]),
    erstelleEssensart() {
      this.loading = true
      essensartenservice
          .createEssensart({art: this.neueEssensart})
          .then(() => {
            this.$emit("created")
            this.addErfolgsNachricht("Die Essensart " + this.neueEssensart + " wurde erstellt.")
            this.dialog = false
            this.neueEssensart = null
          })
          .catch((error) => {
            this.fehler = error.response.data.message
          })
          .finally(() => {
            this.loading = false
          })
    },
    abbrechen() {
      this.neueEssensart = null;
      this.dialog = false;
    }
  }
}
</script>

<style scoped>

</style>