<template>
  <div class="home">
    <start-component />
  </div>
</template>

<script>
import StartComponent from '@/components/start/StartComponent.vue'

export default {
  name: 'Home',
  components: {
    StartComponent
  }
}
</script>
