<template>
  <div class="d-flex">
    <h2>Bestellvorlage</h2>
    <v-btn
        icon
        small @click.stop="dialog = true"
    >
      <v-icon color="primary">
        mdi-information
      </v-icon>
    </v-btn>

    <v-dialog
        v-model="dialog"
        max-width="380"
    >
      <v-card>
        <v-card-title class="text-h5">
          Hinweis zu Bestellvorlagen
        </v-card-title>

        <v-card-text>
          Die Bestellvorlage dient dazu automatisch Bestellungen anzulegen sofern diese noch nicht getätigt wurden.
          Wenn sie für einen Tag keine Bestellung abgeben möchten, nutzen Sie bitte die
          <router-link to="bestellungpausieren">Bestellungspausen</router-link>
          .
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "BestellvorlageInfoComponent",
  data: () => ({
    dialog: false
  })
}
</script>

<style scoped>

</style>