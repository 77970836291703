/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EssensartVO } from '../model';
// @ts-ignore
import { EssensartVerwendungenVO } from '../model';
/**
 * EssensartenApi - axios parameter creator
 * @export
 */
export const EssensartenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Erstelle eine neue Essensart
         * @param {EssensartVO} essensartVO Neue Essensart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEssensart: async (essensartVO: EssensartVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'essensartVO' is not null or undefined
            assertParamExists('createEssensart', 'essensartVO', essensartVO)
            const localVarPath = `/essensarten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(essensartVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loesche eine Essensart
         * @param {string} art 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEssensart: async (art: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'art' is not null or undefined
            assertParamExists('deleteEssensart', 'art', art)
            const localVarPath = `/essensarten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary laedt Bestellungen und Bestellvorlagen, an denen die Essensart verwendet wird
         * @param {string} art 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeEssensartVerwendungen: async (art: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'art' is not null or undefined
            assertParamExists('ladeEssensartVerwendungen', 'art', art)
            const localVarPath = `/essensarten/verwendung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (art !== undefined) {
                localVarQueryParameter['art'] = art;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lädt alle Essensarten
         * @param {'aktiv' | 'inaktiv'} [status] Bestimme den Status der Essensarten die geladen werden sollen. Wird kein Status mitgegeben werden alle Essensarten geladen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeEssensarten: async (status?: 'aktiv' | 'inaktiv', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/essensarten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EssensartenApi - functional programming interface
 * @export
 */
export const EssensartenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EssensartenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Erstelle eine neue Essensart
         * @param {EssensartVO} essensartVO Neue Essensart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEssensart(essensartVO: EssensartVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EssensartVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEssensart(essensartVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary loesche eine Essensart
         * @param {string} art 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEssensart(art: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEssensart(art, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary laedt Bestellungen und Bestellvorlagen, an denen die Essensart verwendet wird
         * @param {string} art 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeEssensartVerwendungen(art: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EssensartVerwendungenVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeEssensartVerwendungen(art, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lädt alle Essensarten
         * @param {'aktiv' | 'inaktiv'} [status] Bestimme den Status der Essensarten die geladen werden sollen. Wird kein Status mitgegeben werden alle Essensarten geladen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeEssensarten(status?: 'aktiv' | 'inaktiv', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EssensartVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeEssensarten(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EssensartenApi - factory interface
 * @export
 */
export const EssensartenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EssensartenApiFp(configuration)
    return {
        /**
         * 
         * @summary Erstelle eine neue Essensart
         * @param {EssensartVO} essensartVO Neue Essensart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEssensart(essensartVO: EssensartVO, options?: any): AxiosPromise<EssensartVO> {
            return localVarFp.createEssensart(essensartVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary loesche eine Essensart
         * @param {string} art 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEssensart(art: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEssensart(art, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary laedt Bestellungen und Bestellvorlagen, an denen die Essensart verwendet wird
         * @param {string} art 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeEssensartVerwendungen(art: string, options?: any): AxiosPromise<EssensartVerwendungenVO> {
            return localVarFp.ladeEssensartVerwendungen(art, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lädt alle Essensarten
         * @param {'aktiv' | 'inaktiv'} [status] Bestimme den Status der Essensarten die geladen werden sollen. Wird kein Status mitgegeben werden alle Essensarten geladen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeEssensarten(status?: 'aktiv' | 'inaktiv', options?: any): AxiosPromise<Array<EssensartVO>> {
            return localVarFp.ladeEssensarten(status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EssensartenApi - object-oriented interface
 * @export
 * @class EssensartenApi
 * @extends {BaseAPI}
 */
export class EssensartenApi extends BaseAPI {
    /**
     * 
     * @summary Erstelle eine neue Essensart
     * @param {EssensartVO} essensartVO Neue Essensart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensartenApi
     */
    public createEssensart(essensartVO: EssensartVO, options?: any) {
        return EssensartenApiFp(this.configuration).createEssensart(essensartVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary loesche eine Essensart
     * @param {string} art 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensartenApi
     */
    public deleteEssensart(art: string, options?: any) {
        return EssensartenApiFp(this.configuration).deleteEssensart(art, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary laedt Bestellungen und Bestellvorlagen, an denen die Essensart verwendet wird
     * @param {string} art 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensartenApi
     */
    public ladeEssensartVerwendungen(art: string, options?: any) {
        return EssensartenApiFp(this.configuration).ladeEssensartVerwendungen(art, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lädt alle Essensarten
     * @param {'aktiv' | 'inaktiv'} [status] Bestimme den Status der Essensarten die geladen werden sollen. Wird kein Status mitgegeben werden alle Essensarten geladen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensartenApi
     */
    public ladeEssensarten(status?: 'aktiv' | 'inaktiv', options?: any) {
        return EssensartenApiFp(this.configuration).ladeEssensarten(status, options).then((request) => request(this.axios, this.basePath));
    }
}
