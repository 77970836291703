<template>
  <v-row>
    <v-col cols="12">
      <div id="printMe">
        <h2 class="hideForPrint">
          {{ getAusgewaehltenKitanamen }}
        </h2>
        <v-data-table
            :headers="headers"
            :items="monatsubersicht.bestellungen"
            :loading="loading"
            dense
            disable-pagination
            disable-sort
            hide-default-footer
            loading-text="Bitte warten"
            no-data-text="Für den gewählten Zeitraum liegen keine Bestellungen vor"
        >
          <template #item.sno="{ index }">
            {{ index + 1 }}
          </template>
          <template #item.datum="{ item }">
            {{ formatiereDatum(item.datum) }}
          </template>
          <template v-slot:item.sonstigeAllergene="{ item }">
            <div v-for="(sonstigeAllergene,index) in item.sonstigeAllergene" :key="index">
              {{ sonstigeAllergene.anzahl }} x {{ sonstigeAllergene.essensarten.join(", ") }}
            </div>
          </template>
          <template v-slot:item.mitDessert="{ item }">
            <v-simple-checkbox v-model="item.mitDessert"
                               class="dessert-checkbox "
                               color="primary"
                               disabled
            ></v-simple-checkbox>
          </template>
          <template slot="body.append">
            <tr v-if="monatsubersicht && monatsubersicht.bestellungen && !$vuetify.breakpoint.xsOnly">
              <th class="v-data-table__divider px-1"></th>
              <th class="text-center v-data-table__divider">Summe</th>
              <th class="text-center v-data-table__divider">{{ getAnzahlNullsafe(monatsubersicht.summiertePositionen.normaleSpeisen) }}</th>
              <th class="text-center v-data-table__divider">{{ getAnzahlNullsafe(monatsubersicht.summiertePositionen.vegetarischeSpeisen) }}</th>
              <th class="text-center v-data-table__divider">{{ getAnzahlNullsafe(monatsubersicht.summiertePositionen.glutenfreieSpeisen) }}</th>
              <th class="text-center v-data-table__divider">{{ getAnzahlNullsafe(monatsubersicht.summiertePositionen.laktosefreieSpeisen) }}</th>
              <th class="v-data-table__divider"></th>
              <th class="text-center v-data-table__divider">{{ monatsubersicht.summiertePositionen.gesamt }}</th>
              <th class="v-data-table__divider"></th>
              <th class="text-center">{{ summierteAnzahlDesserts }}</th>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-col>
  </v-row>

</template>

<script>
import MonatsubersichtFilterVO from "@/model/MonatsubersichtFilterVO";
import {abrechnungservice} from "@/service";
import {mapGetters} from "vuex";

export default {
  name: "MonatsuebersichtAnzeigeComponent",
  props: {
    monatsuebersichtfilter: MonatsubersichtFilterVO
  },
  data: () => ({
    loading: false,
    monatsubersicht: [],
    headers: [
      {
        text: '#',
        align: 'center',
        value: 'sno',
        divider: true,
        width: "1px",
        class: "px-1"
      },
      {
        text: 'Datum',
        align: 'start',
        sortable: true,
        value: 'datum',
        divider: true,
      },
      {
        text: 'Normal',
        value: 'normaleSpeisen.anzahl',
        align: 'center',
        divider: true
      },
      {
        text: 'Vegi',
        value: 'vegetarischeSpeisen.anzahl',
        align: 'center',
        divider: true
      },
      {
        text: 'Glutenfrei',
        value: 'glutenfreieSpeisen.anzahl',
        align: 'center',
        divider: true
      },
      {
        text: 'Laktosefrei',
        value: 'laktosefreieSpeisen.anzahl',
        align: 'center',
        divider: true
      },
      {
        text: 'Sonstiges',
        value: 'sonstigeAllergene',
        align: 'center',
        sortable: false,
        divider: true,
        width: "20%"
      },
      {
        text: 'Gesamt',
        value: 'gesamt',
        align: 'center',
        sortable: false,
        divider: true
      },
      {
        text: 'Bemerkung',
        value: 'bemerkung',
        align: 'center',
        sortable: false,
        divider: true
      },
      {
        text: 'Dessert',
        value: 'mitDessert',
        align: "center"
      }
    ],
  }),
  computed: {
    ...mapGetters(['getKitas', 'getKitaVonBenutzer', 'hatRolleKita']),
    summierteAnzahlDesserts() {
      if (this.monatsubersicht && this.monatsubersicht.bestellungen) {
        return this.monatsubersicht.bestellungen.filter(b => b.mitDessert).length
      } else {
        return 0
      }
    },
    getAusgewaehltenKitanamen() {
      const kitaid = this.monatsuebersichtfilter.kitaFilter
      if (kitaid) {
        if (this.hatRolleKita) {
          return this.getKitaVonBenutzer.name
        } else {
          console.log(this.getKitas.find(k => k.id === kitaid).name)
          return this.getKitas.find(k => k.id === kitaid).name
        }
      }
      return null
    }
  },
  methods: {
    refreshMonatsuebersicht() {
      this.loading = true
      this.monatsubersicht = {}
      abrechnungservice.ladeAuswertungMonatsUebersicht(
          this.monatsuebersichtfilter.kitaFilter,
          this.monatsuebersichtfilter.monatFilter,
          this.monatsuebersichtfilter.jahrFilter)
          .then(
              response => {
                this.monatsubersicht = response.data
              }
          )
          .finally(
              () => {
                this.loading = false
              }
          )
    },
    formatiereDatum(datum) {
      const [year, month, day] = datum.split('-')
      return `${day}.${month}.${year}`
    },
    getAnzahlNullsafe(pos) {
      return pos ? pos.anzahl : 0
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printMe').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=1000,height=800,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
              <html lang="de">
                <head>
                  ${stylesHtml}
                  <style>
                   td {
                         text-align: center;
                         vertical-align: middle;
                         font-size: 10px !important;
                         border-color: black !important;
                         height: 12px !important;
                           padding-left: 5px !important;
                        padding-right: 5px !important;
                    }
                    th {
                        border-color: black !important;
                        color: black !important;
                        height: 18px !important;
                          padding-left: 5px !important;
                        padding-right: 5px !important;

                    }
                    .v-data-table__divider {
                        border-color: black !important;
                    }
                    .v-icon.v-icon{
                        font-size: 12px !important;
                    }
                    .v-input--selection-controls__input{
                        height: 10px !important;
                    }
                    .theme--light.v-icon.v-icon.v-icon--disabled{
                        color: #262626 !important;
                    }
                    .graue-zeile{
                         background-color: rgba(0, 0, 0, .20);
                    }
                    .hideForPrint{
                      display: block !important;
                    }
                  </style><title></title>
                </head>
                <body>
                  ${prtHtml}
                </body>
              </html>`);


      setTimeout(function () {
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
      }, 300);
    },
  },

  watch: {
    monatsuebersichtfilter: {
      immediate: true,
      deep: true,
      handler(neuerFilter) {
        if (neuerFilter.kitaFilter) {
          this.refreshMonatsuebersicht()
        }
      }
    }
  }
}
</script>

<style scoped>
.hideForPrint {
  display: none;
}
</style>