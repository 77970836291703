<template>
  <v-select v-model="ausgewaehlterErsteller"
            :items="benutzer"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="name"
            label="Nach Ersteller filtern"
            outlined
  >

  </v-select>
</template>

<script>
import {benutzerservice} from "@/service";

export default {
  props: ['value'],
  name: "HistorienEintragErstellerFilterComponent",
  data() {
    return {
      ausgewaehlterErsteller: this.value,
      benutzer: []
    }
  },
  watch: {
    ausgewaehlterErsteller(neuerWert) {
      this.$emit('input', neuerWert)
    },
  },
  created() {
    benutzerservice.getBenutzer().then(response => {
      this.benutzer = response.data
      this.benutzer.push({name: "system"})
      this.benutzer = this.benutzer.sort((a, b) => a.name.localeCompare(b.name))
    })
  }

}
</script>

<style scoped>

</style>