import {BestellvorlageVO, EssensbestellpositionVO} from "@/generated";

export default class Bestellvorlage implements BestellvorlageVO {
    essensbestellpositionen: Array<EssensbestellpositionVO>;
    kitaId: string;

    constructor() {
        this.essensbestellpositionen = Array<EssensbestellpositionVO>();
        this.kitaId = "";
        this.essensbestellpositionen.push({essensarten: ['Normale Speisen'], anzahl: undefined})
    }

}
