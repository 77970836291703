<template>
  <div>
    <v-tooltip
      top
      max-width="180"
      class="custom-tooltip"
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >Liefertage*</span>
        <br>
      </template>
      <p>Bestimmt die Wochentage, an denen Kitas normalerweise beliefert werden.</p>
    </v-tooltip>
    <v-btn-toggle
      v-model="liefertageindex"
      multiple
      color="primary"
      r
      mandatory
      @change="gebeLiefertageZurueck"
    >
      <v-btn
        v-for="name in liefertageNamen"
        :key="name"
        :class="$vuetify.breakpoint.mobile ? 'px-0' :''"
        :min-width="$vuetify.breakpoint.mobile ? '35' :''"
      >
        {{ name }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "LiefertagAuswahlComponent",
  props: ['kitaliefertage'],
  data: () => ({
    liefertageindex: [],
    liefertageNamen: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]
  }),
  created() {
    this.kitaliefertage.forEach(it => {
          this.liefertageindex.push(it - 1)
        }
    )
  },
  methods: {
    gebeLiefertageZurueck() {
      this.$emit("change", this.liefertageindex.map(it => it + 1))
    }
  }
})
</script>

<style scoped lang="scss">
.v-tooltip__content.menuable__content__active {
  opacity: 1!important;
}
</style>