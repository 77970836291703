<template>
  <v-dialog
      :key="bestellungspauseerstellenkey"
      v-model="dialog"
      persistent
      max-width="600px"
      v-if="hatRolleMitarbeiter || hatRolleKita"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          depressed
          block
          v-bind="attrs"
          v-on="on"
          @click="fehler=null"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Neue Bestellungspause
      </v-btn>
    </template>
    <v-card rounded="lg">
      <v-alert
          v-if="fehler"
          class="ma-0"
          color="error"
          type="error"
      >{{ fehler }}
      </v-alert>
      <v-card-title>
        <span class="headline">Neue Bestellungspause anlegen</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <kita-auswahl-component
                v-model="NeueBestellungspauseVO.kitaid">
            </kita-auswahl-component>
          </v-row>
          <v-row v-if="NeueBestellungspauseVO.kitaid" justify="center">
            <v-col cols="12" sm="6" class="pa-1">
              <datum-auswahl-component
                  v-model="NeueBestellungspauseVO.von"
                  :verschiebung="0"
                  label="Von"
                  :events="vorhandeneBestellungspausen"
                  @dateChanged="aktualisiereVorhandeneBestellungspausen($event)"
              />
            </v-col>
            <v-col cols="12" sm="6" class="pa-1">
              <datum-auswahl-component
                  v-model="NeueBestellungspauseVO.bis"
                  :verschiebung="0"
                  label="Bis"
                  :events="vorhandeneBestellungspausen"
                  @dateChanged="aktualisiereVorhandeneBestellungspausen($event)"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            @click="leereKomponente"
        >
          Abbrechen
        </v-btn>
        <v-btn
            color="primary"
            :loading="loading"
            depressed
            :disabled="validiereDaten()"
            @click="erstelleBestellungspause"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapGetters, mapMutations} from "vuex"
import DatumAuswahlComponent from "@/components/tools/DatumAuswahlComponent.vue"
import NeueBestellungspause from "@/model/NeueBestellungspause"
import KitaAuswahlComponent from "@/components/kita/KitaAuswahlComponent";
import {bestellungspauseservice} from "@/service";

export default {
  name: "BestellungspauseErstellenComponent",
  components: {KitaAuswahlComponent, DatumAuswahlComponent},
  data() {
    return {
      aktuellerMonat: '',
      aktuellesJahr: '',
      bestellungspauseerstellenkey: 0,
      dialog: false,
      loading: false,
      fehler: null,
      menue: false,
      NeueBestellungspauseVO: new NeueBestellungspause(),
      vorhandeneBestellungspausen: []
    }
  },
  watch: {
    NeueBestellungspauseVO: {
      handler() {
        if (this.NeueBestellungspauseVO.kitaid && this.aktuellesJahr) {
          this.ladeVorhandeneBestellungspausen()
        }

        if (this.NeueBestellungspauseVO.bis < this.NeueBestellungspauseVO.von) {
          this.NeueBestellungspauseVO.bis = this.NeueBestellungspauseVO.von
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['hatRolleMitarbeiter', 'hatRolleKita'])
  },
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    erstelleBestellungspause() {
      this.loading = true
      this.fehler = null
      bestellungspauseservice.erstelleBestellungspause(this.NeueBestellungspauseVO).then(
          response => {
            this.addErfolgsNachricht("Neue Bestellungspause angelegt")
            this.$emit('neueBestellungspause', response.data)
            this.leereKomponente()
          }
      ).catch(
          (error) => {
            this.fehler = error.response.data.message
          }
      ).finally(
          () => (
              this.loading = false
          )
      )
    },
    ladeVorhandeneBestellungspausen() {
      bestellungspauseservice.ladeBestellungspausen(
        this.aktuellesJahr,
        this.NeueBestellungspauseVO.kitaid,
        this.aktuellerMonat)
          .then((response) => {
            const ergebnis = response.data
            this.vorhandeneBestellungspausen = ergebnis.map((bestellungspause) => {
              return bestellungspause.datum
            })
          }).catch((error) => {
        console.log(error.response)
      })
    },
    leereKomponente() {
      this.dialog = false
      this.NeueBestellungspauseVO = new NeueBestellungspause()
      this.bestellungspauseerstellenkey += 1
      this.vorhandeneBestellungspausen = []
    },
    aktualisiereVorhandeneBestellungspausen(datum) {
      if (!this.NeueBestellungspauseVO.kitaid) {
        return
      }
      this.aktuellesJahr = datum.substr(0, 4)
      this.aktuellerMonat = datum.substr(6, 2)
      this.ladeVorhandeneBestellungspausen()
    },
    validiereDaten() {
      return !this.NeueBestellungspauseVO.kitaid || this.NeueBestellungspauseVO.bis < this.NeueBestellungspauseVO.von
    }
  }
}
</script>

<style scoped>

</style>