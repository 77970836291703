<template>
  <v-container>
    <v-row class="px-3">
      <v-col
          sm="7"
          cols="12"
      >
        <h2>Einrichtungen verwalten</h2>
      </v-col>
      <v-col
          sm="5"
          cols="12"
          class="text-sm-right"
          align-self="center"
      >
        <kita-erstellen-component @neueKita="getDataFromApi"/>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col
          cols="12"
          sm="8"
          md="9"
          lg="10"
      >
        <v-text-field
            outlined
            dense
            v-model="suche"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
        />
      </v-col>
      <v-col
          cols="12"
          sm="4"
          md="3"
          lg="2"
      >
        <v-select
            outlined
            dense
            v-model="statusfilter"
            label="Statusfilter"
            :items="statusValues"
            clearable
            hide-details
            @change="getDataFromApi"
        />
      </v-col>
    </v-row>
    <v-data-table
        class="px-3"
        loading-text="Kitas werden geladen"
        no-data-text="Keine Kitas gefunden"
        :headers="headers"
        :search="suche"
        :items="kitas"
        :options.sync="options"
        :server-items-length="anzahlKitas"
        :loading="loading"

        dense
        :header-props="{
        'sort-by-text':'Sortieren nach'
      }"
        :footer-props="{
        'items-per-page-text':'Elemente pro Seite',
        'items-per-page-all-text':'Alle',
      }"
    >
      <template #[`footer.page-text`]="props">
        {{ props.pageStart }}-{{ props.pageStop }} von {{ props.itemsLength }}
      </template>
      <template #[`item.actions`]="{ item }">
        <kita-bearbeiten-component
            :kita="item"
            @kitaGespeichert="updateKita"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import KitaErstellenComponent from "@/components/verwaltung/kitaverwaltung/KitaErstellenComponent";
import {KitaVOStatusEnum} from "@/generated";
import KitaBearbeitenComponent from "@/components/verwaltung/kitaverwaltung/KitaBearbeitenComponent";
import Vue from "vue";
import {kitaservice, verwaltungservice} from "@/service";

export default {
  name: 'KitaListeComponent',
  components: {KitaBearbeitenComponent, KitaErstellenComponent},
  data() {
    return {
      anzahlKitas: 0,
      statusValues: Object.values(KitaVOStatusEnum),
      statusfilter: undefined,
      kitas: [],
      loading: true,
      options: {},
      suche: '',
      warteAufSuche: false,
      headers: [
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
          width: '50px'
        },
        {
          text: 'Einrichtung',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {text: 'Status', value: 'status'},

      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    suche() {
      this.loading = true
      if (!this.warteAufSuche) {
        setTimeout(() => {
          this.getDataFromApi()
          this.warteAufSuche = false
        }, 500)
        this.warteAufSuche = true
      }
    }
  },
  mounted() {
    this.getDataFromApi()
    this.refreshBenutzer()
  },
  methods: {
    updateKita(value) {
      Vue.set(this.kitas, this.kitas.findIndex(f => f.id === value.id), value)
    },
    refreshBenutzer(){
      verwaltungservice.refreshBenutzerverwaltung()
    },
    getDataFromApi() {
      this.loading = true
      const {page, itemsPerPage, sortBy, sortDesc} = this.options
      kitaservice.kitasSuchen(itemsPerPage, page - 1,
          this.statusfilter ? this.statusfilter : undefined,
          this.suche ? this.suche : undefined,
          sortBy.length > 0 ? sortBy[0] : undefined,
          sortDesc.length > 0 && sortDesc[0] ? 'desc' : 'asc')
          .then(response => {
            const kitasuchergebnis = response.data
            this.kitas = kitasuchergebnis.kitas
            this.anzahlKitas = kitasuchergebnis.anzahlkitas
            this.loading = false
          }).catch(error => {
        console.log(error.response)
      }).finally(

      )
    }
  },
}
</script>

<style scoped>

</style>