<template>
  <div v-if="hatRolleMitarbeiter|| hatRolleKueche">
    <v-btn class="ma-3" color="primary"
           fab
           @click="initDialog"
           right
    >
      <v-icon>
        mdi-cloud-upload
      </v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        max-width="500"
    >
      <v-card rounded>
        <v-alert
            v-if="info"
            :color="info.typ ==='success'?'green darken-3':'error'"
            :type="info.typ"
            class="ma-0"
        >
          {{ info.nachricht }}
        </v-alert>
        <v-card-title class="text-h5">
          Neuen Speiseplan hochladen
        </v-card-title>

        <v-card-text>
          <v-select
              v-model="ausgewaehlteWoche"
              :items="speiseplanWochen"
              class="mb-3"
              dense
              hide-details
              item-text="text"
              outlined
              prepend-icon="mdi-calendar-range"
              return-object
              :disabled="ausgewaehltertyp==='ALLERGENENLISTE'"
          >
            <template v-slot:item="{ item }">
              <v-icon v-if="inWocheGibtEsEinenSpeiseplan(item)" color="accent" dense left small>mdi-radiobox-marked</v-icon>
              <v-icon v-else color="accent" dense left small>mdi-radiobox-blank</v-icon>
              <div>
                {{ item.text }}
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <v-icon v-if="inWocheGibtEsEinenSpeiseplan(item)" color="accent" dense left small>mdi-radiobox-marked</v-icon>
              <v-icon v-else color="accent" dense left small>mdi-radiobox-blank</v-icon>
              <div class="kw-select-text">
                {{ item.text }}
              </div>
            </template>
          </v-select>
          <v-select v-model="ausgewaehltertyp"
                    :items="speiseplanTypen"
                    class="mb-3"
                    dense
                    hide-details
                    label="Typ"
                    outlined
                    prepend-icon="mdi-calendar-range"
          >
          </v-select>
          <v-file-input outlined
                        accept="application/pdf"
                        dense
                        label="Datei auswählen..."
                        truncate-length="30"
                        show-size
                        prepend-icon="mdi-cloud-upload"
                        v-model="file"
                        counter
                        :disabled="loading"

          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              depressed
              color="primary"
              @click="speichern"
              :disabled="!file"
              :loading="loading"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {speiseplanservice} from "@/service";
import {mapGetters, mapMutations} from "vuex";
import moment from "moment/moment";
import {SpeiseplanTypen} from "@/components/speiseplan/SpeiseplanTypenEnum";

export default {
  name: "SpeiseplanUploadComponent",
  props: ['wochenMitSpeiseplaenen'],
  data: () => ({
    dialog: false,
    file: null,
    ausgewaehltertyp: "MIT_DESSERT",
    info: null,
    loading: false,
    ausgewaehlteWoche: {},
    speiseplanWochen: []
  }),
  computed: {
    ...mapGetters(['hatRolleMitarbeiter', 'hatRolleKueche']),
    speiseplanTypen() {
      return Array.from(SpeiseplanTypen, ([name, value]) => ({name, value})).map(i => {
        return {value: i.name, text: i.value}
      });
    }
  },
  methods: {
    initDialog() {
      this.dialog = true
      this.file = null
      this.info = null
      this.loading = false
    },
    ...mapMutations(["addErfolgsNachricht"]),
    inWocheGibtEsEinenSpeiseplan(item) {
      return this.wochenMitSpeiseplaenen.find(it => it.kw === item.kw && it.jahr === item.jahr)
    },
    speichern() {
      this.loading = true
      speiseplanservice.erstelleSpeiseplan(this.ausgewaehltertyp, this.file, this.ausgewaehlteWoche.jahr, this.ausgewaehlteWoche.kw)
          .then(() => {
            this.addErfolgsNachricht("Speiseplan erfolgreich hochgeladen")
            this.$emit("update")
            this.dialog = false
          })
          .catch(error => {
            console.log(error)
            this.info = {
              nachricht: error.response.data.message,
              typ: "error"
            }
          })
          .finally(() => this.loading = false)
    }
  },
  created() {
    let startDatum = moment().subtract(2, 'weeks')
    while (startDatum.isSameOrBefore(moment().add(10, 'weeks'))) {
      this.speiseplanWochen.push({
        "kw": startDatum.isoWeek(),
        "jahr": startDatum.isoWeekYear(),
        "text": "KW " + startDatum.isoWeek() + " - " + startDatum.isoWeekYear() + " (" + startDatum.startOf('isoWeek').toDate().toLocaleDateString() + " - " + startDatum.endOf('isoWeek').toDate().toLocaleDateString() + " )"
      })
      startDatum = startDatum.add(1, 'weeks')
    }

    this.ausgewaehlteWoche = this.speiseplanWochen.find(it => it.kw === moment().isoWeek() && it.jahr === moment().isoWeekYear())
  }
}
</script>

<style scoped>
.kw-select-text {
  font-size: 0.9rem;
}
</style>