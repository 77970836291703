export default class MonatsubersichtFilterVO {
    jahrFilter: number
    monatFilter: number
    kitaFilter: string


    constructor(jahrFilter: number, monatFilter: number, kitaFilter: string) {
        this.jahrFilter = jahrFilter;
        this.monatFilter = monatFilter;
        this.kitaFilter = kitaFilter;
    }
}