/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BenutzerVO } from '../model';
// @ts-ignore
import { HistorienEintraegeVO } from '../model';
// @ts-ignore
import { InlineObject1VO } from '../model';
// @ts-ignore
import { KonfigurationVO } from '../model';
/**
 * VerwaltungApi - axios parameter creator
 * @export
 */
export const VerwaltungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lädt Benutzer
         * @param {'kueche' | 'mitarbeiter' | 'kita'} [rolle] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {boolean} [kitaZugewiesen] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzer: async (rolle?: 'kueche' | 'mitarbeiter' | 'kita', kitaZugewiesen?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/benutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rolle !== undefined) {
                localVarQueryParameter['rolle'] = rolle;
            }

            if (kitaZugewiesen !== undefined) {
                localVarQueryParameter['kitaZugewiesen'] = kitaZugewiesen;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lädt die aktuelle Systemkonfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKonfiguration: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ladeHistorienEintraege
         * @param {number} [anzahl] Anzahl der Elemente die maximal zurückgegeben werden sollen. 
         * @param {number} [seite] Ermöglicht eine Pagination. Startet bei 0 
         * @param {string} [kitaid] Optionale Filtermöglichkeit nach Kitaid 
         * @param {string} [typ] Typ des Eintrages
         * @param {'asc' | 'desc'} [sortierReihenfolge] Sortiert entweder aufsteigend oder Absteigend nach Erstelldatum 
         * @param {string} [benutzername] Filtert nach Benutzername 
         * @param {string} [datumVon] Filtert nach Datum. Nur Einträge die nach dem angegebenen Datum erstellt wurden werden zurückgegeben 
         * @param {string} [datumBis] Filtert nach Datum. Nur Einträge die vor dem angegebenen Datum erstellt wurden werden zurückgegeben 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeHistorienEintraege: async (anzahl?: number, seite?: number, kitaid?: string, typ?: string, sortierReihenfolge?: 'asc' | 'desc', benutzername?: string, datumVon?: string, datumBis?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/historieneintraege`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (anzahl !== undefined) {
                localVarQueryParameter['anzahl'] = anzahl;
            }

            if (seite !== undefined) {
                localVarQueryParameter['seite'] = seite;
            }

            if (kitaid !== undefined) {
                localVarQueryParameter['kitaid'] = kitaid;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }

            if (sortierReihenfolge !== undefined) {
                localVarQueryParameter['sortierReihenfolge'] = sortierReihenfolge;
            }

            if (benutzername !== undefined) {
                localVarQueryParameter['benutzername'] = benutzername;
            }

            if (datumVon !== undefined) {
                localVarQueryParameter['datumVon'] = (datumVon as any instanceof Date) ?
                    (datumVon as any).toISOString().substr(0,10) :
                    datumVon;
            }

            if (datumBis !== undefined) {
                localVarQueryParameter['datumBis'] = (datumBis as any instanceof Date) ?
                    (datumBis as any).toISOString().substr(0,10) :
                    datumBis;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lädt alle Benutzer aus der hinterlegten Benutzerverwaltung und aktualisiert diese in der Datenbank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBenutzerverwaltung: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/benutzerverwaltung`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Setzt das Passwort für den Speiseplanzugriff für nicht authentifizierte Nutzer
         * @param {InlineObject1VO} inlineObject1VO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setzeSpeiseplanPasswort: async (inlineObject1VO: InlineObject1VO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1VO' is not null or undefined
            assertParamExists('setzeSpeiseplanPasswort', 'inlineObject1VO', inlineObject1VO)
            const localVarPath = `/verwaltung/speiseplanpasswort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1VO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary speichert die Systemkonfiguration
         * @param {KonfigurationVO} konfigurationVO Systemkonfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKonfiguration: async (konfigurationVO: KonfigurationVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'konfigurationVO' is not null or undefined
            assertParamExists('updateKonfiguration', 'konfigurationVO', konfigurationVO)
            const localVarPath = `/konfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(konfigurationVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerwaltungApi - functional programming interface
 * @export
 */
export const VerwaltungApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerwaltungApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lädt Benutzer
         * @param {'kueche' | 'mitarbeiter' | 'kita'} [rolle] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {boolean} [kitaZugewiesen] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenutzer(rolle?: 'kueche' | 'mitarbeiter' | 'kita', kitaZugewiesen?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BenutzerVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenutzer(rolle, kitaZugewiesen, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lädt die aktuelle Systemkonfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKonfiguration(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KonfigurationVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKonfiguration(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ladeHistorienEintraege
         * @param {number} [anzahl] Anzahl der Elemente die maximal zurückgegeben werden sollen. 
         * @param {number} [seite] Ermöglicht eine Pagination. Startet bei 0 
         * @param {string} [kitaid] Optionale Filtermöglichkeit nach Kitaid 
         * @param {string} [typ] Typ des Eintrages
         * @param {'asc' | 'desc'} [sortierReihenfolge] Sortiert entweder aufsteigend oder Absteigend nach Erstelldatum 
         * @param {string} [benutzername] Filtert nach Benutzername 
         * @param {string} [datumVon] Filtert nach Datum. Nur Einträge die nach dem angegebenen Datum erstellt wurden werden zurückgegeben 
         * @param {string} [datumBis] Filtert nach Datum. Nur Einträge die vor dem angegebenen Datum erstellt wurden werden zurückgegeben 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeHistorienEintraege(anzahl?: number, seite?: number, kitaid?: string, typ?: string, sortierReihenfolge?: 'asc' | 'desc', benutzername?: string, datumVon?: string, datumBis?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistorienEintraegeVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeHistorienEintraege(anzahl, seite, kitaid, typ, sortierReihenfolge, benutzername, datumVon, datumBis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lädt alle Benutzer aus der hinterlegten Benutzerverwaltung und aktualisiert diese in der Datenbank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshBenutzerverwaltung(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshBenutzerverwaltung(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Setzt das Passwort für den Speiseplanzugriff für nicht authentifizierte Nutzer
         * @param {InlineObject1VO} inlineObject1VO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setzeSpeiseplanPasswort(inlineObject1VO: InlineObject1VO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setzeSpeiseplanPasswort(inlineObject1VO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary speichert die Systemkonfiguration
         * @param {KonfigurationVO} konfigurationVO Systemkonfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKonfiguration(konfigurationVO: KonfigurationVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKonfiguration(konfigurationVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VerwaltungApi - factory interface
 * @export
 */
export const VerwaltungApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerwaltungApiFp(configuration)
    return {
        /**
         * 
         * @summary Lädt Benutzer
         * @param {'kueche' | 'mitarbeiter' | 'kita'} [rolle] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {boolean} [kitaZugewiesen] Gibt nur Benutzer mit der angegebenen Rolle zurück 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenutzer(rolle?: 'kueche' | 'mitarbeiter' | 'kita', kitaZugewiesen?: boolean, options?: any): AxiosPromise<Array<BenutzerVO>> {
            return localVarFp.getBenutzer(rolle, kitaZugewiesen, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lädt die aktuelle Systemkonfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKonfiguration(options?: any): AxiosPromise<KonfigurationVO> {
            return localVarFp.getKonfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * ladeHistorienEintraege
         * @param {number} [anzahl] Anzahl der Elemente die maximal zurückgegeben werden sollen. 
         * @param {number} [seite] Ermöglicht eine Pagination. Startet bei 0 
         * @param {string} [kitaid] Optionale Filtermöglichkeit nach Kitaid 
         * @param {string} [typ] Typ des Eintrages
         * @param {'asc' | 'desc'} [sortierReihenfolge] Sortiert entweder aufsteigend oder Absteigend nach Erstelldatum 
         * @param {string} [benutzername] Filtert nach Benutzername 
         * @param {string} [datumVon] Filtert nach Datum. Nur Einträge die nach dem angegebenen Datum erstellt wurden werden zurückgegeben 
         * @param {string} [datumBis] Filtert nach Datum. Nur Einträge die vor dem angegebenen Datum erstellt wurden werden zurückgegeben 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeHistorienEintraege(anzahl?: number, seite?: number, kitaid?: string, typ?: string, sortierReihenfolge?: 'asc' | 'desc', benutzername?: string, datumVon?: string, datumBis?: string, options?: any): AxiosPromise<HistorienEintraegeVO> {
            return localVarFp.ladeHistorienEintraege(anzahl, seite, kitaid, typ, sortierReihenfolge, benutzername, datumVon, datumBis, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lädt alle Benutzer aus der hinterlegten Benutzerverwaltung und aktualisiert diese in der Datenbank
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshBenutzerverwaltung(options?: any): AxiosPromise<void> {
            return localVarFp.refreshBenutzerverwaltung(options).then((request) => request(axios, basePath));
        },
        /**
         * Setzt das Passwort für den Speiseplanzugriff für nicht authentifizierte Nutzer
         * @param {InlineObject1VO} inlineObject1VO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setzeSpeiseplanPasswort(inlineObject1VO: InlineObject1VO, options?: any): AxiosPromise<void> {
            return localVarFp.setzeSpeiseplanPasswort(inlineObject1VO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary speichert die Systemkonfiguration
         * @param {KonfigurationVO} konfigurationVO Systemkonfiguration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKonfiguration(konfigurationVO: KonfigurationVO, options?: any): AxiosPromise<void> {
            return localVarFp.updateKonfiguration(konfigurationVO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerwaltungApi - object-oriented interface
 * @export
 * @class VerwaltungApi
 * @extends {BaseAPI}
 */
export class VerwaltungApi extends BaseAPI {
    /**
     * 
     * @summary Lädt Benutzer
     * @param {'kueche' | 'mitarbeiter' | 'kita'} [rolle] Gibt nur Benutzer mit der angegebenen Rolle zurück 
     * @param {boolean} [kitaZugewiesen] Gibt nur Benutzer mit der angegebenen Rolle zurück 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerwaltungApi
     */
    public getBenutzer(rolle?: 'kueche' | 'mitarbeiter' | 'kita', kitaZugewiesen?: boolean, options?: any) {
        return VerwaltungApiFp(this.configuration).getBenutzer(rolle, kitaZugewiesen, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lädt die aktuelle Systemkonfiguration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerwaltungApi
     */
    public getKonfiguration(options?: any) {
        return VerwaltungApiFp(this.configuration).getKonfiguration(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ladeHistorienEintraege
     * @param {number} [anzahl] Anzahl der Elemente die maximal zurückgegeben werden sollen. 
     * @param {number} [seite] Ermöglicht eine Pagination. Startet bei 0 
     * @param {string} [kitaid] Optionale Filtermöglichkeit nach Kitaid 
     * @param {string} [typ] Typ des Eintrages
     * @param {'asc' | 'desc'} [sortierReihenfolge] Sortiert entweder aufsteigend oder Absteigend nach Erstelldatum 
     * @param {string} [benutzername] Filtert nach Benutzername 
     * @param {string} [datumVon] Filtert nach Datum. Nur Einträge die nach dem angegebenen Datum erstellt wurden werden zurückgegeben 
     * @param {string} [datumBis] Filtert nach Datum. Nur Einträge die vor dem angegebenen Datum erstellt wurden werden zurückgegeben 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerwaltungApi
     */
    public ladeHistorienEintraege(anzahl?: number, seite?: number, kitaid?: string, typ?: string, sortierReihenfolge?: 'asc' | 'desc', benutzername?: string, datumVon?: string, datumBis?: string, options?: any) {
        return VerwaltungApiFp(this.configuration).ladeHistorienEintraege(anzahl, seite, kitaid, typ, sortierReihenfolge, benutzername, datumVon, datumBis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lädt alle Benutzer aus der hinterlegten Benutzerverwaltung und aktualisiert diese in der Datenbank
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerwaltungApi
     */
    public refreshBenutzerverwaltung(options?: any) {
        return VerwaltungApiFp(this.configuration).refreshBenutzerverwaltung(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Setzt das Passwort für den Speiseplanzugriff für nicht authentifizierte Nutzer
     * @param {InlineObject1VO} inlineObject1VO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerwaltungApi
     */
    public setzeSpeiseplanPasswort(inlineObject1VO: InlineObject1VO, options?: any) {
        return VerwaltungApiFp(this.configuration).setzeSpeiseplanPasswort(inlineObject1VO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary speichert die Systemkonfiguration
     * @param {KonfigurationVO} konfigurationVO Systemkonfiguration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerwaltungApi
     */
    public updateKonfiguration(konfigurationVO: KonfigurationVO, options?: any) {
        return VerwaltungApiFp(this.configuration).updateKonfiguration(konfigurationVO, options).then((request) => request(this.axios, this.basePath));
    }
}
