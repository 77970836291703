<template>
  <v-row no-gutters>
    <v-col cols="12" sm="2" class="my-auto">
      <label class="my-auto">{{ label }}</label>
    </v-col>
    <v-col cols="12" sm="10">
      <v-menu
          ref="menue"
          v-model="menue"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="formatiertesDatum"
              prepend-icon="mdi-calendar"
              flat
              solo-inverted
              hide-details
              dense
              v-bind="attrs"
              readonly
              v-on="on"
          />
        </template>
        <v-date-picker
            v-model="datum"
            :allowed-dates="erlaubteDaten"
            :picker-date.sync="pickerDate"
            first-day-of-week="1"
            flat
            locale="de"
            :min="minDatum"
            no-title
            :events="events"
            :event-color="'primary'"
            @input="inputDatum()"
        />
      </v-menu>
    </v-col>

  </v-row>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'DatumAuswahlComponent',
  props: {
    events: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    verschiebung: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    datum: new Date().toISOString().substr(0, 10),
    menue: false,
    pickerDate: ''
  }),
  computed: {
    formatiertesDatum() {
      const [year, month, day] = this.datum.split('-')
      return `${day}.${month}.${year}`
    },
    minDatum() {
      if (!this.verschiebung) {
        return this.datum
      }

      /* verschobenesDatum basiert standardmäßig auf heute und addiert die Verschiebung aus den Props */
      const heute = new Date()
      const verschobenesDatum = new Date(heute)
      verschobenesDatum.setDate(verschobenesDatum.getDate() + this.verschiebung)
      return verschobenesDatum.toISOString().substr(0, 10)
    }
  },
  watch: {
    $attrs() {
      if (this.$attrs.value) {
        this.datum = this.$attrs.value
      }
    },
    pickerDate(neuerWert) {
      this.$emit('dateChanged', neuerWert)
    }
  },
  mounted() {
    this.datum = this.minDatum
    this.inputDatum()
  },
  methods: {
    erlaubteDaten(val) {
      return val >= this.minDatum
    },
    inputDatum() {
      this.menue = false
      this.$emit('input', this.datum)
    }
  }
})
</script>

<style scoped>

</style>