import Vue from 'vue';
import Vuex from 'vuex';
import {NachrichtVO} from "@/components/nachrichten/NachrichtVO";
import {kitasstore} from "@/store/kitasstore";
import {essensartenservice} from "@/service";
import {BenutzerVO} from "@/generated";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        nachricht: {},
        benutzer: {} as BenutzerVO,
        essensarten: Array<String>(),
        oidcuser: {},
        authenticated: false
    },
    getters: {
        getKitaVonBenutzer(state) {
            return state.benutzer.kita
        },
        hatRolleMitarbeiter(state, getters) {
            if (getters.oidcUser.roles) {
                return getters.oidcUser.roles.includes('mitarbeiter')
            } else {
                return false
            }
        },
        hatRolleKita(state, getters) {
            if (getters.oidcUser.roles) {
                return getters.oidcUser.roles.includes('kita')
            } else {
                return false
            }
        },
        hatRolleKueche(state, getters) {
            if (getters.oidcUser.roles) {
                return getters.oidcUser.roles.includes('kueche')
            } else {
                return false
            }
        },
        hatRolleBenutzerverwaltung(state, getters) {
            if (getters.oidcUser.roles) {
                return getters.oidcUser.roles.includes('benutzerverwaltung')
            } else {
                return false
            }
        },
        oidcUser(state) {
            return state.oidcuser
        },
        oidcIsAuthenticated(state) {
            return state.authenticated
        }
    },
    mutations: {
        addErfolgsNachricht(state, text) {
            state.nachricht = new NachrichtVO(text, 'success')
        },
        setzeEssensarten(state, essensarten) {
            state.essensarten = essensarten
            state.essensarten.sort((a: any, b: any) => a.art.localeCompare(b.art))
        },
        setzeBenutzer(state, benutzer) {
            state.benutzer = benutzer
        },
        setOidcUser(state, user) {
            state.oidcuser = user
        },
        setAuthenticated(state, auth: boolean) {
            state.authenticated = auth
        }
    },
    actions: {
        async refreshEssensarten(context) {
            essensartenservice.ladeEssensarten('aktiv').then(response => {
                context.commit('setzeEssensarten', response.data)
            }).catch(error => {
                console.log(error)
            })
        },
    },
    modules: {
        kitasstore: kitasstore
    }
});