<template>
  <div v-if="oidcIsAuthenticated">
    <v-list-item
        color="red"
        link
    >
      <v-list-item-icon>
        <v-icon
            color="red"
            v-text="'mdi-exit-to-app'"
        />
      </v-list-item-icon>
      <v-list-item-content class="red--text" @click="dialog=true">
        <v-list-item-title>
          Abmelden
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-dialog
        v-model="dialog"
        max-width="500"
        persistent
        overlay-opacity="0.8"
    >
      <v-card>
        <v-card-title class="text-center justify-center" >
          <span class="headline"> Möchten Sie sich wirklich abmelden?</span>

        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="error"
              depressed
              @click="abmelden()"
          >
            Abmelden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AbmeldenComponent",
  data: () => ({
    dialog: false
  }),
  methods: {
    async abmelden() {
      this.$keycloak.logout()
    }
  },
  computed: {
    ...mapGetters(['oidcIsAuthenticated']),
  }
}
</script>

<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>