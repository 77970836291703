<template>
  <v-menu
      ref="menue"
      v-model="menue"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="formatiertesDatum"
          prepend-inner-icon="mdi-calendar"
          label="Datum"
          flat
          outlined
          hide-details
          dense
          v-bind="attrs"
          readonly
          v-on="on"
      />
    </template>
    <v-date-picker
        v-model="datum"
        :allowed-dates="erlaubteDaten"
        :picker-date.sync="pickerDate"
        first-day-of-week="1"
        color="primary"
        flat
        locale="de"
        :min="minDatum"
        @input="inputDatum()"
    />
  </v-menu>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AuswertungDatumsAuswahlComponent',
  data: () => ({
    datum: new Date().toISOString().substr(0, 10),
    menue: false,
    pickerDate: ''
  }),
  computed: {
    formatiertesDatum() {
      const [year, month, day] = this.datum.split('-')
      return `${day}.${month}.${year}`
    },
    minDatum() {
      return new Date(2022, 0, 1).toISOString();
    }
  },
  methods: {
    erlaubteDaten(val) {
      return val >= this.minDatum
    },
    inputDatum() {
      this.menue = false
      this.$emit('input', this.datum)
    }
  },
  created() {
    this.inputDatum()
  }
})
</script>

<style scoped>

</style>