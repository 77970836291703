export default class Datumutil {

    static ersterMontagDesMonats(jahr, monat) {
        var dat = new Date(jahr + '/' + monat + '/1')

        // Get the first Monday in the month
        while (dat.getDay() !== 1) {
            dat.setDate(dat.getDate() + 1);
        }
        return dat;
    }
}

