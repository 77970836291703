import {EssensbestellpositionVO, NeueEssensbestellungVO} from "@/generated";

export default class NeueEssensbestellung implements NeueEssensbestellungVO {
    essensbestellpositionen: Array<EssensbestellpositionVO>;
    kitaId: string;
    startdatum: string;
    endddatum?: string;
    bemerkung?: string;


    constructor(essensbestellpositionen: Array<EssensbestellpositionVO>, kitaId: string, startdatum: string, endddatum?: string, bemerkung?: string) {
        this.essensbestellpositionen = essensbestellpositionen;
        this.kitaId = kitaId;
        this.startdatum = startdatum;
        this.endddatum = endddatum;
        this.bemerkung = bemerkung;
    }
}
