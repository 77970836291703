<template>
  <v-container>
    <v-row class="px-3">
      <v-col
          cols="4"
      >
        <h2>Historieneinträge einsehen</h2>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="3">
        <v-text-field v-model="datumVon"
                      class="text-center"
                      clearable
                      dense
                      hide-details
                      label="Datum von"
                      outlined
                      type="date"
                      @input="filterHistorienEintraege"
        >
          test
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field v-model="datumBis"
                      class="text-center"
                      clearable
                      dense
                      hide-details
                      label="Datum bis"
                      outlined
                      type="date"
                      @input="filterHistorienEintraege"
        >
          test
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>

      <v-col cols="12" sm="4">
        <historieneintrag-ersteller-filter-component
            v-model="erstellerFilter"
            @input="filterHistorienEintraege">
          >
        </historieneintrag-ersteller-filter-component>
      </v-col>
      <v-col cols="12" sm="4">
        <historien-eintrag-typ-filter-component
            v-model="typFilter"
            @input="filterHistorienEintraege">

        </historien-eintrag-typ-filter-component>
      </v-col>
      <v-col cols="12" sm="4">
        <kita-filter-component
            v-model="kitafilter"
            @input="filterHistorienEintraege">
        </kita-filter-component>
      </v-col>
    </v-row>
    <v-row>
      <v-progress-linear
          v-if="loading"
          indeterminate
      ></v-progress-linear>
      <v-col cols="12">
        <v-expansion-panels :hover=true>
          <v-expansion-panel
              v-for="eintrag in historieneintraege"
              :key="eintrag.datum"

          >
            <v-expansion-panel-header class="px-2 py-2 historienheader">
              <v-row dense>
                <v-col align-self="center" cols="12" md="2">
                  {{ new Date(eintrag.datum).toLocaleString() }}
                </v-col>
                <v-col cols="5" md="2">
                  <v-chip :color="bestimmeChipFarbe(eintrag.typ)" class="pa-2 text-center " dark small>
                    {{ formatiereTyp(eintrag.typ) }}
                  </v-chip>

                </v-col>
                <v-col align-self="center" class="font-weight-bold" cols="5" md="4">
                  {{ eintrag.kitaname }}
                </v-col>
                <v-col align-self="center" class="text-end" cols="2" md="4">
                  <v-icon small>
                    mdi-account
                  </v-icon>
                  {{ eintrag.ersteller }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bemerkungstext" style="white-space: pre-line">
              {{ verschoenere(eintrag.bemerkung) }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-pagination
            v-if="historieneintraege.length"
            v-model="seite"
            :disabled="loading"
            :length="getMaxSeiten"
            circle
            total-visible="10"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KitaFilterComponent from "@/components/kita/KitaFilterComponent";
import {verwaltungservice} from "@/service";
import {eintragstypen} from "@/components/verwaltung/historie/HistorienEintragTypMapping";
import HistorienEintragTypFilterComponent from "@/components/verwaltung/historie/HistorienEintragTypFilterComponent";
import HistorieneintragErstellerFilterComponent from "@/components/verwaltung/historie/HistorienEintragErstellerFiltercomponent.vue";

export default {
  name: "HistorieComponent",
  components: {HistorienEintragTypFilterComponent, KitaFilterComponent, HistorieneintragErstellerFilterComponent},
  data() {
    return {
      kitafilter: null,
      typFilter: null,
      erstellerFilter: null,
      datumVon: null,
      datumBis: null,
      historieneintraege: [],
      seite: 1,
      maxSeiten: 1,
      loading: true
    }
  },
  methods: {
    ladeHistorienEintraege() {
      this.loading = true
      verwaltungservice.ladeHistorienEintraege(25, this.seite - 1, this.kitafilter || undefined, this.typFilter || undefined, "desc", this.erstellerFilter || undefined, this.datumVon || undefined, this.datumBis || undefined)
          .then(response => {
            this.historieneintraege = response.data.eintraege
            this.maxSeiten = response.data.maxSeiten
          }).finally(() => this.loading = false)
    },
    verschoenere(text) {
      return text.replaceAll("&gt;", ">").replaceAll("&quot;", "'")
    },
    formatiereTyp(typ) {
      return eintragstypen.find(t => t.name === typ).anzeigeName
    },
    bestimmeChipFarbe(typ) {
      return eintragstypen.find(t => t.name === typ).chipFarbe
    },
    filterHistorienEintraege() {
      this.seite = 1
      this.ladeHistorienEintraege()
    }
  },
  computed: {
    getMaxSeiten() {
      return this.maxSeiten > 50 ? 50 : this.maxSeiten
    },
    headers() {
      return [
        {
          text: 'Datum',
          align: 'start',
          sortable: true,
          value: 'datum',
        },
        {
          text: 'Ersteller',
          value: 'ersteller',
          sortable: false,
        },
        {text: 'Typ', value: 'typ', sortable: false,},
        {text: 'Kita', value: 'kitaname', sortable: false,},
        {text: '', value: 'data-table-expand'},
      ]
    },
  },
  created() {
    this.ladeHistorienEintraege()
  },
  watch: {
    seite() {
      this.ladeHistorienEintraege()
    },
  },

}
</script>

<style scoped>
.bemerkungstext {
  line-height: 1.5 !important;
  font-size: 14px !important;
}

.historienheader {
  min-height: 40px;
}
</style>