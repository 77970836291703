import {KitaVO} from "@/generated";
import {Module} from "vuex";
import {kitaservice} from "@/service";

export const kitasstore: Module<any, any> = {
    state: () => ({
        kitas: Array<KitaVO>()
    }),
    mutations: {
        setKita(state: any, kitas: Array<KitaVO>) {
            state.kitas = kitas
        }
    },
    actions: {
        async refreshKitas(context) {
            kitaservice.kitasSuchen(0, 0, 'aktiv', undefined, undefined, 'asc', true)
                .then(response => {
                    if(response.data.kitas!!.length>0){
                        context.commit('setKita', response.data.kitas)
                    }
                }).catch(error => {
                console.log(error.response)
            })
        }
    },
    getters: {
        getKitas(state): Array<KitaVO> {
            return state.kitas
        }
    }
}
