<template>
  <v-row>
    <v-col cols="12"
           md="3"
           sm="6"
    >
      <v-select
          outlined
          dense
          v-model="monatsuebersichtfilter.jahrFilter"
          label="Nach Jahr filtern"
          :items="jahreAb2021"
          hide-details
          @change="setzeJahr"
      />
    </v-col>
    <v-col cols="12"
           md="3"
           sm="6">
      <v-select
          outlined
          dense
          v-model="monatsuebersichtfilter.monatFilter"
          :items="monateMitNummern"
          label="Nach Monate filtern"
          item-text="name"
          item-value="nummer"
          hide-details
          @change="setzeMonat"
      />
    </v-col>
    <v-col cols="12"
           sm="12"
           md="6">
      <kita-filter-component
          @input="setzeKita"
      >
      </kita-filter-component>
    </v-col>
  </v-row>
</template>

<script>
import JahrMonatMixin from "@/components/tools/JahrMonatMixin";
import KitaFilterComponent from "@/components/kita/KitaFilterComponent";
import MonatsubersichtFilterVO from "@/model/MonatsubersichtFilterVO";

export default {
  name: "MonatsuebersichtFilterComponent",
  components: {KitaFilterComponent},
  mixins: [JahrMonatMixin],
  data: () => ({
    monatsuebersichtfilter: new MonatsubersichtFilterVO(new Date().getFullYear(), new Date().getMonth() + 1, null)
  }),
  methods: {
    refresh() {
      this.$emit("change", this.monatsuebersichtfilter)
    },
    setzeKita(e) {
      this.monatsuebersichtfilter.kitaFilter = e
      this.refresh()
    },
    setzeJahr(e) {
      this.monatsuebersichtfilter.jahrFilter = e
      this.refresh()
    },
    setzeMonat(e) {
      this.monatsuebersichtfilter.monatFilter = e
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }
}
</script>

<style scoped>

</style>