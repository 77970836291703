<template>
  <v-autocomplete v-if="hatRolleMitarbeiter || hatRolleKueche"
                  v-model="ausgewaehlteKita"
                  label="Nach Einrichtung filtern"
                  auto-select-first
                  :items="kitas"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details
                  @change="$emit('input',$event)"
                  no-data-text="Keine Kitas gefunden"
                  outlined
                  dense

  />
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: ['value'],
  name: "KitaFilterComponent",
  data() {
    return {
      ausgewaehlteKita: this.value
    }
  },
  watch: {
    ausgewaehlteKita(neuerWert) {
      this.$emit('input', neuerWert)
    },
    getKitaVonBenutzer: {
      immediate: true,
      handler(neuerWert) {
        if (neuerWert) {
          this.ausgewaehlteKita = neuerWert.id
        }
      }
    }
  },
  methods: {
    ...mapActions(['refreshKitas'])
  },
  computed: {
    ...mapGetters(['getKitas', 'hatRolleKueche', 'hatRolleMitarbeiter', 'getKitaVonBenutzer', 'hatRolleKita']),
    kitas() {
      if (this.getKitas.length === 0 && !this.hatRolleKita) {
        this.refreshKitas()
      }
      return this.getKitas
    }
  },

}
</script>

<style scoped>

</style>