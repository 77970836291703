<template>
  <div v-if="!ziel.ziele || ziel.ziele.some(it => pruefeAufRollen(it))">
    <v-list-group
        v-if="ziel.ziele && ziel.ziele.some(it => pruefeAufRollen(it))"
        no-action
        :value="routeIstEinesDerSubziele"
        :prepend-icon="ziel.icon"
        active-class="no-active"
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title v-text="ziel.name"/>
        </v-list-item-content>
      </template>
      <v-list-item
          v-for="subziel in gefilterteZiele"
          :key="subziel.name"
          link
          :to="{name:subziel.zielkomponentenname}"

      >
        <v-list-item-icon
            v-if="subziel.icon"
            class="mr-3"
        >
          <v-icon v-text="subziel.icon"/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="subziel.name"/>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-list-item
        v-else
        :to="{name:ziel.zielkomponentenname}"
        active-class="primary--text"
    >
      <v-list-item-icon v-if="ziel.icon">
        <v-icon v-text="ziel.icon"/>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ ziel.name }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NavigationsZielComponent",
  props: [
    'ziel'
  ],
  computed: {
    ...mapGetters(['oidcUser']),
    routeIstEinesDerSubziele() {
      return this.ziel.ziele.map(it => it.zielkomponentenname).includes(this.$route.name)
    },
    gefilterteZiele(){
      return this.ziel.ziele.filter(subziel => this.pruefeAufRollen(subziel));
    }
  },
  methods: {
    pruefeAufRollen(ziel) {
      if (ziel.zielkomponentenname) {
        let route = this.$router.getRoutes().find(route => route.name === ziel.zielkomponentenname);
        if (route.meta.rollen && this.oidcUser.roles) {
          return route.meta.rollen.some(rolle => this.oidcUser.roles.some(it => it === rolle))
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>

</style>