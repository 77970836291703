/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { VorhandeneSpeiseplaendeVO } from '../model';
/**
 * SpeiseplanApi - axios parameter creator
 * @export
 */
export const SpeiseplanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Prüft ob das übergebene Passwort mit dem hinterlegten übereinstimmt. Nur notwendig wenn der Nutzer nicht authentifizierit ist
         * @param {string} speiseplanpasswort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPasswort: async (speiseplanpasswort: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'speiseplanpasswort' is not null or undefined
            assertParamExists('checkPasswort', 'speiseplanpasswort', speiseplanpasswort)
            const localVarPath = `/speiseplaene/passwortcheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (speiseplanpasswort !== undefined) {
                localVarQueryParameter['speiseplanpasswort'] = speiseplanpasswort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hinterlegt den aktuellen Speiseplan
         * @param {string} typ typ des Dokuments. Kann mit oder ohne Dessert sein
         * @param {any} body 
         * @param {number} [jahr] 
         * @param {number} [kw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erstelleSpeiseplan: async (typ: string, body: any, jahr?: number, kw?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'typ' is not null or undefined
            assertParamExists('erstelleSpeiseplan', 'typ', typ)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('erstelleSpeiseplan', 'body', body)
            const localVarPath = `/speiseplaene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }

            if (kw !== undefined) {
                localVarQueryParameter['kw'] = kw;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/pdf';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * lade Speiseplan
         * @param {string} typ typ des Dokuments. Kann mit oder ohne Dessert sein
         * @param {number} [jahr] 
         * @param {number} [kw] 
         * @param {string} [speiseplanpasswort] Passwort zum Speiseplan. Wird nur benötigt wenn der Nutzer nicht authentifiziert ist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeSpeiseplan: async (typ: string, jahr?: number, kw?: number, speiseplanpasswort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'typ' is not null or undefined
            assertParamExists('ladeSpeiseplan', 'typ', typ)
            const localVarPath = `/speiseplaene`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }

            if (kw !== undefined) {
                localVarQueryParameter['kw'] = kw;
            }

            if (typ !== undefined) {
                localVarQueryParameter['typ'] = typ;
            }

            if (speiseplanpasswort !== undefined) {
                localVarQueryParameter['speiseplanpasswort'] = speiseplanpasswort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * laedt eine Liste an Wochen an denen es bereits einen Speiseplan gibt
         * @param {string} [speiseplanpasswort] Passwort zum Speiseplan. Wird nur benötigt wenn der Nutzer nicht authentifiziert ist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeSpeiseplanWochen: async (speiseplanpasswort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/speiseplanWochen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (speiseplanpasswort !== undefined) {
                localVarQueryParameter['speiseplanpasswort'] = speiseplanpasswort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpeiseplanApi - functional programming interface
 * @export
 */
export const SpeiseplanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpeiseplanApiAxiosParamCreator(configuration)
    return {
        /**
         * Prüft ob das übergebene Passwort mit dem hinterlegten übereinstimmt. Nur notwendig wenn der Nutzer nicht authentifizierit ist
         * @param {string} speiseplanpasswort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkPasswort(speiseplanpasswort: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkPasswort(speiseplanpasswort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hinterlegt den aktuellen Speiseplan
         * @param {string} typ typ des Dokuments. Kann mit oder ohne Dessert sein
         * @param {any} body 
         * @param {number} [jahr] 
         * @param {number} [kw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erstelleSpeiseplan(typ: string, body: any, jahr?: number, kw?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erstelleSpeiseplan(typ, body, jahr, kw, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * lade Speiseplan
         * @param {string} typ typ des Dokuments. Kann mit oder ohne Dessert sein
         * @param {number} [jahr] 
         * @param {number} [kw] 
         * @param {string} [speiseplanpasswort] Passwort zum Speiseplan. Wird nur benötigt wenn der Nutzer nicht authentifiziert ist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeSpeiseplan(typ: string, jahr?: number, kw?: number, speiseplanpasswort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeSpeiseplan(typ, jahr, kw, speiseplanpasswort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * laedt eine Liste an Wochen an denen es bereits einen Speiseplan gibt
         * @param {string} [speiseplanpasswort] Passwort zum Speiseplan. Wird nur benötigt wenn der Nutzer nicht authentifiziert ist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeSpeiseplanWochen(speiseplanpasswort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VorhandeneSpeiseplaendeVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeSpeiseplanWochen(speiseplanpasswort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpeiseplanApi - factory interface
 * @export
 */
export const SpeiseplanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpeiseplanApiFp(configuration)
    return {
        /**
         * Prüft ob das übergebene Passwort mit dem hinterlegten übereinstimmt. Nur notwendig wenn der Nutzer nicht authentifizierit ist
         * @param {string} speiseplanpasswort 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkPasswort(speiseplanpasswort: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkPasswort(speiseplanpasswort, options).then((request) => request(axios, basePath));
        },
        /**
         * hinterlegt den aktuellen Speiseplan
         * @param {string} typ typ des Dokuments. Kann mit oder ohne Dessert sein
         * @param {any} body 
         * @param {number} [jahr] 
         * @param {number} [kw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erstelleSpeiseplan(typ: string, body: any, jahr?: number, kw?: number, options?: any): AxiosPromise<void> {
            return localVarFp.erstelleSpeiseplan(typ, body, jahr, kw, options).then((request) => request(axios, basePath));
        },
        /**
         * lade Speiseplan
         * @param {string} typ typ des Dokuments. Kann mit oder ohne Dessert sein
         * @param {number} [jahr] 
         * @param {number} [kw] 
         * @param {string} [speiseplanpasswort] Passwort zum Speiseplan. Wird nur benötigt wenn der Nutzer nicht authentifiziert ist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeSpeiseplan(typ: string, jahr?: number, kw?: number, speiseplanpasswort?: string, options?: any): AxiosPromise<string> {
            return localVarFp.ladeSpeiseplan(typ, jahr, kw, speiseplanpasswort, options).then((request) => request(axios, basePath));
        },
        /**
         * laedt eine Liste an Wochen an denen es bereits einen Speiseplan gibt
         * @param {string} [speiseplanpasswort] Passwort zum Speiseplan. Wird nur benötigt wenn der Nutzer nicht authentifiziert ist 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeSpeiseplanWochen(speiseplanpasswort?: string, options?: any): AxiosPromise<Array<VorhandeneSpeiseplaendeVO>> {
            return localVarFp.ladeSpeiseplanWochen(speiseplanpasswort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpeiseplanApi - object-oriented interface
 * @export
 * @class SpeiseplanApi
 * @extends {BaseAPI}
 */
export class SpeiseplanApi extends BaseAPI {
    /**
     * Prüft ob das übergebene Passwort mit dem hinterlegten übereinstimmt. Nur notwendig wenn der Nutzer nicht authentifizierit ist
     * @param {string} speiseplanpasswort 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeiseplanApi
     */
    public checkPasswort(speiseplanpasswort: string, options?: any) {
        return SpeiseplanApiFp(this.configuration).checkPasswort(speiseplanpasswort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hinterlegt den aktuellen Speiseplan
     * @param {string} typ typ des Dokuments. Kann mit oder ohne Dessert sein
     * @param {any} body 
     * @param {number} [jahr] 
     * @param {number} [kw] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeiseplanApi
     */
    public erstelleSpeiseplan(typ: string, body: any, jahr?: number, kw?: number, options?: any) {
        return SpeiseplanApiFp(this.configuration).erstelleSpeiseplan(typ, body, jahr, kw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * lade Speiseplan
     * @param {string} typ typ des Dokuments. Kann mit oder ohne Dessert sein
     * @param {number} [jahr] 
     * @param {number} [kw] 
     * @param {string} [speiseplanpasswort] Passwort zum Speiseplan. Wird nur benötigt wenn der Nutzer nicht authentifiziert ist 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeiseplanApi
     */
    public ladeSpeiseplan(typ: string, jahr?: number, kw?: number, speiseplanpasswort?: string, options?: any) {
        return SpeiseplanApiFp(this.configuration).ladeSpeiseplan(typ, jahr, kw, speiseplanpasswort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * laedt eine Liste an Wochen an denen es bereits einen Speiseplan gibt
     * @param {string} [speiseplanpasswort] Passwort zum Speiseplan. Wird nur benötigt wenn der Nutzer nicht authentifiziert ist 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeiseplanApi
     */
    public ladeSpeiseplanWochen(speiseplanpasswort?: string, options?: any) {
        return SpeiseplanApiFp(this.configuration).ladeSpeiseplanWochen(speiseplanpasswort, options).then((request) => request(this.axios, this.basePath));
    }
}
