import Vue from 'vue'
import VueRouter, {Route, RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
import EssensEingabeView from "@/views/bestellungen/EssensEingabeView.vue";
import ImpressumView from "@/views/allgemein/ImpressumView.vue";
import DatenschutzView from "@/views/allgemein/DatenschutzView.vue";
import KitaVerwaltungView from "@/views/verwaltung/KitaVerwaltungView.vue";
import BestellungPausierenView from "@/views/bestellungen/BestellungPausierenView.vue";
import OidcCallback from "@/views/OidcCallback.vue";
import ZugriffVerweigertView from "@/views/ZugriffVerweigertView.vue"
import AuswertungEssensbestellung from "@/views/bestellungen/AuswertungEssensbestellungView.vue"
import Monatsuebersicht from "@/views/verwaltung/MonatsuebersichtView.vue"
import KonfigurationView from "@/views/verwaltung/KonfigurationView.vue"
import InitialisiereView from "@/views/InitialisiereView.vue"
import BestellvorlageView from "@/views/bestellungen/BestellvorlageView.vue"
import AllergenkennzeichnungenView from "@/views/bestellungen/AllergenkennzeichnungenView.vue";
import SpeiseplanView from "@/views/speiseplan/SpeiseplanView.vue"
import HistorienEintraegeView from "@/views/verwaltung/HistorienEintraegeView.vue"
import essensartenView from "@/views/verwaltung/EssensartenView.vue"
import anmelden from "@/views/AnmeldenView.vue"
import store from '@/store'
import KitaInformationenView from "@/views/verwaltung/KitaInformationenView.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            public: true
        }
    },
    {
        path: '/anmelden',
        name: 'Anmelden',
        component: anmelden,
        meta: {
            public: true
        }
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: ImpressumView,
        meta: {
            public: true
        }
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        component: DatenschutzView,
        meta: {
            public: true
        }
    },
    {
        path: '/kontakt',
        name: 'kontakt',
        beforeEnter() {
            window.open("https://www.petit-menu.de/#kontakt", '_blank')
        }
    },
    {
        path: '/auswertung-essensbestellungen',
        name: 'auswertungessensbestellung',
        component: AuswertungEssensbestellung,
        meta: {
            rollen: [
                "kueche",
                "mitarbeiter"
            ]
        }
    },
    {
        path: '/essenseingabe',
        name: 'essenseingabe',
        component: EssensEingabeView,
        meta: {
            rollen: [
                "mitarbeiter",
                "kita"
            ]
        }
    },
    {
        path: '/bestellvorlage',
        name: 'bestellvorlage',
        component: BestellvorlageView,
        meta: {
            rollen: [
                "mitarbeiter",
                "kita"
            ]
        }
    },
    {
        path: '/bestellungpausieren',
        name: 'bestellungpausieren',
        component: BestellungPausierenView,
        meta: {
            rollen: [
                "mitarbeiter",
                "kita",
                "kueche"
            ]
        }
    },
    {
        path: '/allergenkennzeichnungen',
        name: 'allergenkennzeichnungen',
        component: AllergenkennzeichnungenView,
        meta: {
            rollen: [
                "mitarbeiter",
                "kita",
                "kueche"
            ]
        }
    },
    {
        path: '/einrichtungen',
        name: 'einrichtungen',
        component: KitaVerwaltungView,
        meta: {
            rollen: [
                "mitarbeiter",
                "kueche"
            ]
        }
    },
    {
        path: '/monatsuebersicht',
        name: 'monatsuebersicht',
        component: Monatsuebersicht,
        meta: {
            rollen: [
                "mitarbeiter", "kita",
            ]
        }
    },
    {
        path: '/informationen',
        name: 'informationen',
        component: KitaInformationenView,
        meta: {
            rollen: [
                "mitarbeiter",
            ]
        }
    },
    {
        path: '/essensarten',
        name: 'essensarten',
        component: essensartenView,
        meta: {
            rollen: [
                "mitarbeiter", "kueche"
            ]
        }
    },
    {
        path: '/historieneintraege',
        name: 'historieneintraege',
        component: HistorienEintraegeView,
        meta: {
            rollen: [
                "mitarbeiter", "kueche",
            ]
        }
    },
    {
        path: '/konfiguration',
        name: 'konfiguration',
        component: KonfigurationView,
        meta: {
            rollen: [
                "mitarbeiter",
            ]
        }
    },
    {
        path: '/speiseplan',
        name: 'speiseplan',
        component: SpeiseplanView,
        meta: {
            public: true
        }
    },
    {
        path: '/benutzerverwaltung',
        name: 'benutzerverwaltung',
        beforeEnter() {
            const url = process.env.VUE_APP_OIDC_BENUTZERVERWALTUNG_URL
            console.log(url)
            window.open(url, '_blank')
        },
        meta: {
            rollen: [
                "benutzerverwaltung",
            ]
        }
    },

    {
        path: '/initialisiere',
        name: 'initialisiere',
        component: InitialisiereView,
        meta: {
            public: true
        },
        props: true
    },
    {
        path: '/oidc-callback', // Needs to match redirectUri (redirect_uri if you use snake case) in you oidcSettings
        name: 'oidcCallback',
        component: OidcCallback,
        meta: {
            public: true
        }
    },
    {
        path: '/zugriff-verweigert',
        name: 'zugriffVerweigert',
        component: ZugriffVerweigertView,
        meta: {
            public: true
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (!to.meta!.rollen) {
        next()
    } else {
        if (benutzerDatenSindGeladen()) {
            if (!nutzerIstBerechtigt(to) || kitaBenutzerHatNochKeineKita()) {
                next({name: 'zugriffVerweigert'});
            } else {
                next();
            }
        } else {
            next({name: 'initialisiere', query: {red: to.fullPath}});
        }
    }
})


function benutzerDatenSindGeladen() {
    return store.state.benutzer && Object.keys(store.state.benutzer).length
}

function kitaBenutzerHatNochKeineKita() {
    if (store.getters.oidcUser.roles.includes('kita')) {
        return store.state.benutzer.rolle === 'kita' && !store.state.benutzer.kita
    }
    return false
}

function nutzerIstBerechtigt(to: Route) {
    if (!to.meta!.rollen) {
        return to
    }
    const routenRollen: string[] = to.meta!.rollen
    const benutzerRollen: string[] = store.getters.oidcUser.roles
    if (benutzerRollen) {
        return routenRollen.some(routenRolle => benutzerRollen.includes(routenRolle))
    } else {
        return false
    }

}

export default router
