/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuswertungsreihenfolgeUpdateVO } from '../model';
// @ts-ignore
import { BenutzerVO } from '../model';
// @ts-ignore
import { KitaVO } from '../model';
// @ts-ignore
import { KitassuchergebnisVO } from '../model';
// @ts-ignore
import { NeueKitaVO } from '../model';
/**
 * KitasApi - axios parameter creator
 * @export
 */
export const KitasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ändert eine vorhandene Kita
         * @param {KitaVO} kitaVO Struktur der zu änderden Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kitaAendern: async (kitaVO: KitaVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitaVO' is not null or undefined
            assertParamExists('kitaAendern', 'kitaVO', kitaVO)
            const localVarPath = `/kitas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kitaVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lege eine neue Kita an
         * @param {NeueKitaVO} neueKitaVO Struktur der neuen Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kitaAnlegen: async (neueKitaVO: NeueKitaVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'neueKitaVO' is not null or undefined
            assertParamExists('kitaAnlegen', 'neueKitaVO', neueKitaVO)
            const localVarPath = `/kitas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neueKitaVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Zeigt eine Kita zur einer ID
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kitaAnzeigen: async (kitaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitaId' is not null or undefined
            assertParamExists('kitaAnzeigen', 'kitaId', kitaId)
            const localVarPath = `/kitas/{kitaId}`
                .replace(`{${"kitaId"}}`, encodeURIComponent(String(kitaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Such alle Kitas nach spezifizierten kriterien
         * @param {number} [anzahl] Anzahl der Elemente die maximal zurückgegeben werden sollen. Wenn anzahl &lt;&#x3D; 0 werden alle Ergebnisse geliefert die es gibt 
         * @param {number} [seite] Ermöglicht eine Pagination. Startet bei 0 
         * @param {'aktiv' | 'inaktiv'} [status] Optionale Filtermöglichkeit nach status 
         * @param {string} [suchbegriff] Optionale Filtermöglichkeit. Sucht nach Kitas mit Namen die den suchbegriff beinhalten 
         * @param {'name' | 'status'} [sortiertNach] Sortiert die Ergebnisse nach Namen oder Status 
         * @param {'asc' | 'desc'} [sortierReihenfolge] Gibt die Sortierreihenfolge der Ergebnisse an. Funktioniert nur in Kombination mit dem Parameter sortiertNach 
         * @param {boolean} [nurMeta] Ist dieser Wert auf true gesetzt werden nur die IDs und Namen der Kitas geladen. Diese Funktion dient vorallem dann wenn nur die wichtigsten Daten der Kita benoetigt werden 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kitasSuchen: async (anzahl?: number, seite?: number, status?: 'aktiv' | 'inaktiv', suchbegriff?: string, sortiertNach?: 'name' | 'status', sortierReihenfolge?: 'asc' | 'desc', nurMeta?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/kitas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (anzahl !== undefined) {
                localVarQueryParameter['anzahl'] = anzahl;
            }

            if (seite !== undefined) {
                localVarQueryParameter['seite'] = seite;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (suchbegriff !== undefined) {
                localVarQueryParameter['suchbegriff'] = suchbegriff;
            }

            if (sortiertNach !== undefined) {
                localVarQueryParameter['sortiertNach'] = sortiertNach;
            }

            if (sortierReihenfolge !== undefined) {
                localVarQueryParameter['sortierReihenfolge'] = sortierReihenfolge;
            }

            if (nurMeta !== undefined) {
                localVarQueryParameter['nurMeta'] = nurMeta;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Laedt die Benutzer zu einer Kita
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeBenutzerZurKita: async (kitaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitaId' is not null or undefined
            assertParamExists('ladeBenutzerZurKita', 'kitaId', kitaId)
            const localVarPath = `/kitas/{kitaId}/benutzer`
                .replace(`{${"kitaId"}}`, encodeURIComponent(String(kitaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatet den Index der Kitareihenfolge
         * @param {AuswertungsreihenfolgeUpdateVO} auswertungsreihenfolgeUpdateVO Struktur der zu änderden Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKitaAuswertungsIndex: async (auswertungsreihenfolgeUpdateVO: AuswertungsreihenfolgeUpdateVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'auswertungsreihenfolgeUpdateVO' is not null or undefined
            assertParamExists('updateKitaAuswertungsIndex', 'auswertungsreihenfolgeUpdateVO', auswertungsreihenfolgeUpdateVO)
            const localVarPath = `/auswertungen/essensbestellungen/kitareihenfolge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(auswertungsreihenfolgeUpdateVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KitasApi - functional programming interface
 * @export
 */
export const KitasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KitasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ändert eine vorhandene Kita
         * @param {KitaVO} kitaVO Struktur der zu änderden Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kitaAendern(kitaVO: KitaVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitaVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kitaAendern(kitaVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lege eine neue Kita an
         * @param {NeueKitaVO} neueKitaVO Struktur der neuen Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kitaAnlegen(neueKitaVO: NeueKitaVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitaVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kitaAnlegen(neueKitaVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Zeigt eine Kita zur einer ID
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kitaAnzeigen(kitaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitaVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kitaAnzeigen(kitaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Such alle Kitas nach spezifizierten kriterien
         * @param {number} [anzahl] Anzahl der Elemente die maximal zurückgegeben werden sollen. Wenn anzahl &lt;&#x3D; 0 werden alle Ergebnisse geliefert die es gibt 
         * @param {number} [seite] Ermöglicht eine Pagination. Startet bei 0 
         * @param {'aktiv' | 'inaktiv'} [status] Optionale Filtermöglichkeit nach status 
         * @param {string} [suchbegriff] Optionale Filtermöglichkeit. Sucht nach Kitas mit Namen die den suchbegriff beinhalten 
         * @param {'name' | 'status'} [sortiertNach] Sortiert die Ergebnisse nach Namen oder Status 
         * @param {'asc' | 'desc'} [sortierReihenfolge] Gibt die Sortierreihenfolge der Ergebnisse an. Funktioniert nur in Kombination mit dem Parameter sortiertNach 
         * @param {boolean} [nurMeta] Ist dieser Wert auf true gesetzt werden nur die IDs und Namen der Kitas geladen. Diese Funktion dient vorallem dann wenn nur die wichtigsten Daten der Kita benoetigt werden 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kitasSuchen(anzahl?: number, seite?: number, status?: 'aktiv' | 'inaktiv', suchbegriff?: string, sortiertNach?: 'name' | 'status', sortierReihenfolge?: 'asc' | 'desc', nurMeta?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KitassuchergebnisVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kitasSuchen(anzahl, seite, status, suchbegriff, sortiertNach, sortierReihenfolge, nurMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Laedt die Benutzer zu einer Kita
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeBenutzerZurKita(kitaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BenutzerVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeBenutzerZurKita(kitaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updatet den Index der Kitareihenfolge
         * @param {AuswertungsreihenfolgeUpdateVO} auswertungsreihenfolgeUpdateVO Struktur der zu änderden Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateKitaAuswertungsIndex(auswertungsreihenfolgeUpdateVO: AuswertungsreihenfolgeUpdateVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateKitaAuswertungsIndex(auswertungsreihenfolgeUpdateVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KitasApi - factory interface
 * @export
 */
export const KitasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KitasApiFp(configuration)
    return {
        /**
         * 
         * @summary Ändert eine vorhandene Kita
         * @param {KitaVO} kitaVO Struktur der zu änderden Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kitaAendern(kitaVO: KitaVO, options?: any): AxiosPromise<KitaVO> {
            return localVarFp.kitaAendern(kitaVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lege eine neue Kita an
         * @param {NeueKitaVO} neueKitaVO Struktur der neuen Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kitaAnlegen(neueKitaVO: NeueKitaVO, options?: any): AxiosPromise<KitaVO> {
            return localVarFp.kitaAnlegen(neueKitaVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Zeigt eine Kita zur einer ID
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kitaAnzeigen(kitaId: string, options?: any): AxiosPromise<KitaVO> {
            return localVarFp.kitaAnzeigen(kitaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Such alle Kitas nach spezifizierten kriterien
         * @param {number} [anzahl] Anzahl der Elemente die maximal zurückgegeben werden sollen. Wenn anzahl &lt;&#x3D; 0 werden alle Ergebnisse geliefert die es gibt 
         * @param {number} [seite] Ermöglicht eine Pagination. Startet bei 0 
         * @param {'aktiv' | 'inaktiv'} [status] Optionale Filtermöglichkeit nach status 
         * @param {string} [suchbegriff] Optionale Filtermöglichkeit. Sucht nach Kitas mit Namen die den suchbegriff beinhalten 
         * @param {'name' | 'status'} [sortiertNach] Sortiert die Ergebnisse nach Namen oder Status 
         * @param {'asc' | 'desc'} [sortierReihenfolge] Gibt die Sortierreihenfolge der Ergebnisse an. Funktioniert nur in Kombination mit dem Parameter sortiertNach 
         * @param {boolean} [nurMeta] Ist dieser Wert auf true gesetzt werden nur die IDs und Namen der Kitas geladen. Diese Funktion dient vorallem dann wenn nur die wichtigsten Daten der Kita benoetigt werden 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kitasSuchen(anzahl?: number, seite?: number, status?: 'aktiv' | 'inaktiv', suchbegriff?: string, sortiertNach?: 'name' | 'status', sortierReihenfolge?: 'asc' | 'desc', nurMeta?: boolean, options?: any): AxiosPromise<KitassuchergebnisVO> {
            return localVarFp.kitasSuchen(anzahl, seite, status, suchbegriff, sortiertNach, sortierReihenfolge, nurMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Laedt die Benutzer zu einer Kita
         * @param {string} kitaId Die ID der Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeBenutzerZurKita(kitaId: string, options?: any): AxiosPromise<Array<BenutzerVO>> {
            return localVarFp.ladeBenutzerZurKita(kitaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updatet den Index der Kitareihenfolge
         * @param {AuswertungsreihenfolgeUpdateVO} auswertungsreihenfolgeUpdateVO Struktur der zu änderden Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateKitaAuswertungsIndex(auswertungsreihenfolgeUpdateVO: AuswertungsreihenfolgeUpdateVO, options?: any): AxiosPromise<void> {
            return localVarFp.updateKitaAuswertungsIndex(auswertungsreihenfolgeUpdateVO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KitasApi - object-oriented interface
 * @export
 * @class KitasApi
 * @extends {BaseAPI}
 */
export class KitasApi extends BaseAPI {
    /**
     * 
     * @summary Ändert eine vorhandene Kita
     * @param {KitaVO} kitaVO Struktur der zu änderden Kita
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitasApi
     */
    public kitaAendern(kitaVO: KitaVO, options?: any) {
        return KitasApiFp(this.configuration).kitaAendern(kitaVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lege eine neue Kita an
     * @param {NeueKitaVO} neueKitaVO Struktur der neuen Kita
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitasApi
     */
    public kitaAnlegen(neueKitaVO: NeueKitaVO, options?: any) {
        return KitasApiFp(this.configuration).kitaAnlegen(neueKitaVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Zeigt eine Kita zur einer ID
     * @param {string} kitaId Die ID der Kita
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitasApi
     */
    public kitaAnzeigen(kitaId: string, options?: any) {
        return KitasApiFp(this.configuration).kitaAnzeigen(kitaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Such alle Kitas nach spezifizierten kriterien
     * @param {number} [anzahl] Anzahl der Elemente die maximal zurückgegeben werden sollen. Wenn anzahl &lt;&#x3D; 0 werden alle Ergebnisse geliefert die es gibt 
     * @param {number} [seite] Ermöglicht eine Pagination. Startet bei 0 
     * @param {'aktiv' | 'inaktiv'} [status] Optionale Filtermöglichkeit nach status 
     * @param {string} [suchbegriff] Optionale Filtermöglichkeit. Sucht nach Kitas mit Namen die den suchbegriff beinhalten 
     * @param {'name' | 'status'} [sortiertNach] Sortiert die Ergebnisse nach Namen oder Status 
     * @param {'asc' | 'desc'} [sortierReihenfolge] Gibt die Sortierreihenfolge der Ergebnisse an. Funktioniert nur in Kombination mit dem Parameter sortiertNach 
     * @param {boolean} [nurMeta] Ist dieser Wert auf true gesetzt werden nur die IDs und Namen der Kitas geladen. Diese Funktion dient vorallem dann wenn nur die wichtigsten Daten der Kita benoetigt werden 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitasApi
     */
    public kitasSuchen(anzahl?: number, seite?: number, status?: 'aktiv' | 'inaktiv', suchbegriff?: string, sortiertNach?: 'name' | 'status', sortierReihenfolge?: 'asc' | 'desc', nurMeta?: boolean, options?: any) {
        return KitasApiFp(this.configuration).kitasSuchen(anzahl, seite, status, suchbegriff, sortiertNach, sortierReihenfolge, nurMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Laedt die Benutzer zu einer Kita
     * @param {string} kitaId Die ID der Kita
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitasApi
     */
    public ladeBenutzerZurKita(kitaId: string, options?: any) {
        return KitasApiFp(this.configuration).ladeBenutzerZurKita(kitaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updatet den Index der Kitareihenfolge
     * @param {AuswertungsreihenfolgeUpdateVO} auswertungsreihenfolgeUpdateVO Struktur der zu änderden Kita
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KitasApi
     */
    public updateKitaAuswertungsIndex(auswertungsreihenfolgeUpdateVO: AuswertungsreihenfolgeUpdateVO, options?: any) {
        return KitasApiFp(this.configuration).updateKitaAuswertungsIndex(auswertungsreihenfolgeUpdateVO, options).then((request) => request(this.axios, this.basePath));
    }
}
