/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BestellungspauseVO } from '../model';
// @ts-ignore
import { BestellungspausenuebersichtVO } from '../model';
// @ts-ignore
import { NeueBestellungspauseVO } from '../model';
/**
 * BestellungspauseApi - axios parameter creator
 * @export
 */
export const BestellungspauseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary legt neue Bestellungspausen für einen Zeitraum an. Zu jedem Tag in dem Zeitraum wird eine Bestellungspause angelegt
         * @param {NeueBestellungspauseVO} neueBestellungspauseVO Zeitraum der Bestellungspause für eine Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erstelleBestellungspause: async (neueBestellungspauseVO: NeueBestellungspauseVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'neueBestellungspauseVO' is not null or undefined
            assertParamExists('erstelleBestellungspause', 'neueBestellungspauseVO', neueBestellungspauseVO)
            const localVarPath = `/bestellungspausen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neueBestellungspauseVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lädt die Bestellungspausen für einen Monat
         * @param {number} jahr 
         * @param {string} [kitaId] Die ID der Kita für die die Bestellungspausen geladen werden sollen. Wenn nicht gefuellt werden zu allen Kitas die Pausen geladen 
         * @param {number} [monat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeBestellungspausen: async (jahr: number, kitaId?: string, monat?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jahr' is not null or undefined
            assertParamExists('ladeBestellungspausen', 'jahr', jahr)
            const localVarPath = `/bestellungspausen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kitaId !== undefined) {
                localVarQueryParameter['kitaId'] = kitaId;
            }

            if (monat !== undefined) {
                localVarQueryParameter['monat'] = monat;
            }

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loescht eine Bestellungspause
         * @param {string} bestellungspauseID ID der Bestellungspause die geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loescheBestellungspausen: async (bestellungspauseID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bestellungspauseID' is not null or undefined
            assertParamExists('loescheBestellungspausen', 'bestellungspauseID', bestellungspauseID)
            const localVarPath = `/bestellungspausen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (bestellungspauseID !== undefined) {
                localVarQueryParameter['bestellungspauseID'] = bestellungspauseID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BestellungspauseApi - functional programming interface
 * @export
 */
export const BestellungspauseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BestellungspauseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary legt neue Bestellungspausen für einen Zeitraum an. Zu jedem Tag in dem Zeitraum wird eine Bestellungspause angelegt
         * @param {NeueBestellungspauseVO} neueBestellungspauseVO Zeitraum der Bestellungspause für eine Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erstelleBestellungspause(neueBestellungspauseVO: NeueBestellungspauseVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BestellungspauseVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erstelleBestellungspause(neueBestellungspauseVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lädt die Bestellungspausen für einen Monat
         * @param {number} jahr 
         * @param {string} [kitaId] Die ID der Kita für die die Bestellungspausen geladen werden sollen. Wenn nicht gefuellt werden zu allen Kitas die Pausen geladen 
         * @param {number} [monat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeBestellungspausen(jahr: number, kitaId?: string, monat?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BestellungspausenuebersichtVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeBestellungspausen(jahr, kitaId, monat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary loescht eine Bestellungspause
         * @param {string} bestellungspauseID ID der Bestellungspause die geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loescheBestellungspausen(bestellungspauseID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loescheBestellungspausen(bestellungspauseID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BestellungspauseApi - factory interface
 * @export
 */
export const BestellungspauseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BestellungspauseApiFp(configuration)
    return {
        /**
         * 
         * @summary legt neue Bestellungspausen für einen Zeitraum an. Zu jedem Tag in dem Zeitraum wird eine Bestellungspause angelegt
         * @param {NeueBestellungspauseVO} neueBestellungspauseVO Zeitraum der Bestellungspause für eine Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erstelleBestellungspause(neueBestellungspauseVO: NeueBestellungspauseVO, options?: any): AxiosPromise<Array<BestellungspauseVO>> {
            return localVarFp.erstelleBestellungspause(neueBestellungspauseVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lädt die Bestellungspausen für einen Monat
         * @param {number} jahr 
         * @param {string} [kitaId] Die ID der Kita für die die Bestellungspausen geladen werden sollen. Wenn nicht gefuellt werden zu allen Kitas die Pausen geladen 
         * @param {number} [monat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeBestellungspausen(jahr: number, kitaId?: string, monat?: number, options?: any): AxiosPromise<BestellungspausenuebersichtVO> {
            return localVarFp.ladeBestellungspausen(jahr, kitaId, monat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary loescht eine Bestellungspause
         * @param {string} bestellungspauseID ID der Bestellungspause die geloescht werden soll
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loescheBestellungspausen(bestellungspauseID: string, options?: any): AxiosPromise<void> {
            return localVarFp.loescheBestellungspausen(bestellungspauseID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BestellungspauseApi - object-oriented interface
 * @export
 * @class BestellungspauseApi
 * @extends {BaseAPI}
 */
export class BestellungspauseApi extends BaseAPI {
    /**
     * 
     * @summary legt neue Bestellungspausen für einen Zeitraum an. Zu jedem Tag in dem Zeitraum wird eine Bestellungspause angelegt
     * @param {NeueBestellungspauseVO} neueBestellungspauseVO Zeitraum der Bestellungspause für eine Kita
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestellungspauseApi
     */
    public erstelleBestellungspause(neueBestellungspauseVO: NeueBestellungspauseVO, options?: any) {
        return BestellungspauseApiFp(this.configuration).erstelleBestellungspause(neueBestellungspauseVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lädt die Bestellungspausen für einen Monat
     * @param {number} jahr 
     * @param {string} [kitaId] Die ID der Kita für die die Bestellungspausen geladen werden sollen. Wenn nicht gefuellt werden zu allen Kitas die Pausen geladen 
     * @param {number} [monat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestellungspauseApi
     */
    public ladeBestellungspausen(jahr: number, kitaId?: string, monat?: number, options?: any) {
        return BestellungspauseApiFp(this.configuration).ladeBestellungspausen(jahr, kitaId, monat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary loescht eine Bestellungspause
     * @param {string} bestellungspauseID ID der Bestellungspause die geloescht werden soll
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BestellungspauseApi
     */
    public loescheBestellungspausen(bestellungspauseID: string, options?: any) {
        return BestellungspauseApiFp(this.configuration).loescheBestellungspausen(bestellungspauseID, options).then((request) => request(this.axios, this.basePath));
    }
}
