<template>
  <v-container>
    <v-row class="px-3">
      <v-col
          sm="7"
          cols="12"
      >
        <h2>Allergenkennzeichnungen</h2>
      </v-col>
      <v-col
          sm="5"
          cols="12"
          class="text-sm-right"
          align-self="center"
      >
        <neue-allergenkennzeichnung-anlegen v-if="hatRolleMitarbeiter || hatRolleKueche" @neueAllergenkennzeichnung="getDataFromApi"/>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col
          cols="12"
      >
        <v-text-field
            outlined
            dense
            v-model="suche"
            append-icon="mdi-magnify"
            label="Suche"
            single-line
            hide-details
        />
      </v-col>
      <v-col
          cols="12"
      >
      </v-col>
    </v-row>
    <v-data-table
        class="px-3"
        loading-text="Allergenkennzeichnungen werden geladen"
        no-data-text="Keine Allergenkennzeichnungen gefunden"
        :headers="headers"
        :search="suche"
        :items="allergenkennzeichnungen"
        :loading="loading"
        dense
        :header-props="{
        'sort-by-text':'Sortieren nach'
      }"
        :footer-props="{
        'items-per-page-text':'Elemente pro Seite',
        'items-per-page-all-text':'Alle',
      }"
    >
      <template #[`footer.page-text`]="props">
        {{ props.pageStart }}-{{ props.pageStop }} von {{ props.itemsLength }}
      </template>
      <template v-if="hatRolleMitarbeiter || hatRolleKueche" #[`item.actions`]="{ item }">
        <allergenkennzeichnung-aktualisieren
            :allergenkennzeichnung="item"
            @allergenkennzeichnungGespeichert="updateAllergenkennzeichnung"
        />
        <allergenkennzeichnung-loeschen
            :allergenkennzeichnungName="item.name"
            @allergenkennzeichnungGeloescht="getDataFromApi"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import NeueAllergenkennzeichnungAnlegen from "@/components/essenbestellen/allergenkennzeichnung/NeueAllergenkennzeichnungAnlegen";
import AllergenkennzeichnungAktualisieren from "@/components/essenbestellen/allergenkennzeichnung/AllergenkennzeichnungAktualisieren.vue";
import AllergenkennzeichnungLoeschen from "@/components/essenbestellen/allergenkennzeichnung/AllergenkennzeichnungLoeschen.vue";
import Vue from "vue";
import {allergenkennzeichnungenservice} from "@/service";
import {mapGetters} from "vuex";

export default {
  name: 'AllergenkennzeichnungenUebersicht',
  components: {NeueAllergenkennzeichnungAnlegen, AllergenkennzeichnungAktualisieren, AllergenkennzeichnungLoeschen},
  data() {
    return {
      anzahlAllergenkennzeichnungen: 0,
      allergenkennzeichnungen: [],
      loading: true,
      options: {},
      suche: '',
      warteAufSuche: false,
      headers: [

        {
          text: 'Allergenkennzeichnung',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Allergene',
          align: 'start',
          sortable: true,
          value: 'allergene'
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end',
          width: '150px'
        },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    suche() {
      this.loading = true
      if (!this.warteAufSuche) {
        setTimeout(() => {
          this.getDataFromApi()
          this.warteAufSuche = false
        }, 500)
        this.warteAufSuche = true
      }
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  computed: {
    ...mapGetters(['hatRolleMitarbeiter', 'hatRolleKueche'])
  },
  methods: {
    updateAllergenkennzeichnung(value) {
      Vue.set(this.allergenkennzeichnungen, this.allergenkennzeichnungen.findIndex(f => f.name === value.name), value)
    },
    getDataFromApi() {
      this.loading = true
      allergenkennzeichnungenservice.ladeAllergenkennzeichnungen()
          .then(response => {
            this.allergenkennzeichnungen = response.data
            this.loading = false
          }).catch(error => {
        console.log(error.response)
      }).finally(

      )
    }
  },
}
</script>

<style scoped>

</style>