import { render, staticRenderFns } from "./EssensBestellungHistorie.vue?vue&type=template&id=134fda90&scoped=true&"
import script from "./EssensBestellungHistorie.vue?vue&type=script&lang=js&"
export * from "./EssensBestellungHistorie.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "134fda90",
  null
  
)

export default component.exports