export class NachrichtVO {
    /**
     *
     * @type {string}
     * @memberof KitaVO
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof KitaVO
     */
    typ: string;

    constructor(text: string, typ: string) {
        this.text = text;
        this.typ = typ;
    }
}