<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pa-2">
        <v-form>
          <v-container>

            <v-row>
              <v-col cols="12" sm="6">
                <h2>Essensbestellungen</h2>
              </v-col>
              <v-col cols="12" sm="6">
                <kita-auswahl-component v-model="ausgewaehlteKita"></kita-auswahl-component>
              </v-col>
            </v-row>
            <v-row justify="center" align-content="center">
              <v-col cols="12" sm="5" md="5" class="pa-1">
                <essens-datum-auswahl-component
                    :key="ausgewaehlteKita"
                    :kita=ausgewaehlteKita
                    @change="datum = $event"
                    @change-betrachteter-monate="betrachteterMonat=$event"
                    @bestellungstypGeandert="handleBestellungstypGeandert($event)"
                    ref="datumAuswahlComponent"
                >
                </essens-datum-auswahl-component>
              </v-col>
              <v-col cols="12" sm="7" md="7" class="pa-1">
                <essens-bestellung-component
                    v-if="bestellungstyp ==='Einzel' || bestellungstyp ==='Zeitraum'"
                    :bestellungszeitraum="datum"
                    :kita="ausgewaehlteKita"
                    @change="refreshBestellungInDatumsAuswahl"
                ></essens-bestellung-component>
                <essens-bestellung-kopieren-component
                    v-else-if="bestellungstyp === wocheKopieren && this.datum.length>0"
                    :kita="ausgewaehlteKita"
                    :woche="datum"
                    @erfolgreichGespeichert="refreshBestellungInDatumsAuswahl"
                >
                </essens-bestellung-kopieren-component>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import EssensDatumAuswahlComponent from "@/components/essenbestellen/bestellung/EssensDatumAuswahlComponent.vue";
import KitaAuswahlComponent from "@/components/kita/KitaAuswahlComponent.vue";
import EssensBestellungComponent from "@/components/essenbestellen/bestellung/EssensBestellungComponent.vue";
import {BestellungsTypEnum} from "@/model/BestellungsTypEnum";
import EssensBestellungKopierenComponent from "@/components/essenbestellen/bestellung/wocheKopieren/EssensBestellungKopierenComponent";

export default Vue.extend({
  name: 'EssensEingabeView',
  components: {EssensBestellungKopierenComponent, EssensBestellungComponent, KitaAuswahlComponent, EssensDatumAuswahlComponent},
  data: () => ({
    ausgewaehlteKita: null,
    datum: [],
    bestellungstyp: null,
  }),
  computed: {
    wocheKopieren() {
      return BestellungsTypEnum.WocheKopieren
    }
  },
  methods: {
    refreshBestellungInDatumsAuswahl() {
      this.$refs.datumAuswahlComponent.refreshBestellungen()
    },
    handleBestellungstypGeandert(event) {
      this.bestellungstyp = event;
    }
  },

})
</script>
