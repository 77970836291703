<template>
  <v-text-field
      v-model="telefonnummer"
      :maxlength="30"
      :value="telefonnummer"
      counter="30"
      dense
      label="Telefonnummer"
      outlined
      @input="handleInput"
  />
</template>

<script>
export default {
  name: "KitaTelefonnummerComponent",
  props: ['value'],
  data() {
    return {
      telefonnummer: '',
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.telefonnummer = newValue
      }
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.telefonnummer)
    },
  }
}
</script>

<style scoped>

</style>