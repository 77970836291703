<template>
  <v-dialog
      :key="kitabearbeitenkey"
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          @click="ladeKitaDaten"
          icon
      >
        <v-icon>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <v-card rounded="lg" :loading="this.ladeKita" :disabled="this.ladeKita">
      <v-alert
          v-if="fehler"
          class="ma-0"
          color="error"
          type="error"
      >{{ fehler }}
      </v-alert>
      <v-card-title>
        <span class="headline">Einrichtung {{ kita.name }} bearbeiten</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col
                cols="8"
                md="9"
                sm="9"
            >
              <kita-name-component
                  ref="kitanamecomponent"
                  v-model="kitaVO.name"
              />
            </v-col>
            <v-col
                cols="4"
                md="3"
                sm="3"
            >
              <v-switch
                  v-model="kitaVO.status"
                  :label="kitaVO.status"
                  class="mt-0"
                  false-value="inaktiv"
                  true-value="aktiv"
              />
            </v-col>
            <v-col
                cols="12"
            >
              <kita-geschaeftsname-component
                  v-model="kitaVO.geschaeftsname"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
                cols="12"
            >
              <kita-ansprechpartner-component
                  v-model="kitaVO.ansprechpartner"
              />
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <kita-kundennummer-component
                  v-model="kitaVO.kundenummer"
              />
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
              <kita-telefonnummer-component
                  v-model="kitaVO.telefonnummer"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
            >
              <kita-sonderwunsch-component
                  v-model="kitaVO.sonderwunsch"
              />
            </v-col>
            <v-col cols="12">
              <benutzerzuweisen-component v-model="kitaVO.benutzer">
              </benutzerzuweisen-component>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <kita-mit-dessert-component
                  v-model="kitaVO.mitDessert"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <liefertag-auswahl-component
                  :kitaliefertage="kitaVO.liefertage"
                  @change="kitaVO.liefertage = $event"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>*Pflichtfelder</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            @click="leereKomponente"
        >
          Abbrechen
        </v-btn>
        <v-btn
            :disabled="kitaVO.name.trim().length === 0"
            :loading="loading"
            color="primary"
            depressed
            @click="speichereKita"
            v-if="hatRolleMitarbeiter"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KitaSonderwunschComponent from "@/components/verwaltung/kitaverwaltung/components/KitaSonderwunschComponent.vue";
import KitaNameComponent from "@/components/verwaltung/kitaverwaltung/components/KitaNameComponent.vue";
import KitaMitDessertComponent from "@/components/verwaltung/kitaverwaltung/components/KitaMitDessertComponent.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import LiefertagAuswahlComponent from "@/components/verwaltung/kitaverwaltung/components/LiefertagAuswahlComponent";
import {kitaservice} from "@/service";
import KitaGeschaeftsnameComponent from "@/components/verwaltung/kitaverwaltung/components/KitaGeschaeftsnameComponent";
import KitaKundennummerComponent from "@/components/verwaltung/kitaverwaltung/components/KitaKundennummerComponent";
import KitaTelefonnummerComponent from "@/components/verwaltung/kitaverwaltung/components/KitaTelefonnummerComponent";
import KitaAnsprechpartnerComponent from "@/components/verwaltung/kitaverwaltung/components/KitaAnsprechpartnerComponent";
import BenutzerzuweisenComponent from "@/components/verwaltung/kitaverwaltung/components/BenutzerZuweisenComponent";

export default {
  name: "KitaBearbeitenComponent",
  components: {
    BenutzerzuweisenComponent,
    KitaAnsprechpartnerComponent,
    KitaTelefonnummerComponent,
    KitaKundennummerComponent, KitaGeschaeftsnameComponent, LiefertagAuswahlComponent, KitaSonderwunschComponent, KitaNameComponent, KitaMitDessertComponent
  },
  props: ['kita'],
  data() {
    return {
      dialog: false,
      ladeKita: true,
      fehler: null,
      kitaVO: Object.assign({}, this.kita),
      loading: false,
      kitabearbeitenkey: 0,
      validierung: {
        nameNochNichtVergeben: true
      },
    }
  },
  computed: {
    ...mapGetters(['hatRolleMitarbeiter'])
  },
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    ...mapActions(['refreshKitas']),
    leereKomponente() {
      this.dialog = false
      this.kitaVO = Object.assign({}, this.kita)
      this.kitabearbeitenkey += 1
    },
    speichereKita() {
      this.loading = true
      this.fehler = null
      kitaservice.kitaAendern(this.kitaVO).then(
          response => {
            this.addErfolgsNachricht("Einrichtung erfolgreich gespeichert")
            this.leereKomponente()
            this.refreshKitas()
            this.$emit('kitaGespeichert', response.data)
          }
      ).catch(
          (error) => {
            if (error.response.data.code === '0100101') {
              this.$refs.kitanamecomponent.setNameBereitsVergeben()
            } else if (error.response.status === 403) {
              this.fehler = "Das Speichern einer Einrichtung ist nicht erlaubt"
            } else {
              this.fehler = error.response.data.message
            }
          }
      ).finally(
          () => {
            this.loading = false
          }
      )
    },
    ladeKitaDaten() {
      this.fehler = null
      this.ladeKita = true
      kitaservice.ladeBenutzerZurKita(this.kita.id).then(
          response => {
            this.kitaVO.benutzer = response.data
          }
      ).finally(() => this.ladeKita = false)
    },
  }
}
</script>

<style scoped>

</style>