<template>
  <v-col v-if="pruefeAufRollen" :md="breite" cols="12">
    <v-card :to="{name:zielkomponentenname}" class="start-card" hover min-height="150" outlined rounded>
      <v-card-title class="start-cardtitle accent--text">
        <v-icon color="accent" large left>
          {{ icon }}
        </v-icon>
        {{ titel }}
      </v-card-title>
      <v-card-text class="start-card-text primary--text">
        {{ text }}
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "StartCardComponent",
  props: ['titel', 'text', 'icon', 'zielkomponentenname', 'breite'],
  computed: {
    ...mapGetters(['oidcUser']),
    pruefeAufRollen() {
      if (this.zielkomponentenname) {
        let route = this.$router.getRoutes().find(route => route.name === this.zielkomponentenname);
        if (route.meta.rollen) {
          if (this.oidcUser.roles) {
            return route.meta.rollen.some(rolle => this.oidcUser.roles.some(it => it === rolle))
          } else {
            return false
          }
        } else {
          return true
        }
      } else {
        return true
      }
    }
  },
}
</script>

<style scoped>

.start-card {
  border-color: var(--v-primary-base);
}

.start-cardtitle {
  font-family: "Londrina Solid", Sans-serif;
  font-size: 30px;
}

.start-card-text {
  font-family: 'Titillium Web', Sans-serif !important;
  font-weight: 600;
  font-size: 16px;
}

.start-card {

}
</style>