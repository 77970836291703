<template>
  <v-row justify="center" align="center" dense>
    <v-col cols="9" class="text-right">
      <span class="text-button font-weight-bold">Summe</span>
    </v-col>
    <v-col cols="2" class="text-left">
      <span class="text-h6 ml-2">{{ summe }}</span>
    </v-col>
    <v-col cols="1">
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "EssensBestellungSummenZeileComponent",
  props: ['bestellung'],
  computed: {
    summe() {
      return this.bestellung.essensbestellpositionen
          .map(position => Number(position.anzahl||0))
          .reduce((sum, current) => sum + current, 0)
    },
  }
}
</script>

<style scoped>

</style>