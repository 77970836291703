<template>
  <v-text-field
      v-model="kitaname"
      :maxlength="100"
      :rules="[nameBereitsVergebenRule,nameNochLeerRule]"
      :value="kitaname"
      counter="100"
      dense
      label="Name*"
      outlined
      required
      @input="handleInput"
  />
</template>

<script>
export default {
  name: "KitaNameComponent",
  props: ['value'],
  data() {
    return {
      kitaname: '',
      validierung: {
        nameNochNichtVergeben: true
      },
    }
  },
  computed: {
    nameBereitsVergebenRule() {
      return !!this.validierung.nameNochNichtVergeben || 'Der Name ist bereits vergeben'
    },
    nameNochLeerRule() {
      return !!this.kitaname || 'Bitte einen Namen eingeben'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.kitaname = newValue
      }
    }
  },
  methods: {
    handleInput() {
      this.validierung.nameNochNichtVergeben = true
      this.$emit('input', this.kitaname)
    },
    setNameBereitsVergeben() {
      this.validierung.nameNochNichtVergeben = false
    }
  }
}
</script>

<style scoped>

</style>