<template>
  <v-slide-y-transition v-if="this.monat">
    <v-sheet>
      <v-calendar
          ref="calendar"
          :event-more=false
          :event-overlap-threshold="30"
          :events="events"
          :start="this.jahr + '-'+this.monat+'-'+'01'"
          :weekdays="weekday"
          locale="de-de"
          locale-first-day-of-year="4"
          show-week
          type="month"
      />
    </v-sheet>
  </v-slide-y-transition>
</template>

<script>
export default {
  name: "BestellungspausenKalendarComponent",
  props: ["bestellungspausenZeitraeume", "monat", "jahr"],
  data() {
    return {
      weekday: [1, 2, 3, 4, 5, 6, 0],
      events: []
    }
  },
  methods: {
    stringToColour(stringInput) {
      let stringUniqueHash = [...stringInput].reduce((acc, char) => {
        return char.charCodeAt(0) + ((acc << 5) - acc);
      }, 0);
      return `hsl(${stringUniqueHash % 360}, 75%, 35%)`;
    }
  },
  watch: {
    bestellungspausenZeitraeume(neu) {
      this.events = []
      if (neu) {
        neu.forEach(z => {
          this.events.push({
            name: z.kitaname,
            start: new Date(z.von),
            end: new Date(z.bis),
            color: this.stringToColour(z.kitaname),
            timed: false,
          })
        })
      }
    }
  }

}
</script>

<style scoped>

</style>