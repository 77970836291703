<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="8">
        <bestellvorlage-info-component/>
      </v-col>

      <v-col class="pa-2" cols="12" sm="4">
        <kita-auswahl-component v-model="ausgewaehlteKita"/>
      </v-col>
    </v-row>
    <v-row align-content="center" justify="center">
      <v-col class="pa-1" cols="12" lg="6" md="7" sm="8">
        <bestellvorlage-compoent :key="ausgewaehlteKita" :kita="ausgewaehlteKita"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import KitaAuswahlComponent from "@/components/kita/KitaAuswahlComponent";
import BestellvorlageCompoent from "@/components/essenbestellen/bestellvorlage/BestellvorlageCompoent";
import BestellvorlageInfoComponent from "@/components/essenbestellen/bestellvorlage/BestellvorlageInfoComponent";

export default {
  name: "BestellvorlageView",
  components: {BestellvorlageInfoComponent, BestellvorlageCompoent, KitaAuswahlComponent},
  data: () => ({
    ausgewaehlteKita: null,
  }),
}
</script>

<style scoped>

</style>