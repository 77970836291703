
import Vue from 'vue'
import NavigatiosInhaltComponent from "@/components/navigation/NavigationsInhaltComponent.vue";
import NachrichtComponent from "@/components/nachrichten/Nachricht.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {benutzerservice} from "@/service";
import InformationsAnzeigeBannerComponent from "@/components/verwaltung/kitainformationen/InformationsAnzeigeBannerComponent.vue";

export default Vue.extend({
  name: 'App',
  components: {InformationsAnzeigeBannerComponent, NachrichtComponent, NavigatiosInhaltComponent},
  data: () => ({
    drawer: false
  }),
  computed: {
    ...mapGetters(['oidcIsAuthenticated', 'hatRolleKita']),
    ...mapState(['benutzer']),
    istUmgebungTest() {
      return process.env.VUE_APP_SHOW_TEST_ICON === 'true'
    }
  },
  created() {
    if (!this.$vuetify.breakpoint.mobile) {
      this.drawer = true
    }
  },
  methods: {
    ...mapActions(['authenticateOidc', 'refreshEssensarten', 'refreshKitas']),
    ...mapMutations(['setzeBenutzer']),
    ladeEigenenBenutzer() {
      benutzerservice.benutzerMeGet().then(response => {
        this.setzeBenutzer(response.data)
      }).finally(() => {
        if (this.benutzer.rolle === 'kita' && !this.benutzer.kita) {
          this.$router.push({name: 'zugriffVerweigert'})
        }
      })
    }
  },
  beforeMount() {
    window.addEventListener('vuexoidc:automaticSilentRenewError', this.authenticateOidc)
  },
  watch: {
    oidcIsAuthenticated: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.ladeEigenenBenutzer()
          this.refreshEssensarten()
          if (!this.hatRolleKita) {
            this.refreshKitas()
          }
        }
      }
    }
  },
})
