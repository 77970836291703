<template>
  <v-autocomplete
      v-model="ausgewaehltertyp"
      :items="eintraege"
      auto-select-first
      clearable
      dense
      hide-details
      item-text="anzeigeName"
      item-value="name"
      label="Nach Typ filtern"
      outlined
      @change="$emit('input',$event)"

  />
</template>

<script>
import {eintragstypen} from "@/components/verwaltung/historie/HistorienEintragTypMapping";

export default {
  props: ['value'],
  name: "HistorienEintragTypFilterComponent",
  data() {
    return {
      ausgewaehltertyp: this.value
    }
  },
  watch: {
    ausgewaehltertyp(neuerWert) {
      this.$emit('input', neuerWert)
    },
  },
  computed: {
    eintraege() {
      return eintragstypen.sort((a, b) => a.anzeigeName.localeCompare(b.anzeigeName))
    }
  },

}
</script>

<style scoped>

</style>