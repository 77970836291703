
import Vue from 'vue'
import StartCardComponent from "@/components/start/StartCardComponent.vue";

export default Vue.extend({
  name: 'StartComponent',
  components: {StartCardComponent},
  data: () => ({
    karten: [
      {
        titel: "Essen bestellen",
        text: "Geben Sie ganz einfach eine Bestellung auf. Egal ob für diese Woche / nächste Woche oder den ganzen Monat.",
        icon: "mdi-food-apple",
        zielkomponentenname: "essenseingabe",
        breite: 6
      },
      {
        titel: "Bestellvorlage",
        text: "Über die Bestellvorlage wird für Sie automatisch immer eine Bestellung für den übernächsten Werktag angelegt.",
        icon: "mdi-text-box-check-outline",
        zielkomponentenname: "bestellvorlage",
        breite: 6
      },
      {
        titel: "Monatsübersicht",
        text: "Sie möchten Wissen was in den letzten Monaten bestellt wurde? Schauen Sie in die Monatsübersicht. Dort finden Sie alle Infos zu Ihren Bestellungen.",
        icon: "mdi-file-table",
        zielkomponentenname: "monatsuebersicht",
        breite: 6
      },

      {
        titel: "Essensauswertung",
        text: "Eine Übersicht aller Bestellungen die von den Einrichtungen zu einem Tag getätigt wurden.",
        icon: "mdi-order-bool-descending-variant",
        zielkomponentenname: "auswertungessensbestellung",
        breite: 6
      },
      {
        titel: "Speiseplan",
        text: "Hier finden Sie unseren aktuellen Speiseplan inkl. aller Allergene.",
        icon: "mdi-food-fork-drink",
        zielkomponentenname: "speiseplan",
        breite: 12
      }

    ],
  }),
})
