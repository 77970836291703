<template>
  <v-container>
    <v-row class="ma-2">
      <h2>Essensarten verwalten</h2>
      <v-spacer></v-spacer>
      <neue-essensart-anlegen @created="refreshEssensarten"/>
    </v-row>
    <v-row>
      <v-col>
        <essensarten-uebersicht @change="refreshEssensarten" @update="setzeEssensdetail"/>
      </v-col>

    </v-row>
    <v-row>
      <v-col>
        <essensart-detailansicht v-if="this.essensartDetail" :key="essensartDetail" v-model="this.essensartDetail"/>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import EssensartenUebersicht from "@/components/verwaltung/essensarten/EssensartenUebersicht";
import NeueEssensartAnlegen from "@/components/verwaltung/essensarten/NeueEssensartAnlegen";
import {mapActions} from "vuex";
import EssensartDetailansicht from "@/components/verwaltung/essensarten/EssensartDetailansicht";

export default {
  name: "EssensartenView",
  components: {EssensartDetailansicht, NeueEssensartAnlegen, EssensartenUebersicht},

  data: () => ({
    essensartDetail: null,
  }),
  methods: {
    ...mapActions(["refreshEssensarten"]),
    setzeEssensdetail(art) {
      this.essensartDetail = art
    }
  }
}
</script>

<style scoped>

</style>