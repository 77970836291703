/* tslint:disable */
/* eslint-disable */
/**
 * Kitasystem Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuswertungEssensbestellungenVO } from '../model';
// @ts-ignore
import { BestellungswocheKopierenRequestVO } from '../model';
// @ts-ignore
import { EssensbestellungMitPositionenVO } from '../model';
// @ts-ignore
import { EssensbestellungVO } from '../model';
// @ts-ignore
import { FeiertagVO } from '../model';
// @ts-ignore
import { NeueEssensbestellungVO } from '../model';
/**
 * EssensbestellungApi - axios parameter creator
 * @export
 */
export const EssensbestellungApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary erstelle zu einem Zeitraum neue Essensbestellungen
         * @param {NeueEssensbestellungVO} neueEssensbestellungVO Zeitraum der Bestellungspause für eine Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erstelleEssensbestellung: async (neueEssensbestellungVO: NeueEssensbestellungVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'neueEssensbestellungVO' is not null or undefined
            assertParamExists('erstelleEssensbestellung', 'neueEssensbestellungVO', neueEssensbestellungVO)
            const localVarPath = `/essensbestellungen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(neueEssensbestellungVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * kopiert eine Bestellungswoche auf mehrere Wochen
         * @param {BestellungswocheKopierenRequestVO} bestellungswocheKopierenRequestVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopiereBestellungsWoche: async (bestellungswocheKopierenRequestVO: BestellungswocheKopierenRequestVO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bestellungswocheKopierenRequestVO' is not null or undefined
            assertParamExists('kopiereBestellungsWoche', 'bestellungswocheKopierenRequestVO', bestellungswocheKopierenRequestVO)
            const localVarPath = `/essensbestellungen/woche/kopie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bestellungswocheKopierenRequestVO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lädt zu einem Tag die Essensbestellungen aller Kitas
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeAuswertungZuEssensbestellung: async (datum: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'datum' is not null or undefined
            assertParamExists('ladeAuswertungZuEssensbestellung', 'datum', datum)
            const localVarPath = `/auswertungen/essensbestellungen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (datum !== undefined) {
                localVarQueryParameter['datum'] = datum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lädt alle Informationen zu einer Essensbestlelung
         * @param {string} kitaId Die ID der Kita für die die Bestellung geladen werden sollen. 
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeEinzelneEssensbestellung: async (kitaId: string, datum: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitaId' is not null or undefined
            assertParamExists('ladeEinzelneEssensbestellung', 'kitaId', kitaId)
            // verify required parameter 'datum' is not null or undefined
            assertParamExists('ladeEinzelneEssensbestellung', 'datum', datum)
            const localVarPath = `/essensbestellungen/{kitaId}/{datum}`
                .replace(`{${"kitaId"}}`, encodeURIComponent(String(kitaId)))
                .replace(`{${"datum"}}`, encodeURIComponent(String(datum)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lädt die Essensbestellungen einer Kita zu einem Monat und Jahr
         * @param {number} jahr 
         * @param {string} [kitaId] Die ID der Kita für die die Essensbestellungen geladen werden sollen. Wenn nicht gefuellt werden zu allen Kitas die Bestellungen geladen 
         * @param {number} [monat] Die Monate von 1-12
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeEssensbestellungen: async (jahr: number, kitaId?: string, monat?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jahr' is not null or undefined
            assertParamExists('ladeEssensbestellungen', 'jahr', jahr)
            const localVarPath = `/essensbestellungen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kitaId !== undefined) {
                localVarQueryParameter['kitaId'] = kitaId;
            }

            if (monat !== undefined) {
                localVarQueryParameter['monat'] = monat;
            }

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lädt Feiertage zu einem Monat und Jahr
         * @param {number} jahr 
         * @param {number} [monat] Die Monate von 1-12
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeFeiertage: async (jahr: number, monat?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jahr' is not null or undefined
            assertParamExists('ladeFeiertage', 'jahr', jahr)
            const localVarPath = `/feiertage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (monat !== undefined) {
                localVarQueryParameter['monat'] = monat;
            }

            if (jahr !== undefined) {
                localVarQueryParameter['jahr'] = jahr;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary löscht eine Essensbestellung
         * @param {string} kitaId Die ID der Kita für die die Bestellungspausen geladen werden sollen. 
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loescheEssensbestellung: async (kitaId: string, datum: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kitaId' is not null or undefined
            assertParamExists('loescheEssensbestellung', 'kitaId', kitaId)
            // verify required parameter 'datum' is not null or undefined
            assertParamExists('loescheEssensbestellung', 'datum', datum)
            const localVarPath = `/essensbestellungen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kitaId !== undefined) {
                localVarQueryParameter['kitaId'] = kitaId;
            }

            if (datum !== undefined) {
                localVarQueryParameter['datum'] = datum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EssensbestellungApi - functional programming interface
 * @export
 */
export const EssensbestellungApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EssensbestellungApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary erstelle zu einem Zeitraum neue Essensbestellungen
         * @param {NeueEssensbestellungVO} neueEssensbestellungVO Zeitraum der Bestellungspause für eine Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erstelleEssensbestellung(neueEssensbestellungVO: NeueEssensbestellungVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EssensbestellungMitPositionenVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erstelleEssensbestellung(neueEssensbestellungVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * kopiert eine Bestellungswoche auf mehrere Wochen
         * @param {BestellungswocheKopierenRequestVO} bestellungswocheKopierenRequestVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kopiereBestellungsWoche(bestellungswocheKopierenRequestVO: BestellungswocheKopierenRequestVO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kopiereBestellungsWoche(bestellungswocheKopierenRequestVO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lädt zu einem Tag die Essensbestellungen aller Kitas
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeAuswertungZuEssensbestellung(datum: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuswertungEssensbestellungenVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeAuswertungZuEssensbestellung(datum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lädt alle Informationen zu einer Essensbestlelung
         * @param {string} kitaId Die ID der Kita für die die Bestellung geladen werden sollen. 
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeEinzelneEssensbestellung(kitaId: string, datum: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EssensbestellungMitPositionenVO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeEinzelneEssensbestellung(kitaId, datum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lädt die Essensbestellungen einer Kita zu einem Monat und Jahr
         * @param {number} jahr 
         * @param {string} [kitaId] Die ID der Kita für die die Essensbestellungen geladen werden sollen. Wenn nicht gefuellt werden zu allen Kitas die Bestellungen geladen 
         * @param {number} [monat] Die Monate von 1-12
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeEssensbestellungen(jahr: number, kitaId?: string, monat?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EssensbestellungVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeEssensbestellungen(jahr, kitaId, monat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lädt Feiertage zu einem Monat und Jahr
         * @param {number} jahr 
         * @param {number} [monat] Die Monate von 1-12
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ladeFeiertage(jahr: number, monat?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeiertagVO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ladeFeiertage(jahr, monat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary löscht eine Essensbestellung
         * @param {string} kitaId Die ID der Kita für die die Bestellungspausen geladen werden sollen. 
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loescheEssensbestellung(kitaId: string, datum: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loescheEssensbestellung(kitaId, datum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EssensbestellungApi - factory interface
 * @export
 */
export const EssensbestellungApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EssensbestellungApiFp(configuration)
    return {
        /**
         * 
         * @summary erstelle zu einem Zeitraum neue Essensbestellungen
         * @param {NeueEssensbestellungVO} neueEssensbestellungVO Zeitraum der Bestellungspause für eine Kita
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erstelleEssensbestellung(neueEssensbestellungVO: NeueEssensbestellungVO, options?: any): AxiosPromise<Array<EssensbestellungMitPositionenVO>> {
            return localVarFp.erstelleEssensbestellung(neueEssensbestellungVO, options).then((request) => request(axios, basePath));
        },
        /**
         * kopiert eine Bestellungswoche auf mehrere Wochen
         * @param {BestellungswocheKopierenRequestVO} bestellungswocheKopierenRequestVO 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kopiereBestellungsWoche(bestellungswocheKopierenRequestVO: BestellungswocheKopierenRequestVO, options?: any): AxiosPromise<void> {
            return localVarFp.kopiereBestellungsWoche(bestellungswocheKopierenRequestVO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lädt zu einem Tag die Essensbestellungen aller Kitas
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeAuswertungZuEssensbestellung(datum: string, options?: any): AxiosPromise<AuswertungEssensbestellungenVO> {
            return localVarFp.ladeAuswertungZuEssensbestellung(datum, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lädt alle Informationen zu einer Essensbestlelung
         * @param {string} kitaId Die ID der Kita für die die Bestellung geladen werden sollen. 
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeEinzelneEssensbestellung(kitaId: string, datum: string, options?: any): AxiosPromise<EssensbestellungMitPositionenVO> {
            return localVarFp.ladeEinzelneEssensbestellung(kitaId, datum, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lädt die Essensbestellungen einer Kita zu einem Monat und Jahr
         * @param {number} jahr 
         * @param {string} [kitaId] Die ID der Kita für die die Essensbestellungen geladen werden sollen. Wenn nicht gefuellt werden zu allen Kitas die Bestellungen geladen 
         * @param {number} [monat] Die Monate von 1-12
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeEssensbestellungen(jahr: number, kitaId?: string, monat?: number, options?: any): AxiosPromise<Array<EssensbestellungVO>> {
            return localVarFp.ladeEssensbestellungen(jahr, kitaId, monat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lädt Feiertage zu einem Monat und Jahr
         * @param {number} jahr 
         * @param {number} [monat] Die Monate von 1-12
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ladeFeiertage(jahr: number, monat?: number, options?: any): AxiosPromise<Array<FeiertagVO>> {
            return localVarFp.ladeFeiertage(jahr, monat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary löscht eine Essensbestellung
         * @param {string} kitaId Die ID der Kita für die die Bestellungspausen geladen werden sollen. 
         * @param {string} datum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loescheEssensbestellung(kitaId: string, datum: string, options?: any): AxiosPromise<void> {
            return localVarFp.loescheEssensbestellung(kitaId, datum, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EssensbestellungApi - object-oriented interface
 * @export
 * @class EssensbestellungApi
 * @extends {BaseAPI}
 */
export class EssensbestellungApi extends BaseAPI {
    /**
     * 
     * @summary erstelle zu einem Zeitraum neue Essensbestellungen
     * @param {NeueEssensbestellungVO} neueEssensbestellungVO Zeitraum der Bestellungspause für eine Kita
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensbestellungApi
     */
    public erstelleEssensbestellung(neueEssensbestellungVO: NeueEssensbestellungVO, options?: any) {
        return EssensbestellungApiFp(this.configuration).erstelleEssensbestellung(neueEssensbestellungVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * kopiert eine Bestellungswoche auf mehrere Wochen
     * @param {BestellungswocheKopierenRequestVO} bestellungswocheKopierenRequestVO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensbestellungApi
     */
    public kopiereBestellungsWoche(bestellungswocheKopierenRequestVO: BestellungswocheKopierenRequestVO, options?: any) {
        return EssensbestellungApiFp(this.configuration).kopiereBestellungsWoche(bestellungswocheKopierenRequestVO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lädt zu einem Tag die Essensbestellungen aller Kitas
     * @param {string} datum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensbestellungApi
     */
    public ladeAuswertungZuEssensbestellung(datum: string, options?: any) {
        return EssensbestellungApiFp(this.configuration).ladeAuswertungZuEssensbestellung(datum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lädt alle Informationen zu einer Essensbestlelung
     * @param {string} kitaId Die ID der Kita für die die Bestellung geladen werden sollen. 
     * @param {string} datum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensbestellungApi
     */
    public ladeEinzelneEssensbestellung(kitaId: string, datum: string, options?: any) {
        return EssensbestellungApiFp(this.configuration).ladeEinzelneEssensbestellung(kitaId, datum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lädt die Essensbestellungen einer Kita zu einem Monat und Jahr
     * @param {number} jahr 
     * @param {string} [kitaId] Die ID der Kita für die die Essensbestellungen geladen werden sollen. Wenn nicht gefuellt werden zu allen Kitas die Bestellungen geladen 
     * @param {number} [monat] Die Monate von 1-12
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensbestellungApi
     */
    public ladeEssensbestellungen(jahr: number, kitaId?: string, monat?: number, options?: any) {
        return EssensbestellungApiFp(this.configuration).ladeEssensbestellungen(jahr, kitaId, monat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lädt Feiertage zu einem Monat und Jahr
     * @param {number} jahr 
     * @param {number} [monat] Die Monate von 1-12
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensbestellungApi
     */
    public ladeFeiertage(jahr: number, monat?: number, options?: any) {
        return EssensbestellungApiFp(this.configuration).ladeFeiertage(jahr, monat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary löscht eine Essensbestellung
     * @param {string} kitaId Die ID der Kita für die die Bestellungspausen geladen werden sollen. 
     * @param {string} datum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EssensbestellungApi
     */
    public loescheEssensbestellung(kitaId: string, datum: string, options?: any) {
        return EssensbestellungApiFp(this.configuration).loescheEssensbestellung(kitaId, datum, options).then((request) => request(this.axios, this.basePath));
    }
}
