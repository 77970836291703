export var eintragstypen = [{
    name: "BESTELLUNG_ANGEPASST",
    anzeigeName: "Bestellung angepasst",
    chipFarbe: "blue"
},
    {
        name: "BESTELLUNG_STORNIERT",
        anzeigeName: "Bestellung storniert",
        chipFarbe: "red"
    },
    {
        name: "DATEN_BEREINIGEN",
        anzeigeName: "Daten bereinigen",
        chipFarbe: "purple lighten-2"
    },
    {
        name: "KITA_ANGELEGT",
        anzeigeName: "Neue Kita angelegt",
        chipFarbe: "green"
    },
    {
        name: "KITA_BEARBEITET",
        anzeigeName: "Kita bearbeitet",
        chipFarbe: "teal"
    },
    {
        name: "BESTELLUNGSPAUSE_ANGELEGT",
        anzeigeName: "Bestellungspause angelegt",
        chipFarbe: "blue-grey darken-3"
    },
    {
        name: "BESTELLUNGSPAUSE_GELOESCHT",
        anzeigeName: "Bestellungspause bearbeitet",
        chipFarbe: "orange darken-3"
    },
    {
        name: "ESSENSART_ANGELEGT",
        anzeigeName: "Essensart angelegt",
        chipFarbe: "green darken-3"
    },
    {
        name: "ESSENSART_GELOESCHT",
        anzeigeName: "Essensart gelöscht",
        chipFarbe: "red darken-2"
    },
    {
        name: "BESTELLVORLAGE_GELOESCHT",
        anzeigeName: "Bestellvorlage gelöscht",
        chipFarbe: "red lighten-1"
    },
    {
        name: "VERWALTUNG_SPEISEPLANPASSWORTGEAENDERT",
        anzeigeName: "Speiseplanpasswort geaendert",
        chipFarbe: "black"
    },
    {
        name: "SPEISEPLAN_HOCHGELADEN",
        anzeigeName: "Speiseplan hochgeladen",
        chipFarbe: "green lighten-1"
    },
    {
        name: "SPEISEPLAN_GELOESCHT",
        anzeigeName: "Speiseplan gelöscht",
        chipFarbe: "teal lighten-1"
    },
    {
        name: "KITAINFORMATION_VERSENDET",
        anzeigeName: "Info versendet",
        chipFarbe: "blue darken-3"
    },
    {
        name: "KITAINFORMATION_ANGELEGT",
        anzeigeName: "Info angelegt",
        chipFarbe: "primary darken-1"
    },
    {
        name: "INFORMATION_GELOESCHT",
        anzeigeName: "Info gelöscht",
        chipFarbe: "red darken-1"
    },
    {
        name: "INFORMATION_GEAENDERT",
        anzeigeName: "Info geändert",
        chipFarbe: "orange darken-1"
    },
]