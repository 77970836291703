<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card :loading="loading" outlined>
        <v-card-subtitle class="text-break">
          Nächste Bestellungen zu {{ value }}
        </v-card-subtitle>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Datum
                </th>
                <th class="text-left">
                  Kita
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in essensartenVerwendung.essensbestellungen"
                  :key="item.datum+item.kita.kitaId"
              >
                <td>{{ new Date(item.datum).toLocaleDateString() }}</td>
                <td>{{ item.kita.kitaName }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card :loading="loading" outlined>
        <v-card-subtitle class="text-break">
          Bestellvorlagen zu {{ value }}
        </v-card-subtitle>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Kita
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in essensartenVerwendung.kitasMitBestellvorlagen"
                  :key="item.kitaId"
              >
                <td>{{ item.kitaName }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import {essensartenservice} from "@/service";

export default {
  props: ['value'],
  name: "EssensartDetailansicht",
  data: () => ({
    essensartenVerwendung: null,
    loading: false
  }),
  methods: {
    ladeVerwendungVonEssensart() {
      this.loading = true
      essensartenservice
          .ladeEssensartVerwendungen(this.value)
          .then((response) => {
            this.essensartenVerwendung = response.data
          })
          .finally(() => {
            this.loading = false
          })
    }
  },
  created() {
    this.ladeVerwendungVonEssensart()


  }
}
</script>

<style scoped>

</style>