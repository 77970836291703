<template>
  <v-container fill-height class="fill-height">
    <v-row justify="center" align="center">
      <v-col cols="12" class="text-center">

        <h4>
          <v-icon color="error" large left>mdi-alert</v-icon>
        </h4>
        <h2>
          Zugriff verweigert
        </h2>
        <h4>
          Die gewünschte Seite darf nicht aufgerufen werden.
        </h4>
        <v-btn :to="'/'" class="mt-4" text>
          Zurück zur Startseite
        </v-btn>

      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ZugriffVerweigertView"
}
</script>

<style scoped>

</style>