<template>
  <v-skeleton-loader
      v-bind="attrs"
      type="article,divider, actions"
      v-if="loading"
  ></v-skeleton-loader>
  <v-card flat v-else>
    <v-alert
        v-if="info"
        :type="info.typ"
        :color="info.typ ==='success'?'green darken-3':'error'"
        class="ma-0"
    >
      {{ info.nachricht }}
    </v-alert>
    <v-card-title>
      Systemkonfiguration
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-text-field
              v-model="speiseplanPasswort"
              dense
              hint="Passwort für den Elternzugang zum Speiseplan"
              label="Speiseplan Passwort"
              outlined
              :rules="[ value => value.length  >=5|| 'Das Passwort muss mindestens 5 Zeichen lang sein']"
              @keydown.enter="speiseplanPasswortSpeichern"
              persistent-hint
              type="password">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="2">
          <v-btn
              :disabled="!speiseplanPasswort"
              :loading="loadingSpeiseplanPasswortSpeichern"
              block
              color="primary"
              depressed
              @click="speiseplanPasswortSpeichern">
            Passwort speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {verwaltungservice} from "@/service";
import {mapMutations} from "vuex";

export default {
  name: "KonfigurationView",
  data: () => ({
    konfiguration: {},
    speiseplanPasswort: null,
    konfigurationOriginal: {},
    loading: true,
    loadingSpeichern: false,
    loadingSpeiseplanPasswortSpeichern: false,
    info: null,
    attrs: {
      elevation: 0,
    },
  }),
  computed: {
    gibtEsAenderungen() {
      return JSON.stringify(this.konfiguration) === JSON.stringify(this.konfigurationOriginal)
    }
  },
  methods: {
    ...mapMutations(['addErfolgsNachricht']),
    speiseplanPasswortSpeichern() {
      this.loadingSpeiseplanPasswortSpeichern = true
      verwaltungservice.setzeSpeiseplanPasswort({passwort: this.speiseplanPasswort})
          .then(() => {
            this.info = {
              typ: 'success',
              nachricht: 'Passwort erfolgreich gespeichert'
            }
            this.speiseplanPasswort = null
          })
          .catch(error => {
            console.log(error.response)
            this.info = {
              typ: 'error',
              nachricht: error.response.data.message
            }
          }).finally(() => {
        this.loadingSpeiseplanPasswortSpeichern = false
      });
    },
    speichern() {
      this.loadingSpeichern = true
      verwaltungservice.updateKonfiguration(this.konfiguration).then(() => {
        this.addErfolgsNachricht("Konfiguration erfolgreich gespeichert")
        this.info = {
          typ: 'success',
          nachricht: 'Konfiguration erfolgreich gespeichert'
        }
      }).catch(error => {
        console.log(error.response)
        this.info = {
          typ: 'error',
          nachricht: 'Beim Speichern ist ein Fehler aufgetreten. ' + error.response
        }
      }).finally(() => {
            this.loadingSpeichern = false
          }
      )
    },
    ladeKonfiguration() {
      this.loading = true
      verwaltungservice.getKonfiguration().then(response => {
        this.konfiguration = response.data
        this.konfigurationOriginal = Object.assign({}, this.konfiguration)
      }).finally(
          () => {
            this.loading = false
          }
      )
    },
  },
  created() {
    this.ladeKonfiguration()
  }
}
</script>

<style scoped>

</style>