<template>
  <v-container v-if="passwortkorrekt || oidcIsAuthenticated">
    <v-row>
      <v-col cols="12" sm="5">
        <v-select v-model="ausgewaehlteWoche"
                  :items="speiseplanWochen"
                  class="ml-3 mt-3"
                  dense
                  hide-details
                  item-text="text"
                  label="Kalenderwoche"
                  no-data-text="Es gibt noch keine Speisepläne"
                  outlined
                  return-object
                  @change="ladeSpeiseplan">
        </v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select v-model="ausgewaehltertyp"
                  :items="speiseplanTypenZurWoche"
                  class="ml-3 mt-3"
                  dense
                  hide-details
                  label="Typen"
                  no-data-text="Es gibt noch keine Speisepläne"
                  outlined
                  @change="ladeSpeiseplan">
        </v-select>
      </v-col>
      <v-spacer></v-spacer>
      <speiseplan-upload-component :wochen-mit-speiseplaenen="speiseplanWochen" @update="ladeSpeiseplanWochen"></speiseplan-upload-component>
    </v-row>
    <speiseplan-download-component :dateiname="bestimmeDateiName" :speiseplan="speiseplan"></speiseplan-download-component>
    <v-row justify="center" align-content="center">
      <v-col cols="12" lg="10" align-self="center" class="text-center">
        <div v-if="speiseplan && !loading">
          <pdf
              v-for="i in pdfSeitenZahl"
              :key="i"
              :page="i"
              :src="speiseplan"
          ></pdf>
        </div>
        <v-progress-circular v-if="loading"
                             :size="75"
                             :width="4"
                             color="primary"
                             indeterminate
        ></v-progress-circular>
        <v-alert v-if="!loading&&!ausgewaehlteWoche" class="font-weight-bold" color="accent" icon="mdi-information" outlined prominent>
          Für die aktuelle Kalenderwoche gibt es noch keinen Speiseplan.
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else class="speiseplan-passwort-container justify-center fill-height">
    <v-form ref="form" @submit.prevent="pruefeSpeiseplanPasswort">
      <v-row align-content="center">
        <v-col cols="12" offset-sm="2" sm="8">
          <h2 class="text-center">
            Bitte geben Sie das Passwort für den Zugriff auf den Speiseplan ein.
          </h2>
        </v-col>
        <v-col cols="12" md="4" offset-md="4" offset-sm="3" sm="6">
          <v-text-field v-model="speiseplanpasswort"
                        dense
                        label="Passwort"
                        outlined
                        :error-messages="fehler"
                        autofocus
                        type="password"
                        @change="ladeSpeiseplanWochen">
          </v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12" md="4" offset-md="4" offset-sm="3" sm="6">
          <v-btn :disabled="!speiseplanpasswort" :loading="loadingSpeiseplanPasswortCheck"
                 block
                 color="primary"
                 depressed
                 type="submit">Abschicken
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import SpeiseplanUploadComponent from "@/components/speiseplan/SpeiseplanUploadComponent";
import {speiseplanservice} from "@/service";
import pdf from 'vue-pdf'
import SpeiseplanDownloadComponent from "@/components/speiseplan/SpeiseplanDownloadComponent";
import moment from "moment";
import {SpeiseplanTypen} from "@/components/speiseplan/SpeiseplanTypenEnum";
import {mapGetters} from "vuex";

export default {
  name: "SpeiseplanView",
  components: {SpeiseplanDownloadComponent, SpeiseplanUploadComponent, pdf},
  data: () => ({
    speiseplan: null,
    loading: false,
    loadingSpeiseplanPasswortCheck: false,
    speiseplanpasswort: null,
    passwortkorrekt: false,
    ausgewaehltertyp: null,
    pdfSeitenZahl: 0,
    speiseplanWochen: [],
    ausgewaehlteWoche: {},
    fehler: null
  }),
  computed: {
    ...mapGetters(['oidcIsAuthenticated']),
    speiseplanTypenZurWoche() {
      if (this.ausgewaehlteWoche?.typen) {
        return this.ausgewaehlteWoche.typen.map(typ => {
          return {
            value: typ,
            text: SpeiseplanTypen.get(typ)
          }
        })
      } else {
        return null
      }
    },
    bestimmeDateiName() {
      if (this.ausgewaehlteWoche) {
        return this.ausgewaehlteWoche.jahr + '_kw' + this.ausgewaehlteWoche.kw + '_' + this.ausgewaehltertyp?.toLowerCase()
      } else {
        return "speiseplan";
      }
    }
  },
  methods: {
    pruefeSpeiseplanPasswort() {
      this.loadingSpeiseplanPasswortCheck = true
      speiseplanservice.checkPasswort(this.speiseplanpasswort)
          .then(() => {
            this.passwortkorrekt = true;
            if (this.passwortkorrekt) {
              this.ladeSpeiseplanWochen();
            }
          }).catch(() => {
        this.passwortkorrekt = false;
        this.fehler = "Das übergebene Passwort ist inkorrekt."
      }).finally(() => {
        this.loadingSpeiseplanPasswortCheck = false
      })
    },
    ladeSpeiseplan() {
      this.speiseplan = null
      this.loading = true
      if (!this.ausgewaehltertyp || !this.speiseplanTypenZurWoche.find(typ => typ.value === this.ausgewaehltertyp)) {
        this.ausgewaehltertyp = this.ausgewaehlteWoche.typen[0]
      }
      speiseplanservice.ladeSpeiseplan(this.ausgewaehltertyp, this.ausgewaehlteWoche.jahr, this.ausgewaehlteWoche.kw, this.speiseplanpasswort)
          .then(response => {
            this.speiseplan = 'data:application/pdf;base64,' + response.data;
            pdf.createLoadingTask(this.speiseplan).promise.then(pdf => {
              this.pdfSeitenZahl = pdf.numPages;
            });
          })
          .finally(() => this.loading = false)
    },
    ladeSpeiseplanWochen() {
      this.loading = true

      speiseplanservice.ladeSpeiseplanWochen(this.speiseplanpasswort)
          .then(response => {
            this.speiseplanWochen = response.data
            this.speiseplanWochen.forEach(it => {
              const ersterTagDerWoche = moment().day(1).isoWeek(it.kw).isoWeekYear(it.jahr).toDate().toLocaleDateString()
              const letzterTagDerWoche = moment().day(7).isoWeek(it.kw).isoWeekYear(it.jahr).toDate().toLocaleDateString()
              return it.text = "KW " + it.kw + " - " + it.jahr + " (" + ersterTagDerWoche + " - " + letzterTagDerWoche + ")"
            })
            const aktuellesJahr = moment().isoWeekYear()
            const aktuelleWoche = moment().isoWeek()
            this.ausgewaehlteWoche = this.speiseplanWochen.find(it => it.kw === aktuelleWoche && it.jahr === aktuellesJahr)
            if (this.ausgewaehlteWoche) {
              this.ladeSpeiseplan()
            }
          })
          .finally(() => {
            this.loading = false
          })
    }
  },
  created() {
    if (this.oidcIsAuthenticated) {
      this.ladeSpeiseplanWochen()
    }
  },

}
</script>

<style scoped>

.font-weight-bold {
  font-family: "Titillium Web", Sans-serif;
  font-size: 25px;
}

.passworteingabe {
  min-height: 700px;
}

.speiseplan-passwort-container {
  height: 700px;

}
</style>