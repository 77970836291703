<template>
  <bestellungspausen-liste-component />
</template>

<script>
import BestellungspausenListeComponent from "@/components/essenbestellen/bestellungspause/BestellungspausenAnzeigenComponent.vue"

export default {
  name: "BestellungPausierenView",
  components: { BestellungspausenListeComponent }
}
</script>

<style scoped>

</style>