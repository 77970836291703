<template>
  <v-list
      color="transparent"
      shaped
  >
    <v-list-item>
      <v-list-item-content>
        <anmelden-button-component></anmelden-button-component>
        <v-list-item-title class="title">
          {{ oidcUser.name }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider/>
    <navigations-ziel-component
        v-for="ziel in navigationsziele"
        :key="ziel.name"
        :ziel="ziel"
    />

    <v-divider class="my-2"/>
    <navigations-ziel-component
        v-for="ziel in rechtlicheziele"
        :key="ziel.name"
        :ziel="ziel"
    />
      <abmelden-component></abmelden-component>

    <v-divider></v-divider>
    <v-footer color="transparent" padless>
      <v-col
          class="text-center overline"
          cols="12"
      >
        V{{ getVersion }}
      </v-col>
    </v-footer>

  </v-list>
</template>

<script>
import Vue from 'vue'
import NavigationsZielComponent from "@/components/navigation/NavigationsZielComponent.vue"
import {mapGetters} from "vuex";
import AbmeldenComponent from "@/components/navigation/AbmeldenComponent";
import {version} from '../../../package'
import AnmeldenButtonComponent from "@/components/navigation/AnmeldenButtonComponent.vue";

export default Vue.extend({
  name: 'NavigatiosInhaltComponent',
  components: {AnmeldenButtonComponent, AbmeldenComponent, NavigationsZielComponent},
  data: () => ({
    navigationsziele: [
      {
        name: "Bestellungen",
        icon: "mdi-food-apple",
        ziele: [
          {
            name: "Essen bestellen",
            zielkomponentenname: "essenseingabe",
            icon: "mdi-plus"
          },
          {
            name: "Allergenkennzeichnungen",
            zielkomponentenname: "allergenkennzeichnungen",
            icon: "mdi-food-variant"
          },
          {
            name: "Pausieren",
            zielkomponentenname: "bestellungpausieren",
            icon: "mdi-sleep"
          },
          {
            name: "Auswertung",
            zielkomponentenname: "auswertungessensbestellung",
            icon: "mdi-order-bool-descending-variant"
          },
          {
            name: "Bestellvorlage",
            zielkomponentenname: "bestellvorlage",
            icon: "mdi-text-box-check-outline"
          }
        ]
      },
      {
        name: "Verwaltung",
        icon: "mdi-domain",
        ziele: [
          {
            name: "Einrichtungen",
            zielkomponentenname: "einrichtungen",
            icon: "mdi-human-female-boy"
          },
          {
            name: "Monatsübersicht",
            zielkomponentenname: "monatsuebersicht",
            icon: "mdi-file-table"
          },
          {
            name: "Essensarten",
            zielkomponentenname: "essensarten",
            icon: "mdi-flower"
          },
          {
            name: "Benutzer",
            zielkomponentenname: "benutzerverwaltung",
            icon: "mdi-account-details"
          },
          {
            name: "Historie",
            zielkomponentenname: "historieneintraege",
            icon: "mdi-history"
          },
          {
            name: "Informationen",
            zielkomponentenname: "informationen",
            icon: "mdi-email-newsletter"
          },
          {
            name: "Konfiguration",
            zielkomponentenname: "konfiguration",
            icon: "mdi-cog"
          }
        ]
      },
      {
        name: "Speiseplan",
        zielkomponentenname: "speiseplan",
        icon: "mdi-food-fork-drink"
      },
    ],
    rechtlicheziele: [
      {
        name: "Impressum",
        zielkomponentenname: "impressum",
        icon: "mdi-information"
      },
      {
        name: "Datenschutz",
        zielkomponentenname: "datenschutz",
        icon: "mdi-security"
      },
      {
        name: "Kontakt",
        zielkomponentenname: "kontakt",
        icon: "mdi-chat"
      },
    ]
  }),
  computed: {
    ...mapGetters(['oidcUser']),
    getVersion(){
      return version
    }
  },
})
</script>
