<template>
  <div>
    <historie-component></historie-component>
  </div>
</template>

<script>
import HistorieComponent from "@/components/verwaltung/historie/HistorieComponent";

export default {
  name: "HistorienEintraegeView",
  components: {HistorieComponent}
}
</script>

<style scoped>

</style>