<template>
  <v-container fluid>
    <v-row>
      <v-col
          md="8"
          sm="7"
          cols="12"
      >
        <h2>Auswertung Essensbestellungen</h2>
      </v-col>
      <v-col
          md="3"
          sm="4"
          cols="10"
          align-self="end"
      >
        <auswertung-datums-auswahl-component @input="datum = $event"></auswertung-datums-auswahl-component>
      </v-col>
      <v-col cols="2" sm="1" md="1">
        <v-btn @click="$refs.auswertungtabelle.print()" color="primary" fab depressed small>
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
          cols="12"
          sm="4"
          md="3"
      >
        <v-switch
            label="Reihenfolge anpassen"
            hide-details
            v-model="reihenfolgeAnpassen"
            @click="setzeFilterZurueck"
        >
        </v-switch>
      </v-col>
      <v-col
          cols="12"
          sm="4"
          md="3"
          align-self="end"
      >
        <v-checkbox
            label="Bestellungspausen"
            v-model="zeigeBestellungspausen"
            hide-details
            :disabled="reihenfolgeAnpassen"
        >
        </v-checkbox>
        <v-checkbox
            label="Ohne Bestellung"
            v-model="zeigeLeereBestellungen"
            hide-details
            :disabled="reihenfolgeAnpassen"
            class="mt-1"
        >
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <auswertung-essensbestellung-component
            ref="auswertungtabelle"
            v-if="datum!=null"
            :key="datum"
            :datum="datum"
            :reihenfolgeAnpassen="reihenfolgeAnpassen"
            :zeigeBestellungspausen="zeigeBestellungspausen"
            :zeigeLeereBestellungen="zeigeLeereBestellungen"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script >
import AuswertungDatumsAuswahlComponent from "@/components/essenbestellen/auswertung/AuswertungDatumsAuswahlComponent.vue";
import AuswertungEssensbestellungComponent from "@/components/essenbestellen/auswertung/AuswertungEssensbestellungComponent.vue";

export default {
  name: "AuswertungEssensbestellung",
  components: {AuswertungEssensbestellungComponent, AuswertungDatumsAuswahlComponent},
  data: () => ({
    datum: null,
    reihenfolgeAnpassen: false,
    zeigeBestellungspausen: true,
    zeigeLeereBestellungen: true,
  }),
  methods:{
    setzeFilterZurueck(){
      this.zeigeBestellungspausen=true
      this.zeigeLeereBestellungen=true
    }
  }
}
</script>

<style scoped>

</style>